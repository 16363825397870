<template>
  <div>
    <h5 class="mb-2">{{titleText}}</h5>
    <p><small>{{staticTexts.subtitle}}</small></p>
    <button class="btn btn-secondary btn-block" :class="{'btn-secondary': !isSigned, 'btn-success': isSigned}" @click.prevent="showModal">
      <span class="material-icons">
        {{signButtonIcon}}
      </span>
      {{staticTexts.noMinors}}
    </button>
    <b-modal ref="modal-signbbll" size="xl" no-close-on-backdrop hide-header-close hide-footer :title="staticTexts.modalTitle">
        <ul class="list-group list-group-flush list-group-users">
          <MinorItem v-for="(minor, index) in minors" :value="minor" :managerMode="mode" :key="index" :index="index" :legalText="legalText" @change="handleChangeMinor" @delete="handleDeleteMinor" @modeChange="handleModeChange"></MinorItem>
        </ul>
        <button class="btn btn-warning btn-block" @click="handleCreate" v-show="mode==='list'">
          <span class="material-icons">add_circle</span>
          {{staticTexts.addMinor}}
        </button>
        <button class="btn btn-default btn-block" @click="hideModal" v-show="mode==='list'">
          <span class="material-icons">close</span>
          {{staticTexts.back}}
        </button>
    </b-modal>

  </div>
</template>

<script>
/* global gettext */
import { mapGetters } from 'vuex'
import { BModal } from 'bootstrap-vue'
import MinorItem from '@/components/Hitsbook/MinorItem'

export default {
  components: {
    BModal,
    MinorItem
  },
  computed: {
    ...mapGetters(['getCampaign']),
    titleText() {
      if (this.minors.length > 0) {
        if (this.minors.length === 1) {
          return this.minors.length + this.staticTexts.minor
        } else {
          return this.minors.length + this.staticTexts.minors
        }
      }
      return this.staticTexts.noMinorsTitle
    },
    signButtonIcon() {
      if (this.isSigned) {
        return "download_done"
      }
      return "child_care"
    },
    staticTexts () {
      return {
        addMinor: gettext("Añadir menor"),
        back: gettext("Cerrar y volver"),
        minors: gettext(" menores registrado"),
        minor: gettext(" menor registrado"),
        noMinors: gettext("Añadir/Editar Menores"),
        noMinorsTitle: gettext("¿Aparecen menores?"),
        subtitle: gettext("Si aparecen menores debes introducir sus datos y firmar sus tutores legales."),
        name: gettext("Menor"),
        modalTitle: gettext("Gestor de menores")
      };
    }
  },
  props: {
    value: {
      type: Array,
      default: () => { return [] }
    },
    legalText: String
  },
  data() {
    return {
      isSigned: false,
      minors: [],
      mode: 'list',
      defaultMinorData: {
        name: null,
        surname: null,
        age: null,
        id: null,
        address: null,
        tutor1: {
          name: null,
          surname: null,
          email: null,
          id: null,
          signature: null
        },
        tutor2_non_exists: false,
        tutor2: {
          name: null,
          surname: null,
          email: null,
          id: null,
          signature: null
        }
      },
      defaultTutorData: {
        name: null,
        surname: null,
        email: null,
        id: null,
        signature: null
      }
    }
  },
  methods: {
    handleModeChange (newMode) {
      if (newMode === 'form') {
        this.mode = "edit"
      } else {
        this.mode = "list"
      }
    },
    handleChangeMinor (payload) {
      this.minors[payload.index] = payload.value
      this.$emit('input', this.minors)
    },
    handleDeleteMinor (payload) {
      this.minors.splice(payload.index, 1)
      this.$emit('input', this.minors)
      this.mode = "list"
      if (this.minors.length === 0) {
        this.hideModal()
      }
    },
    showModal() {
      this.minors = this.value
      this.$refs['modal-signbbll'].show()
      if (this.minors.length === 0) {
        this.handleCreate()
      }
    },
    handleCreate () {
      let newMinor = { ...this.defaultMinorData }
      // newMinor.name = this.staticTexts.name
      newMinor.tutor1 = { ...this.defaultTutorData }
      newMinor.tutor2 = { ...this.defaultTutorData }
      this.minors.push(newMinor)
    },
    hideModal() {
      this.$emit('input', this.minors)
      this.$refs['modal-signbbll'].hide()
    }
  }
};

</script>

<style>
.list-group-item.item-mode-view.manager-mode-edit {
  display: none !important;
}
</style>
