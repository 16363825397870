<template>
  <div class="container container-register">
    <transition name="slide-fade" mode="out-in">
      <div class="row" v-if="step === 1" key="step1">
        <div class="col-md-6">
          <ValidationObserver v-slot="{ validate }">
            <form @submit.prevent="onSubmitStep1(validate)" autocomplete="nope">
              <!-- FORM HEADER-->
              <div class="form-header form-header-contentup mb-2">
                <h3 class="title color-custom-primary mb-2 animation-appear" id="registerIn">
                  {{ staticTexts.registerIn }}
                </h3>
                <h5 class="font-corp animation-appear animation-delay-1" id="dataIsRequired">
                  {{ staticTexts.dataIsRequired }}
                </h5>
                <p class="animation-appear animation-delay-2" id="alreadyHasAccount">
                  <span class="pretext">{{ staticTexts.haveAnAccount }}</span>
                  <router-link class="color-custom-primary" :to="{'name': 'login'}">{{ staticTexts.initSession }}</router-link>
                </p>
                <h6 class="title-line animation-appear animation-delay-3" v-if="isFacebookEnabled || isGoogleEnabled">
                  <span class="pretext">{{ staticTexts.registerWith }}</span>
                  <span v-if="isFacebookEnabled"> {{ staticTexts.registerWithFacebook }} <span v-if="isGoogleEnabled">{{ staticTexts.registerWithOr }}</span> </span>
                  <span v-if="isGoogleEnabled"> {{ staticTexts.registerWithGoogle }} </span>
                </h6>
                <div class="row m-0 animation-appear animation-delay-4" v-if="isFacebookEnabled || isGoogleEnabled">
                  <button type="button" v-if="isFacebookEnabled" @click="fbLogin()" class="btn btn-social-login btn-facebook btn-link col">
                    <img src="/static/img/facebook.svg" />
                    <span>{{ staticTexts.facebook }}</span>
                  </button>
                  <button @click="goLogin()" v-if="isGoogleEnabled" type="button" class="btn btn-social-login btn-facebook btn-link col">
                    <img src="/static/img/google.svg" />
                    <span>{{ staticTexts.google }}</span>
                  </button>
                </div>
              </div>
              <!-- END / FORM HEADER-->
              <!-- FORM BODY-->
              <div class="form-body animation-appear animation-delay-5" v-if="isEmailEnabled">
                <h6 class="font-corp title-line">
                  <span v-if="isFacebookEnabled || isGoogleEnabled">o </span>
                  <span> {{ staticTexts.registerWithEmail }}</span>
                </h6>

                <validation-provider :name="staticTexts.email" rules="required|email" v-slot="{ classes, errors }">
                  <div class="form-group field md-field md-theme-default" :class="classes">
                      <b-form-input v-model="form.email" :placeholder="staticTexts.email" class="font-corp md-input"></b-form-input>
                      <label class="font-corp"> {{ staticTexts.email }} *</label>
                      <span class="error" v-if="errors">{{ errors[0] }}</span>
                  </div>
                </validation-provider>

                <div class="form-row" v-if="askPasswords">
                  <div class="col-sm">
                    <validation-provider :name="staticTexts.password" rules="required|min:6|confirmed:pass" v-slot="{ classes, errors }">
                      <div class="form-group field" :class="classes">
                        <b-form-input v-model="form.password" id="id_password" name="password" :placeholder="staticTexts.password" type="password" class="font-corp"></b-form-input>
                        <label for="id_password">{{ staticTexts.password }}</label>
                        <span class="error" v-if="errors">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                  <div class="col-sm">
                    <validation-provider vid="pass" :name="staticTexts.repeatPassword" rules="required" v-slot="{ classes, errors }">
                      <div class="form-group field" :class="classes">
                        <b-form-input v-model="form.confirm_password" id="id_confirm_password" :placeholder="staticTexts.repeatPassword" name="confirm_password" type="password" class="font-corp"></b-form-input>
                        <label for="id_confirm_password">{{ staticTexts.repeatPassword }}</label>
                        <span class="error">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <!-- END / FORM BODY-->
              <!-- FORM BOTTOM-->
              <div class="form-bottom mt-4 animation-appear animation-delay-6">
                <!-- DYNAMIC FIELDS -->
                <div class="form-row">
                  <div v-for="field in extraFields" :class="getFieldClasses(field)" :key="field.id">
                    <validation-provider :rules="getFieldRules(field)" :name="field.name" v-slot="{ errors, classes }">
                      <div v-if="field.type==='checkbox'" class="">
                        <b-form-checkbox
                          :id="field.id.toString()"
                          v-model="form.extra[field.id.toString()]"
                          :name="field.id.toString()"
                          :value="true"
                          class="font-corp"
                          :class="classes"
                          :unchecked-value="false"
                        >
                          <a :href="field.options.link" target="_blank" v-if="field.options.link">
                           {{ field.name }}
                          </a>
                          <span v-else>{{field.name}}</span>
                          <div>
                            <span class="error">{{ errors[0] }}</span>
                          </div>
                        </b-form-checkbox>
                      </div>
                      <div v-if="field.type==='char'" class="field">
                        <b-form-input
                          :id="field.id.toString()"
                          v-model="form.extra[field.id.toString()]"
                          :name="field.id.toString()"
                          class="font-corp md-input"
                          :class="classes"
                          :placeholder="field.name"
                        >
                        </b-form-input>
                        <label class="font-corp"> {{ field.name }}</label>
                        <em class="help_text">{{ field.description }}</em>
                        <span class="error">{{ errors[0] }}</span>
                      </div>
                      <div v-if="field.type==='date'" class="">
                        <datetime class="field" :phrases="{ok: 'Ok', cancel: 'Cancelar'}" :input-class="getInputClasses(classes)" v-model="form.extra[field.id.toString()]">
                          <template slot="after">
                            <label class="font-corp"> {{ field.name }}</label>
                          </template>
                        </datetime>
                        <em class="help_text">{{ field.description }}</em>
                        <span class="error">{{ errors[0] }}</span>
                      </div>
                      <div v-if="field.type==='integer'" class="field">
                        <b-form-input
                          :id="field.id.toString()"
                          v-model="form.extra[field.id.toString()]"
                          :name="field.id.toString()"
                          type="number"
                          class="font-corp md-input"
                          :class="classes"
                          :placeholder="field.name"
                        >
                        </b-form-input>
                        <label class="font-corp"> {{ field.name }}</label>
                        <em class="help_text">{{ field.description }}</em>
                        <span class="error">{{ errors[0] }}</span>
                      </div>
                      <div v-if="field.type==='positive_integer'" class="field">
                        <b-form-input
                          :id="field.id.toString()"
                          v-model="form.extra[field.id.toString()]"
                          :name="field.id.toString()"
                          type="number"
                          class="font-corp md-input"
                          :class="classes"
                          :placeholder="field.name"
                          min="0"
                        >
                        </b-form-input>
                        <label class="font-corp"> {{field.name}}</label>
                        <em class="help_text">{{ field.description }}</em>
                        <span class="error">{{ errors[0] }}</span>
                      </div>
                      <div v-if="field.type==='select'" class="">
                        <label class="font-corp"> {{field.name}}</label>
                        <b-form-select
                          :id="field.id.toString()"
                          v-model="form.extra[field.id.toString()]"
                          :name="field.id.toString()"
                          :options="getFieldSelectOptions(field)"
                          class="font-corp md-input"
                          :class="classes"
                          :placeholder="field.name"
                        >
                        </b-form-select>
                        <em class="help_text">{{ field.description }}</em>
                        <span class="error">{{ errors[0] }}</span>
                      </div>
                      <div v-if="field.type==='text'" class="field">
                        <b-form-textarea
                          :id="field.id.toString()"
                          v-model="form.extra[field.id.toString()]"
                          :name="field.id.toString()"
                          :rows="getFieldRows(field)"
                          class="font-corp md-input"
                          :class="classes"
                          :placeholder="field.name"
                        >
                        </b-form-textarea>
                        <label class="font-corp"> {{ field.name }}</label>
                        <em class="help_text">{{ field.description }}</em>
                        <span class="error">{{ errors[0] }}</span>
                      </div>
                    </validation-provider>
                  </div>
                </div>

                <validation-provider rules="acceptance" v-slot="{ errors, classes }">
                  <b-form-checkbox
                    id="legal_basis_acceptance"
                    v-model="form.legal_basis_acceptance"
                    name="legal_basis_acceptance"
                    value="accepted"
                    class="font-corp"
                    :class="classes"
                    unchecked-value="not_accepted"
                  >
                    <template v-if="getCampaignId === 538">
                      Al hacer clic en "Enviar código de validación", confirmas que tienes al menos 18 años, aceptas los <a href="/#/info/legal/legal_basis" class="color-custom-primary" target="_blank">{{ staticTexts.termsAndConditions }}</a> y reconoces que has leído y aceptas nuestra <a href="https://www.warnermediaprivacy.com/policycenter/b2c/es-row/" target="_blank">{{ staticTexts.privacyPolicy }}</a>. Además, reconoces que te enviaremos ofertas de marketing, promociones y otras ofertas de Canal Space y sus afiliadas (<a href="https://www.warnermediaprivacy.com/policycenter/b2c/affiliateslist/" target="_blank">ver detalles</a>) por e-mail, redes sociales y otros canales. Para cancelar en cualquier momento o para obtener más información sobre tus derechos y cómo ejercerlos, consulta las opciones disponibles en la Política de privacidad o enviar un correo a <a href="mailto:wmprivacy@warnermedia.com">wmprivacy@warnermedia.com</a>.
                    </template>
                    <template v-else>
                      {{ staticTexts.iAcceptThe }} <router-link :to="{name:'legal_basis'}" class="color-custom-primary" target="_blank">{{ staticTexts.legalBasis }}</router-link>, {{ staticTexts.the }} <router-link :to="{name:'terms'}" class="color-custom-primary" target="_blank">{{ staticTexts.termsAndConditions }}</router-link> {{ staticTexts.andThe }}
                        <router-link :to="{name:'privacy_policy'}" class="color-custom-primary" target="_blank">{{ staticTexts.privacyPolicy }}</router-link>
                    </template>

                    <div>
                      <span class="error">{{ errors[0] }}</span>
                    </div>
                  </b-form-checkbox>
                </validation-provider>

                <div class="form-row mt-5 align-items-center">
                  <div class="col-md-7">
                    <button type="submit" @click.prevent="onSubmitStep1(validate)" :disabled="sendCodeInProgress" class="btn btn-custom-primary btn-lg btn-block">
                      <span v-if="!sendCodeInProgress">
                        <span class="material-icons">
                        check_circle_outline </span>
                        {{ staticTexts.sendValidationCode }}
                      </span>
                      <span v-if="sendCodeInProgress">
                        <LoaderIndicator theme="light" :size="40" />
                        {{ staticTexts.sending }}
                      </span>

                    </button>
                  </div>
                  <div class="col">
                    <p class="mb-0 font-corp">
                      <small>
                        {{ staticTexts.clickSendCode }}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <!-- END / FORM BOTTOM-->
            </form>
          </ValidationObserver>
        </div>
        <div class="col-md-6">
          <figure class="animation-appear-right animation-delay-2">
            <img class="img-fluid" :src="getRegistrationImage" />
          </figure>
        </div>
      </div>
      <div class="row" v-if="step === 2" key="step2">
        <div class="col-md-6">
          <form>
            <!-- FORM HEADER-->
            <div class="form-header mb-2 animation-appear">
              <h3 class="title color-custom-primary mb-2">
                {{ staticTexts.validateAndParticipate }}
              </h3>
              <h5 class="font-corp mb-1">
                {{ staticTexts.enterTheCodeReceived }} <strong class="current-email" @click.prevent="wrongEmail=!wrongEmail">{{form.email}}</strong>.<br />
              </h5>
              <transition name="slide-down">
                <p class="wrong-email" v-if="wrongEmail">
                  {{ staticTexts.madeAMistake }}
                  <button class="btn btn-custom-primary btn-link btn-xs" @click.prevent="goToStep(1)"><span class="material-icons">edit</span> {{ staticTexts.fixNow }}</button>
                </p>
              </transition>
              <p class="font-corp">
                {{ staticTexts.codeNotReceived }}
                <button @click.prevent="reSendCode" v-if="!resendInProgress && !resent" class="btn btn-link btn-custom-primary color-custom-primary">
                  {{ staticTexts.sendAgain }}
                </button>
                <span v-if="resendInProgress" class="btn btn-link btn-custom-primary color-custom-primary">
                  <LoaderIndicator :theme="getCampaign.theme_style" :size="40" /> {{ staticTexts.sending }}
                </span>
                <span v-show="resent" class="btn btn-link disabled btn-custom-primary color-custom-primary" :class="{'fade-out': resent}">
                  <i class="material-icons">check</i> {{ staticTexts.sent }}
                </span>
              </p>
            </div>
            <!-- END / FORM HEADER-->
            <!-- FORM BODY-->
            <div class="form-body animation-appear animation-delay-1"  v-if="retries_left > 0">
              <ValidationObserver v-slot="{ invalid }">
                <validation-provider ref="codeProvider" :name="staticTexts.code" rules="required|digits:6" v-slot="{ classes, errors }">
                  <div class="form-group md-field md-theme-default" :class="classes">
                    <div class="input-group">
                      <b-form-input v-model="form.code" class="font-corp md-input"></b-form-input>
                      <div class="input-group-btn">
                        <button class="btn btn-lg" :class="{'btn-success': !validatingCodeInProgress, 'btn-link': validatingCodeInProgress}" :disabled="validatingCodeInProgress || invalid" @click.prevent="validateCode">
                          <span v-if="!validatingCodeInProgress">
                            <i class="material-icons">
                              check
                            </i>
                            {{ staticTexts.validateCode }}
                          </span>
                          <span v-if="validatingCodeInProgress">
                            <LoaderIndicator :theme="getCampaign.theme_style" :inversed="false" :size="40" />
                            {{ staticTexts.validating }}
                          </span>
                        </button>
                      </div>
                    </div>
                    <span class="error" v-if="errors">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </ValidationObserver>
            </div>
            <div class="form-body" v-if="retries_left===0">
              <div class="alert alert-danger">
                {{ staticTexts.tooManyRetries }}
              </div>
            </div>
            <!-- END / FORM BODY-->
            <!-- FORM BOTTOM-->
            <div class="form-bottom mt-3 animation-appear animation-delay-2">
              <p class="font-corp">
                {{ staticTexts.haveAnAccount }} <router-link class="color-custom-primary" :to="{'name': 'login'}"><strong>{{ staticTexts.initSession }}</strong></router-link>
              </p>
            </div>
          </form>
        </div>
        <div class="col-md-6">
          <figure class="animation-appear-right animation-delay-2">
            <img class="img-fluid" :src="getPinImage" />
          </figure>
        </div>
      </div>
      <div class="row justify-content-center" v-if="step === 3" key="step3">
        <div class="col-md-6 text-center">
          <h2 class="title color-custom-primary mb-2 animation-appear">
            {{ staticTexts.thanksForParticipating }}
          </h2>
          <p class="font-corp animation-appear animation-delay-1">
            {{ staticTexts.manyThanksForParticipating }}
          </p>
          <p class="text-align-center animation-appear animation-delay-2">
            <router-link :to="getNextUrl" class="btn btn-primary">{{ staticTexts.continue }} &rarr;</router-link>
          </p>
        </div>
      </div>
    </transition>

    <b-modal ref="modal-loading" hide-footer hide-header-close no-close-on-backdrop :title="staticTexts.waitFewSeconds ">
      <div class="text-center p-3 m-3">
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        <p class="lead mt-3 text-dark">{{ messageLoading }}</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* global gettext, interpolate */
/* global FB_LOGIN_URL */
/* global GO_LOGIN_URL */
/* global CURRENT_LANGUAGE_CODE */

import { mapGetters, mapActions, mapMutations } from 'vuex'
import { BFormInput, BFormCheckbox, BFormSelect, BFormTextarea, BModal, BSpinner } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import LoaderIndicator from '@/components/Hitsbook/LoaderIndicator'
import { v4 as uuidv4 } from 'uuid'
import { Settings } from 'luxon'
import { Datetime } from 'vue-datetime'

Settings.defaultLocale = 'es'

export default {
  components: {
    LoaderIndicator,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    BFormTextarea,
    Datetime,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner
  },
  computed: {
    ...mapGetters(['getCampaign', 'getCampaignId', 'getLoginRequired', 'getFormExtraFields']),
    askPasswords () {
      return this.getCampaign.request_password_on_registration_form
    },
    extraFields () {
      // TODO: Revisar que se ordene por el nuevo campo de orden que hizo jarr
      return this.getFormExtraFields.filter(item => item.request_to_vote).sort((a, b) => { return a.voting_order - b.voting_order })
    },
    getNextUrl () {
      if (this.nextUrl) {
        return this.nextUrl
      } else {
        return "/"
      }
    },
    getPinImage () {
      if (this.getCampaign.registration_pin_image) {
        return this.getCampaign.registration_pin_image
      } else {
        return '/static/img/register/img-register-03.png'
      }
    },
    getRegistrationImage () {
      if (this.getCampaign.registration_image) {
        return this.getCampaign.registration_image
      } else {
        return '/static/img/register/img-register-01.png'
      }
    },
    isEmailEnabled () {
      if (Array.isArray(this.getCampaign.registration_sources)) {
        return this.getCampaign.registration_sources.includes('email')
      }
      return false
    },
    isFacebookEnabled () {
      if (Array.isArray(this.getCampaign.registration_sources)) {
        return this.getCampaign.registration_sources.includes('facebook') && this.getCampaign.registration_sources_validated
      }
      return false
    },
    isGoogleEnabled () {
      if (Array.isArray(this.getCampaign.registration_sources)) {
        return this.getCampaign.registration_sources.includes('google') && this.getCampaign.registration_sources_validated
      }
      return false
    },
    staticTexts () {
      return {
        registerIn: interpolate(gettext("Regístrate en %s"), [this.getCampaign.name]),
        dataIsRequired: gettext("Para poder completar el registro necesitamos los siguientes datos:"),
        haveAnAccount: gettext("¿Ya tienes cuenta?"),
        initSession: gettext("Inicia Sesión"),
        registerWith: gettext("Regístrate con tu cuenta"),
        registerWithFacebook: gettext("FACEBOOK"),
        registerWithGoogle: gettext("GOOGLE"),
        registerWithOr: gettext("o"),
        facebook: gettext("Facebook"),
        google: gettext("Google"),
        registerWithEmail: gettext("o regístrate con tu email"),
        email: gettext("Email"),
        password: gettext("Contraseña"),
        repeatPassword: gettext("Repetir contraseña"),
        cancel: gettext("Cancelar"),
        sendValidationCode: gettext("Enviar código de validación"),
        sending: gettext("Enviando..."),
        clickSendCode: gettext('Haz click en "Enviar código" para que te llegue un PIN por email para validar tu registro.'),
        validateAndParticipate: gettext("¡Valida y participa!"),
        enterTheCodeReceived: gettext("Introduce el código que te hemos enviado por email a"),
        madeAMistake: gettext("¿Te has equivocado al escribirlo?"),
        fixNow: gettext("Corregir ahora"),
        codeNotReceived: gettext("¿No te ha llegado el código?"),
        sendAgain: gettext("Volver a enviar"),
        sent: gettext("Enviado"),
        validateCode: gettext("Validar código"),
        validating: gettext("Validando..."),
        tooManyRetries: gettext("Has agotado el número máximo de intentos. El PIN ha sido invalidado por motivos de seguridad. Pulsa en 'Volver a enviar' para reiniciar el proceso. Te enviaremos un nuevo PIN por email."),
        thanksForParticipating: gettext("¡Gracias por participar!"),
        manyThanksForParticipating: gettext("Muchas gracias por participar en la campaña"),
        continue: gettext("Continuar"),
        waitFewSeconds: gettext("Espere unos segundos..."),
        iAcceptThe: gettext("Acepto las"),
        legalBasis: gettext("Bases legales"),
        the: gettext("los"),
        termsAndConditions: gettext("Términos y condiciones de uso"),
        andThe: gettext("y la"),
        privacyPolicy: gettext("Política de privacidad"),
        code: gettext("Código")
      };
    }
  },
  methods: {
    ...mapActions([
      'registerCampaignInteractions',
      'mockAPICall',
      'performPendingAction',
      'doRequestEmailValidationCode',
      'doValidateEmail',
      'doRegisterByEmailToVote',
      'doRegisterByEmailToPreParticipate']),
    ...mapMutations(['setParticipationAttempt']),
    getInputClasses (classes) {
      classes['form-control'] = true
      classes['md-input'] = true
      return classes
    },
    fbLogin () {
      this.messageLoading = "Conectando con Facebook..."
      this.$refs['modal-loading'].show()

      let url = FB_LOGIN_URL + "?next=" + encodeURIComponent("/#/accounts/required-data")
      window.location = url
    },
    goLogin () {
      this.messageLoading = "Conectando con Google..."
      this.$refs['modal-loading'].show()

      let url = GO_LOGIN_URL + "&next=" + encodeURIComponent("/#/accounts/required-data")
      window.location = url
    },
    reSendCode () {
      if (!this.resendInProgress && !this.resent) {
        this.resendInProgress = true
        let payload = {
          email: this.form.email,
          token: this.form.token
        }
        this.doRequestEmailValidationCode(payload).then((response) => {
          this.retries_left = response.data.retries_left
          this.reSendCodeCompleted()
        })
        this.registerEvent('basic-registration-resend-code')
      }
    },
    getRegistrationPayload () {
      let payload = {
        gtm: this.$gtm,
        email: this.form.email,
        password: this.form.password,
        password_repeat: this.form.confirm_password,
        token: this.form.token,
        code: this.form.code,
        conditions_accepted: true
      }
      Object.keys(this.form.extra).forEach(key => {
        payload[key] = this.form.extra[key]
      })
      this.extraFields.forEach(item => {
        if (item.id.toString() in payload) {
          if (item.type === 'date') {
            payload[item.id.toString()] = payload[item.id.toString()].split("T")[0]
          }
        } else {
          if (item.type === 'checkbox') {
            payload[item.id.toString()] = false
          } else if (item.type === 'char' || item.type === 'text' || item.type === 'select') {
            payload[item.id.toString()] = ''
          } else {
            payload[item.id.toString()] = null
          }
        }
      })
      // console.log(payload)

      return payload
    },
    reSendCodeCompleted () {
      this.resendInProgress = false
      this.resent = true
      setTimeout(this.resentCleanup.bind(this), 5000)
    },
    resentCleanup () {
      this.resent = false
    },
    validateCode () {
      if (!this.resendInProgress && !this.resent) {
        this.validatingCodeInProgress = true
        let payload = {
          email: this.form.email,
          token: this.form.token,
          code: this.form.code
        }
        this.doValidateEmail(payload).then((response) => {
          this.registerEvent('basic-registration-valid-code')
          let method = this.doRegisterByEmailToPreParticipate
          if (this.getLoginRequired.pendingAction) {
            method = this.doRegisterByEmailToVote
          }
          method(this.getRegistrationPayload()).then((response) => {
            this.registerEvent('basic-registration-completed')
            this.nextUrl = this.getLoginRequired.url
            if (this.getLoginRequired.pendingAction) {
              this.performPendingAction()
            }
            this.$router.push(this.getNextUrl)
            // this.goToStep(3)
          }).catch((error) => {
            this.$refs.codeProvider.applyResult({
              errors: error.response.data.errors,
              valid: false,
              failedRules: {}
            })
            this.registerEvent('basic-registration-invalid-data')
            this.goToStep(2)
          })
        }).catch((error) => {
          this.retries_left = error.response.data.retries_left
          this.$refs.codeProvider.applyResult({
            errors: [gettext("El PIN no es válido. Asegúrese de escribirlo tal cual se lo hemos enviado por email.")],
            valid: false,
            failedRules: {}
          })
          this.registerEvent('basic-registration-invalid-code')
        }).finally(() => {
          this.validatingCodeInProgress = false
        })
      }
    },
    onSubmitStep1 (validate) {
      localize(this.lang)

      validate().then((isValid) => {
        if (isValid) {
          this.sendCodeInProgress = true
          // API Call
          let payload = {
            email: this.form.email,
            token: this.form.token
          }
          this.doRequestEmailValidationCode(payload).then((response) => {
            // console.log("response ok", response)
            this.retries_left = response.data.retries_left
            this.goToStep(2)
            this.registerEvent('basic-registration-send-code')
          }).catch((error) => {
            // console.log("response error", error.response)
            if ('email' in error.response.data) {
              this.$notify({
                message: error.response.data.email.join("<br>"),
                icon: 'add_alert',
                horizontalAlign: 'center',
                verticalAlign: 'bottom',
                type: 'danger'
              })
              this.registerEvent('basic-registration-error-email-bad-domain')
            } else {
              this.$notify({
                message: error.response.data.errors.join("<br>"),
                icon: 'add_alert',
                horizontalAlign: 'center',
                verticalAlign: 'bottom',
                type: 'danger'
              })
              this.registerEvent('basic-registration-error-email-already-exists')
            }
          }).finally(() => {
            this.sendCodeInProgress = false
          })
        } else {
          // console.log("NOT valid form")
        }
      }).catch(() => {
        // console.log("An unexpected error occurred while validating form values")
      })
    },
    goToStep (number) {
      this.step = number
      if (number === 2) {
        this.wrongEmail = false
      }
      this.registerEvent('basic-registration-step-' + number)
    },
    registerEvent (eventName) {
      let interactions = []
      interactions.push({ 'type': 'event', 'url': window.location.href, 'event': eventName })
      this.registerCampaignInteractions({ 'campaign': this.getCampaignId, interactions })
    },
    getFieldRules (field) {
      let rules = []
      if (field.is_required_to_vote) {
        if (field.type === "checkbox") {
          rules.push("checkboxrequired")
        } else {
          rules.push("required")
        }
      }
      if (field.type === "integer") {
        rules.push("integer")
      }
      if (field.type === "positive_integer") {
        rules.push("integer")
        rules.push("min_value:0")
      }
      return rules.join("|")
    },
    getFieldClasses (field) {
      return field.css_classes + " mb-2"
    },
    getFieldSelectOptions (field) {
      return [...field.options.options]
    },
    getFieldRows (field) {
      if ("rows" in field.options) {
        return field.options.rows
      }
      return 4
    }
  },
  data () {
    return {
      step: 1,
      messageLoading: gettext("Cargando..."),
      sendCodeInProgress: false,
      resendInProgress: false,
      resent: false,
      wrongEmail: false,
      nextUrl: null,
      lang: CURRENT_LANGUAGE_CODE,
      validatingCodeInProgress: false,
      retries_left: 10,
      form: {
        email: null,
        password: null,
        token: null,
        extra: {},
        confirm_password: null,
        legal_basis_acceptance: 'not_accepted',
        newsletter_acceptance: 'not_accepted',
        code: ""
      }
    }
  },
  created () {
    if (this.lang === "pt-br") {
      this.lang = "pt_BR"
    }
    localize(this.lang)
  },
  mounted () {
    if (this.form.token === null) {
      this.form.token = uuidv4()
    }
    this.registerEvent('basic-registration-step-1')
    this.setParticipationAttempt(false)
    localize(this.lang)
  }
};

</script>

<style lang="scss">
  .field {

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    label.custom-control-label {
      padding: 3px;
    }

  }
  .btn-social-login {
    img {
      height: 28px;
      width: 28px;
    }
    border: 1px solid rgba(0,0,0,0);
    transition: all 0.3s ease;
    &:hover {
      background-color: white !important;
      border: 1px solid black;
      color: black !important;
    }
  }

  .form-group {
    &.valid {
      .form-control {
        background-image: linear-gradient(to top, var(--text-color) 2px, rgba(70, 150, 10, 0) 2px),
                          linear-gradient(to top, green 1px, rgba(20, 210, 20, 0) 1px) !important;
      }
    }
    &.invalid {
      .form-control {
        background-image: linear-gradient(to top, var(--text-color) 2px, rgba(70, 150, 10, 0) 2px),
                          linear-gradient(to top, red 1px, rgba(20, 210, 20, 0) 1px) !important;
      }
    }
  }
  .custom-control {
    .error {
      color: red;
    }
  }
  .current-email {
    cursor: pointer;
  }
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all .2s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(15px);
    opacity: 0;
  }

  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all .2s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(15px);
    opacity: 0;
  }

  .slide-down-enter-active, .slide-down-leave-active {
    transition: all .2s ease;
  }
  .slide-down-enter, .slide-down-leave-to {
    transform: translateY(-15px);
    opacity: 0;
  }

  .wrong-email {
    margin-top: -10px;
    .btn-xs {
      padding: 5px;
      text-transform: none;
    }
  }

  .fade-out {
    animation: fade-out 5s ease-out forwards;
  }

  @keyframes fade-out {
    0% {
      opacity: 0.2;
      transform: scale(0.9) translateX(0px);
    }
    5% {
      opacity: 1;
      transform: scale(1.1);
    }
    10% {
      opacity: 1;
      transform: scale(1);
    }
    90% {
      opacity: 1;
      transform: translateX(0px);
    }
    100% {
      opacity: 0;
      transform: translateX(15px);
    }
  }

  .animation-appear {
    animation: appear 0.2s ease-in forwards;
    opacity:0;
  }

  .animation-appear-right {
    animation: appear-right 0.2s ease-in forwards;
    opacity:0;
  }

  .modal-content {
    color: black;
  }

  @for $i from 1 through 20 {
    .animation-delay-#{$i} {
      animation-delay: $i * 0.04s
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
      transform: translateY(15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px)
    }
  }

  @keyframes appear-right {
    0% {
      opacity: 0;
      transform: translateX(15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px)
    }
  }

</style>
