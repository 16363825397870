<template>
    <div class="jumbotron jumbotron-full page-transition page-transition-slide-up" v-if="show" :class="modeClass">
      <carousel v-if="slideList" :items="1" :nav="false" :loop="true" :autoplay="config.autoplay" :autoplayTimeout="config.autoplay_delay" :dots="false">
        <div v-for="slide in slideList" :key="slide.id" ref="slide">
          <div class="jumbotron-item jumbotron-light">
            <div class="jumbotron-item-img" v-if="slide.background_image">
              <img ref="parallax" class="parallax-full" :src="slide.background_image" />
            </div>
            <div class="jumbotron-item-content">
              <div class="jumbotron-item-content--image">
                <img :src="slide.featured_image_mobile" class="d-sm-none" />
                <img :src="slide.featured_image" class="d-none d-sm-block" />
              </div>
              <div class="jumbotron-item-content--title">
                <h6 v-if="showTimeUntil">{{timeUntil}}</h6>
              </div>
            </div>
          </div>
        </div>
      </carousel>
    </div>
</template>

<script>
/* global LANGUAGE_CODE */
/*
  mode:
    default - grande
    box     - compacto
    aside   - lateral
*/
import carousel from 'vue-owl-carousel2'
import { mapGetters } from 'vuex'
import simpleParallax from 'simple-parallax-js'

import moment from 'moment'

let languageCode = LANGUAGE_CODE;
if (languageCode === "es-es") {
  languageCode = "es";
}

import("moment/locale/" + languageCode).then(function () {
  moment.locale(languageCode);
});

export default {
  name: 'MainBanner',
  components: {
    carousel
  },
  props: ['mode'],
  data () {
    return {
      show: true,
      images: [],
      sp: null
    }
  },
  mounted () {
  },
  watch: {
    slideList () {
      this.show = false
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  updated () {
    var image = document.getElementsByClassName('parallax-full')
    this.sp = new simpleParallax(image, {
      delay: 0.6,
      transition: 'cubic-bezier(0,0,0,1)'
    })
  },
  computed: {
    ...mapGetters(['getCampaign']),
    showTimeUntil () {
      return this.getCampaign.show_countdown
    },
    slideList () {
      return this.getCampaign.slides
    },
    timeUntil () {
      return moment(this.getCampaign.ends).fromNow()
    },
    modeClass () {
      let className = ''
      if (this.mode !== 'full') {
        className = 'jumbotron-' + this.mode
      }
      if (this.mode !== 'box') {
        className = className + " text-center"
      }
      return className
    },
    config () {
      return {
        'autoplay': this.getCampaign.slider_autoplay,
        'autoplay_delay': this.getCampaign.slider_autoplay_delay
      }
    }
  }
};
</script>
