<template>
  <div v-html="getContent" :key="contentKey">
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'infopagelegaldetail',
  data () {
    return {
      default: {
        title: "...",
        content: "......"
      }
    }
  },
  computed: {
    ...mapGetters(['getCampaign']),
    contentKey () {
      let path = this.$route.fullPath
      if (path.includes('legal_basis')) {
        return 'legal_basis'
      }
      if (path.includes('privacy_policy')) {
        return 'privacy_policy'
      }
      if (path.includes('terms')) {
        return 'terms'
      }
      if (path.includes('cookies_policy')) {
        return 'cookies_policy'
      }
      return "other"
    },
    getContent () {
      let path = this.$route.fullPath
      if (path.includes('legal_basis')) {
        return this.getCampaign.legal_basis
      }
      if (path.includes('privacy_policy')) {
        return this.getCampaign.privacy_policy
      }
      if (path.includes('terms')) {
        return this.getCampaign.terms
      }
      if (path.includes('cookies_policy')) {
        return this.getCampaign.cookies_policy
      }
      return "..."
    }
  },
  components: {
  }
}

</script>
