<template>
  <li
    class="list-group-item"
    :class="'item-mode-' + mode + ' manager-mode-' + managerMode"
    v-if="mode === 'view'"
  >
    <div class="list-group-item--ico">
      <i class="material-icons">
        child_care
      </i>
    </div>
    <div class="list-group-item--text p-0">
      <div class="list-group-item--text---title">
        <h4 class="title m-0">{{ value.name }} {{ value.surname }}</h4>
        <i class="material-icons" :class="overall_status.class">
          {{ overall_status.icon }}
        </i>
      </div>
      <div class="btn-group dropup" >
        <button
          class="btn btn-card-options btn-just-icon btn-light btn-link btn-round"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          @click="dropDown = !dropDown"
        >
          <i class="material-icons text_align-center">more_vert</i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-right"
          :class="{ show: dropDown }"
          style="will-change: transform;"
        >
          <button
            class="btn btn-warning btn-link btn-block text-left"
            @click="handleEdit"
          >
            <span class="btn-label"><i class="material-icons">edit</i></span>
            {{ staticTexts.edit }}
          </button>
          <button
            class="btn btn-danger btn-link btn-block text-left"
            @click="handleDelete"
          >
            <span class="btn-label"
              ><i class="material-icons">delete_outline</i></span
            >
            {{ staticTexts.delete }}
          </button>
        </div>
      </div>
    </div>
  </li>
  <li class="list-group-item" v-else>
    <ValidationObserver v-slot="{ validate }" slim>
      <div class="form-row">
        <div class="col-12">
          <h3 class="title text-left text-primary mb-0 mt-0">
            {{ staticTexts.minorsData }}
          </h3>
        </div>
        <div class="col-lg-5 col-md-6">
          <validation-provider
            :name="staticTexts.minorName"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="form-group md-field md-theme-default" :class="classes">
              <b-form-input
                v-model="minor.name"
                :placeholder="staticTexts.minorName"
                class="font-corp md-input no-dark"
              ></b-form-input>
              <span class="error" v-if="errors">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <div class="col-lg-5 col-md-6">
          <validation-provider
            :name="staticTexts.minorSurname"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="form-group md-field md-theme-default" :class="classes">
              <b-form-input
                v-model="minor.surname"
                :placeholder="staticTexts.minorSurname"
                class="font-corp md-input no-dark"
              ></b-form-input>
              <span class="error" v-if="errors">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <div class="col-lg-2 col-md-6">
          <validation-provider
            :name="staticTexts.minorAge"
            rules="required|numeric"
            v-slot="{ classes, errors }"
          >
            <div class="form-group md-field md-theme-default" :class="classes">
              <b-form-input
                v-model="minor.age"
                :placeholder="staticTexts.minorAge"
                class="font-corp md-input no-dark"
              ></b-form-input>
              <span class="error" v-if="errors">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <!-- <div class="col-lg-5 col-md-6">
          <validation-provider :name="staticTexts.minorID" rules="" v-slot="{ classes, errors }">
            <div class="form-group md-field md-theme-default" :class="classes">
                <b-form-input v-model="minor.id" :placeholder="staticTexts.minorID" class="font-corp md-input no-dark"></b-form-input>
                <span class="error" v-if="errors">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div> -->
        <!-- <div class="col-lg-7">
          <validation-provider :name="staticTexts.minorAddress" rules="" v-slot="{ classes, errors }">
            <div class="form-group md-field md-theme-default" :class="classes">
                <b-form-input v-model="minor.address" :placeholder="staticTexts.minorAddress" class="font-corp md-input no-dark"></b-form-input>
                <span class="error" v-if="errors">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div> -->
      </div>
      <hr />
      <div class="form-row">
        <div class="col-12">
          <h5 class="title mb-0 text-left text-primary">{{ staticTexts.firstTutorData }}</h5>
        </div>
        <div class="col-lg-5 col-md-6">
          <validation-provider
            :name="staticTexts.tutor1Name"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="form-group md-field md-theme-default" :class="classes">
              <b-form-input
                v-model="minor.tutor1.name"
                :placeholder="staticTexts.tutor1Name"
                class="no-dark"
              ></b-form-input>
              <span class="error" v-if="errors">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <div class="col-lg-7 col-md-6">
          <validation-provider
            :name="staticTexts.tutor1Surname"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="form-group md-field md-theme-default" :class="classes">
              <b-form-input
                v-model="minor.tutor1.surname"
                :placeholder="staticTexts.tutor1Surname"
                class="no-dark"
              ></b-form-input>
              <span class="error" v-if="errors">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <div class="col-lg-5 col-md-6">
          <validation-provider
            :name="staticTexts.tutor1ID"
            rules="required"
            v-slot="{ classes, errors }"
          >
            <div class="form-group md-field md-theme-default" :class="classes">
              <b-form-input
                v-model="minor.tutor1.id"
                :placeholder="staticTexts.tutor1ID"
                class="no-dark"
              ></b-form-input>
              <span class="error" v-if="errors">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <div class="col-lg-7 col-md-6">
          <validation-provider
            :name="staticTexts.tutor1Email"
            rules="required|email"
            v-slot="{ classes, errors }"
          >
            <div class="form-group md-field md-theme-default" :class="classes">
              <b-form-input
                v-model="minor.tutor1.email"
                :placeholder="staticTexts.tutor1Email"
                class="no-dark"
              ></b-form-input>
              <span class="error" v-if="errors">{{ errors[0] }}</span>
            </div>
          </validation-provider>
        </div>
        <div class="col-lg-5 col-md-6"></div>
        <div class="col-lg-7 col-md-6">
          <SignBBLL
            v-model="minor.tutor1.signature"
            btnExtraClass="btn-block"
            :signedLabel="staticTexts.tutor1Signed"
            :legalText="legalText"
            :unsignedLabel="staticTexts.tutor1Unsigned"
          ></SignBBLL>
        </div>
      </div>
      <hr />
      <div class="form-row">
        <div class="col-12">
          <h5 class="title mb-0 text-left text-primary">
            {{ staticTexts.secondTutorData }}
          </h5>
        </div>
        <template v-if="!minor.tutor2_non_exists">
          <div class="col-lg-5 col-md-6">
            <validation-provider
              :name="staticTexts.tutor2Name"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div
                class="form-group md-field md-theme-default"
                :class="classes"
              >
                <b-form-input
                  v-model="minor.tutor2.name"
                  :placeholder="staticTexts.tutor2Name"
                  class="no-dark"
                ></b-form-input>
                <span class="error" v-if="errors">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
          <div class="col-lg-7 col-md-6">
            <validation-provider
              :name="staticTexts.tutor2Surname"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div
                class="form-group md-field md-theme-default"
                :class="classes"
              >
                <b-form-input
                  v-model="minor.tutor2.surname"
                  :placeholder="staticTexts.tutor2Surname"
                  class="no-dark"
                ></b-form-input>
                <span class="error" v-if="errors">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
          <div class="col-lg-5 col-md-6">
            <validation-provider
              :name="staticTexts.tutor2ID"
              rules="required"
              v-slot="{ classes, errors }"
            >
              <div
                class="form-group md-field md-theme-default"
                :class="classes"
              >
                <b-form-input
                  v-model="minor.tutor2.id"
                  :placeholder="staticTexts.tutor2ID"
                  class="no-dark"
                ></b-form-input>
                <span class="error" v-if="errors">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
          <div class="col-lg-7 col-md-6">
            <validation-provider
              :name="staticTexts.tutor2Email"
              rules="required|email"
              v-slot="{ classes, errors }"
            >
              <div
                class="form-group md-field md-theme-default"
                :class="classes"
              >
                <b-form-input
                  v-model="minor.tutor2.email"
                  :placeholder="staticTexts.tutor2Email"
                  class="no-dark"
                ></b-form-input>
                <span class="error" v-if="errors">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
        </template>
        <div class="col-lg-5 col-md-6 text-left">
          <b-form-checkbox
            v-model="minor.tutor2_non_exists"
            :value="true"
            :unchecked-value="false"
          >
            El menor no dispone de segundo tutor legal
          </b-form-checkbox>
        </div>
        <div class="col-lg-7 col-md-6">
          <SignBBLL
            v-if="!minor.tutor2_non_exists"
            v-model="minor.tutor2.signature"
            btnExtraClass="btn-block"
            :signedLabel="staticTexts.tutor2Signed"
            :legalText="legalText"
            :unsignedLabel="staticTexts.tutor2Unsigned"
          ></SignBBLL>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12 text-right mt-3 col-btn--responsive">
          <button
            class="btn btn-lg btn-light-blue float-left"
            @click="handleCancel"
          >
            <span class="material-icons">arrow_back</span>
            {{ staticTexts.cancel }}
          </button>
          <button v-if="isEditing"
            class="btn btn-lg btn-light-red text-left"
            @click="handleDelete"
          >
            <span class="material-icons">delete</span>
            {{ staticTexts.deleteMinor }}
          </button>
          <button class="btn btn-lg btn-success" @click="handleClose(validate)">
            <span class="material-icons">done</span>
            {{ staticTexts.confirm }}
          </button>
        </div>
      </div>
    </ValidationObserver>
  </li>
</template>

<script>
/* global gettext */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BFormInput, BFormCheckbox } from "bootstrap-vue";
import SignBBLL from "@/components/Hitsbook/SignBBLL";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormCheckbox,
    SignBBLL
  },
  props: {
    value: Object,
    index: Number,
    legalText: String,
    managerMode: String
  },
  computed: {
    overall_status() {
      let completeCount = 0;
      if (
        this.minor.tutor1.signature &&
        this.minor.tutor1.name &&
        this.minor.tutor1.email
      ) {
        completeCount += 1;
      }
      if (
        this.minor.tutor2.signature &&
        this.minor.tutor2.name &&
        this.minor.tutor2.email
      ) {
        completeCount += 1;
      } else if (this.minor.tutor2_non_exists) {
        completeCount += 1;
      }
      if (completeCount === 1) {
        return { icon: "done", class: "text-warning" };
      }
      if (completeCount === 2) {
        return { icon: "done_all", class: "text-success" };
      }
      return { icon: "remove_done", class: "text-danger" };
    },
    staticTexts() {
      return {
        tutor1Signed: gettext("Tutor 1 firmado"),
        tutor1Unsigned: gettext("Firma ahora"),
        tutor2Signed: gettext("Tutor 2 firmado"),
        tutor2Unsigned: gettext("Firmar ahora"),
        minorsData: gettext("Datos del menor"),
        firstTutorData: gettext("Datos del primer tutor legal"),
        secondTutorData: gettext("Datos del segundo tutor legal"),
        edit: gettext("Editar"),
        deleteMinor: gettext("Borrar menor"),
        delete: gettext("Borrar"),
        cancel: gettext("Cancelar"),
        confirm: gettext("Guardar menor"),
        fixErrors: gettext(
          "Debes completar todos los datos del menor y sus tutores"
        ),
        signError: gettext("Los tutores legales deben firmar la autorización"),
        minorName: gettext("Nombre del menor"),
        minorSurname: gettext("Apellidos"),
        minorAge: gettext("Edad"),
        minorID: gettext("DNI del menor"),
        minorAddress: gettext("Dirección del menor"),
        tutor1Name: gettext("Nombre del tutor 1"),
        tutor1Surname: gettext("Apellidos del tutor 1"),
        tutor1Email: gettext("Email del tutor 1"),
        tutor1ID: gettext("DNI del tutor 1"),
        tutor1Address: gettext("Dirección del tutor 1"),
        tutor2Name: gettext("Nombre del tutor 2"),
        tutor2Surname: gettext("Apellidos del tutor 2"),
        tutor2Email: gettext("Email del tutor 2"),
        tutor2ID: gettext("DNI del tutor 2"),
        tutor2Address: gettext("Dirección del tutor 2")
      };
    }
  },
  data() {
    return {
      minor: {
        name: null,
        surname: null,
        age: 2,
        id: null,
        address: null,
        tutor1: {
          name: null,
          surname: null,
          email: null,
          id: null,
          signature: null
        },
        tutor2_non_exists: false,
        tutor2: {
          name: null,
          surname: null,
          email: null,
          id: null,
          signature: null
        }
      },
      mode: "view",
      isEditing: false,
      dropDown: false
    };
  },
  watch: {
    value(newValue) {
      this.minor = newValue;
      if (!this.minor.surname) {
        this.handleEdit();
      }
    }
  },
  methods: {
    triggerChange() {
      this.$emit("change", { value: this.minor, index: this.index });
    },
    handleCreate() {
      this.mode = "form";
      this.isEditing = false
      this.$emit("modeChange", this.mode);
      this.minor = this.value;
      this.dropDown = false;
    },
    handleEdit() {
      this.mode = "form";
      this.isEditing = true
      this.$emit("modeChange", this.mode);
      this.minor = this.value;
      this.dropDown = false;
    },
    handleDelete() {
      this.isEditing = false
      this.$emit("delete", { index: this.index });
      this.dropDown = false;
    },
    handleCancel() {
      if (this.isEditing) {
        this.mode = "view";
        this.minor = this.value;
        this.$emit("modeChange", this.mode);
      } else {
        this.$emit("delete", { index: this.index });
      }
      this.isEditing = false
      this.dropDown = false;
    },
    handleClose(validate) {
      validate().then(isValid => {
        if (isValid) {
          if (
            this.minor.tutor1.signature === null ||
            (!this.minor.tutor2_non_exists &&
              this.minor.tutor2.signature === null)
          ) {
            this.$swal.fire({
              title: "Error",
              text: this.staticTexts.signError,
              icon: "error",
              timer: 4000,
              timerProgressBar: true
            });
            return;
          }
          this.triggerChange();
          this.mode = "view";
          this.$emit("modeChange", this.mode);
        } else {
          this.$swal.fire({
            title: "Error",
            text: this.staticTexts.fixErrors,
            icon: "error",
            timer: 4000,
            timerProgressBar: true
          });
        }
      });
    }
  },
  created() {
    this.minor = this.value;
    if (!this.minor.surname) {
      this.handleCreate();
    }
  }
};
</script>
