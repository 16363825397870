<template>
  <span class="badge bagde-small bagde-campaign font-corp --effect-updated --effect-base" :class="{'--play-effect': valueChanged}" data-toggle="tooltip" data-placement="top">
    <i :class="iconClass">{{iconText}}</i>
    {{value}}
  </span>
</template>

<script>
export default {
  computed: {
    itemID () {
      return this.item.id
    }
  },
  data () {
    return {
      initial: false,
      valueChanged: false,
      tmr: null
    }
  },
  watch: {
    itemID (to, from) {
      this.initial = true
    },
    value (to, from) {
      // console.log('numViews changed')
      this.animateValue()
    }
  },
  methods: {
    animateValue () {
      if (this.initial) {
        this.initial = false
        return
      }
      this.valueChanged = true
      this.tmr = setTimeout(this.clearAnimateViews.bind(this), 800)
    },
    clearAnimateViews () {
      this.valueChanged = false
      clearTimeout(this.tmr)
      this.tmr = null
    }
  },
  props: {
    item: Object,
    iconClass: String,
    iconText: String,
    value: Number
  }
};
</script>
