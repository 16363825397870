<template>
  <div
    class="card card-video-thumb" :data-orientation="item.orientation" :data-id="item.id" :key="item.id" ref="cardElement" @mouseover="mouseOver"
    :class="{'card-video-thumb-compact': isCompact, 'card-active': isActive}"
  >
    <div class="card-header">
      <!-- Icono variable segun contenido-->
      <router-link :to="{ name: 'content', params: { slug: item.slug }}" class="card-header-link">
        <i class="material-icons card-header--i">{{ iconShowed }}</i>
        <div class="bar-ranking">
          <strong
            v-if="showPosition"
            class="bar-ranking--number font-corp"
            :class="getRankingClass"
          >#{{ getPosition }}</strong>
          <i v-if="isFromSocialNetwork" class="icon-social--item fab fa-fw" :class="itemIcon"></i>
        </div>
        <img v-if="isVideo" v-show="isGIFLoaded" class="card-video-thumb--gif" ref="gifImg" />
        <img class="card-video-thumb--jpg" v-show="isThumbLoaded || isGIFLoading" ref="thumbImg" />
        <div v-show="!isThumbLoaded" :style="boxStyle">
          <content-loader
                  :width="parentWidth"
                  :height="parentHeight"
                  :viewBox="'0 0 ' + parentWidth + ' ' + parentHeight"
                  :speed="2"
                  primaryColor="var(--content-loader-primary)"
                  secondaryColor="var(--content-loader-secondary)"
                >
                  <rect x="0" y="0" rx="0" ry="0" :width="parentWidth" :height="parentHeight" />
          </content-loader>
        </div>
      </router-link>
    </div>
    <div class="card-body">
      <div class="card-body--top">
        <h6 class="card-custom-user font-corp">@{{item.author}}</h6>
        <div class="bagde-group">
          <span
            class="badge bagde-small bagde-campaign font-corp"
            data-toggle="tooltip"
            data-placement="top"
            :title="staticTexts.timesWatched"
            v-if="ranking === 'most_viewed'"
          >
            <i class="material-icons">remove_red_eye</i>
            {{item.num_views}}
          </span>
          <span
            class="badge bagde-small bagde-campaign font-corp"
            data-toggle="tooltip"
            data-placement="top"
            :title="staticTexts.timesVoted"
            v-if="ranking === 'most_liked'"
          >
            <i class="fas fa-thumbs-up"></i>
            {{item.num_likes}}
          </span>
          <span
            class="badge bagde-small bagde-campaign font-corp"
            data-toggle="tooltip"
            data-placement="top"
            :title="staticTexts.timesShared"
            v-if="ranking === 'most_shared'"
          >
            <i class="fas fa-share-alt"></i>
            {{item.num_shares}}
          </span>
          <span
            class="badge bagde-small bagde-campaign font-corp"
            data-toggle="tooltip"
            data-placement="top"
            :title="staticTexts.timesApplauded"
            v-if="ranking === 'most_applauded'"
          >
            <i class="fas fa-sign-language"></i>
            {{item.num_applauses}}
          </span>
          <span
            class="badge bagde-small bagde-campaign font-corp"
            data-toggle="tooltip"
            data-placement="top"
            :title="staticTexts.timesReacted"
            v-if="ranking.includes('reaction-')"
          >
            <i :class="'em em-' + getReactionFromRanking(ranking).icon"></i>
            {{item.num_reactions}}
          </span>
        </div>
      </div>
      <h4 class="card-custom-title">
        <router-link
          :to="{ name: 'content', params: { slug: item.slug }}"
          class="card-body-link color-custom-primary"
        >{{item.title}}</router-link>
      </h4>
    </div>
  </div>
</template>

<script>
/* global gettext */
import { ContentLoader } from "vue-content-loader"

export default {
  name: "ContentListItem",
  components: { ContentLoader },
  props: {
    item: Object,
    campaign: Object,
    isCompact: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      default: false
    },
    showPosition: {
      type: Boolean,
      default: false
    },
    ranking: {
      type: String,
      default: ""
    },
    index: Number,
    paginator: Object
  },
  data() {
    return {
      isMounted: false,
      isThumbLoaded: false,
      thumb: null,
      gif: null,
      isGIFLoaded: false,
      isGIFLoading: false
    };
  },
  watch: {
    item (from, to) {
      this.isThumbLoaded = false
      this.isGIFLoaded = false
      this.loadThumb()
      this.$forceUpdate()
    }
  },
  computed: {
    boxStyle () {
      return {
        width: this.parentWidth + "px",
        height: this.parentHeight + "px"
      }
    },
    parentWidth () {
      return 300
    },
    parentHeight () {
      return 300
    },
    isFromSocialNetwork() {
      return (
        this.item.clip_type === "twitter" || this.item.clip_type === "instagram"
      );
    },
    itemIcon() {
      return "fa-" + this.item.clip_type;
    },
    getRankingClass() {
      if (this.getPosition === 1) {
        return "ranking--gold";
      } else if (this.getPosition === 2) {
        return "ranking--silver";
      } else if (this.getPosition === 3) {
        return "ranking--bronze";
      }
      return "ranking--others";
    },
    getPosition() {
      return this.index + (this.paginator.page - 1) * this.paginator.size + 1;
    },
    iconShowed() {
      if (this.isVideo) {
        return "play_circle_filled";
      } else {
        return "photo_size_select_actual";
      }
    },
    isVideo() {
      return (
        this.item.media_type === "video_internal" ||
        this.item.media_type === "video_external" ||
        this.item.media_type === "video"
      );
    },
    isVideoInternal() {
      return this.item.media_type === "video_internal";
    },
    isPhoto() {
      return (
        this.item.media_type === "photo" || this.item.media_type === "image"
      );
    },
    staticTexts() {
      return {
        timesShared: gettext("Veces compartido"),
        timesVoted: gettext("Veces votado"),
        timesWatched: gettext("Veces visto"),
        timesApplauded: gettext("Veces aplaudido"),
        timesReacted: gettext("Veces reaccionado")
      };
    }
  },
  methods: {
    loadThumb () {
      this.thumb = new Image()
      this.thumb.onload = this.thumbLoaded.bind(this)
      this.thumb.src = this.item.url // para usar thumb_url habria que revisar esas miniaturas
    },
    getReactionFromRanking(ranking) {
      return this.getReaction(ranking.split("-").slice(1).join("-"))
    },
    thumbLoaded () {
      if (typeof this.$refs.thumbImg !== 'undefined') {
        this.$refs.thumbImg.src = this.thumb.src
      }
      this.isThumbLoaded = true
    },
    loadGif () {
      this.gif = new Image()
      this.gif.onload = this.gifLoaded.bind(this)
      this.isGIFLoading = true
      this.gif.src = this.item.gif
    },
    gifLoaded () {
      if (typeof this.$refs.gifImg !== 'undefined') {
        this.$refs.gifImg.src = this.gif.src
      }
      this.isGIFLoaded = true
      this.isGIFLoading = false
    },
    mouseOver () {
      if (this.isVideo && !this.isGIFLoaded && !this.isGIFLoading) {
        this.loadGif()
      }
    },
    getReaction (type) {
      return this.campaign.reactions.filter((item) => { return item.code === type })[0]
    }
  },
  mounted () {
    this.isMounted = true
    this.loadThumb()
  }
};
</script>
<style lang="scss">
h4.card-custom-title, h6.card-custom-user {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
