<template>
  <div class="donutChart" :class="donutVariantClass">
    <transition name="fade" mode="out-in">
      <div v-if="isLoading" class="loading" key="value">
        <div class="number-info" :class="numberClass">
          <div class="number-info--date">
            <small>...</small>
          </div>
          <div class="number-info--description">
            <p>Loading</p>
          </div>
        </div>
      </div>
      <div v-else key="percent">
        <StatNumber :value="value" :extraClasses="numberClass" :description="description" :type="type" />
        <svg width="100%" height="100%" viewBox="0 0 42 42">
          <circle cx="21" cy="21" r="16" :stroke-dasharray="getStrokeDasharray"
              stroke-dashoffset="25">
          </circle>
        </svg>
      </div>
    </transition>
  </div>
</template>

<script>

import StatNumber from '@/components/Hitsbook/StatNumber.vue'

export default {
  components: {
    StatNumber
  },
  props: {
    variant: {
      type: String,
      default: 'type1'
    },
    value: {
      default: 0
    },
    type: {
      type: String,
      default: 'percentage',
      validator: function (value) {
        return ['percentage', 'number', 'time'].indexOf(value) !== -1
      }
    },
    percent: {
      default: null
    },
    description: {
      type: String,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getPercent () {
      let parsed = this.percent
      if (this.percent !== null && this.percent !== 0) {
        if (this.type === 'percent') {
          parsed = parsed.slice(0, -1)
        }
        return parseInt(parsed)
      }
      return 100
    },
    getStrokeDasharray () {
      let value = this.getPercent
      let complementary = 100 - value
      return (value + " " + complementary)
    },
    donutVariantClass () {
      return "donutChart-" + this.variant
    },
    numberClass () {
      if (this.variant === 'type2') {
        return 'number-info--med'
      }
      return ""
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
};
</script>

<style lang="scss">
.donutChart {
    position: relative;
    max-width: 100%;

    .number-info {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        z-index: 2;
    }

    svg {
        width: 100%;
        height: 100%;
        fill: none;
        position: relative;
        z-index: 5;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        border-radius: 100%;
        z-index: 1;
    }

    &.donutChart-type1 {
        width: 20em;
        margin: 0 auto 30px;
        transform: scale(1.1);

        @media screen and (max-width: 767px) {
           width: 15em;
        }

        svg {
            stroke-width: 1;
            stroke:  rgb(
              var(--red-primary),
              var(--green-primary),
              var(--blue-primary)
            );
        }

        &::after {
            border: solid 4px rgba(0, 0, 0, 0.2);
            top: 5px;
            left: 5px;
            right: 5px;
            bottom: 5px;
        }
    }

}
.donutChart {
  transition: all .2s ease;
  background: linear-gradient(0deg, rgba(var(--theme-contrast-color), var(--theme-contrast-color), var(--theme-contrast-color), 0.5) 0%, rgba(var(--theme-contrast-color), var(--theme-contrast-color), var(--theme-contrast-color), 0) 90%);
  border-radius: 300px;
  .number-info--date {
    transition: transform .2s ease;
  }
}
.donutChart:hover {
  background: linear-gradient(0deg, rgba(var(--theme-contrast-color), var(--theme-contrast-color), var(--theme-contrast-color), 1) 0%, rgba(var(--theme-contrast-color), var(--theme-contrast-color), var(--theme-contrast-color), 0) 90%);
  &::after {
    border-color: rgba(var(--theme-contrast-color), var(--theme-contrast-color), var(--theme-contrast-color), 1) !important;
  }
  .number-info--date {
    transform: scale(1.1);
  }
}
.donutChart.donutChart-type2 svg {
  transform: scale(1.18);
}
.donutChart.donutChart-type1 svg, .donutChart.donutChart-type3 svg {
  transform: scale(1.25);
}
.donutChart.donutChart-type1 .loading svg, .donutChart.donutChart-type3 .loading svg {
  stroke-width: 0.1;
  transform: scale(1);
}
.fade-enter-active, .fade-leave-active {
  transition: all .1s ease;
}
.fade-enter {
  opacity: 0;
  transform: scale(0);
}
.fade-leave-to {
  opacity: 0;
  transform: scale(0);
}

</style>
