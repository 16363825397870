<template>
  <div class="reaction-box" v-show="isEnabled">
    <div class="vote--animation" ref="rvoteanim" v-show="showAnimation">
      <div><i class="em-svg" :class="'em-' + animationIcon" aria-role="presentation"></i></div>
    </div>

    <div v-if="userReaction" class="reactions">
      <button class="btn btn-voted btn-success btn-round btn-fab" :class="alreadyVotedBtnClass" @mousedown="cancelReactionStart" @touchstart="cancelReactionStart" @mouseup="cancelReactionStop" @touchend="cancelReactionStop" @touchcancel="cancelReactionStop" @mouseleave="cancelReactionStop">
        <span v-show="cancelReactionAttemptInProgress">
          <i class="fas fa-times"></i>
        </span>
        <span v-show="!cancelReactionAttemptInProgress">
          <i class="em-svg" :class="'em-' + userReaction.icon" aria-role="presentation" :aria-label="userReaction.name"></i>
        </span>
        <b-progress v-if="cancelReactionAttemptInProgress" class="btn-bottom--bar" variant="dark" :value="cancelReactionCounter" :max="100"></b-progress>
      </button>
    </div>
    <div v-else class="reactions">
      <button class="btn btn-round btn-fab btn-reaction-icon" v-for="reaction in reactions" :key="reaction.id" @click="performReaction(reaction.code)"><i class="em-svg" :class="'em-' + reaction.icon" aria-role="presentation" :aria-label="reaction.name"></i></button>
    </div>

    <div ref="particles" class="particles-container">
      <div class="triangle">
        <div class="square"></div>
        <div class="circle"></div>
        <div class="circle2"></div>
      </div>
      <div class="triangle">
        <div class="square"></div>
        <div class="circle"></div>
        <div class="circle2"></div>
      </div>
      <div class="triangle">
        <div class="square"></div>
        <div class="circle"></div>
        <div class="circle2"></div>
      </div>
      <div class="triangle">
        <div class="square"></div>
        <div class="circle"></div>
        <div class="circle2"></div>
      </div>
      <div class="triangle">
        <div class="square"></div>
        <div class="circle"></div>
        <div class="circle2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { BProgress } from "bootstrap-vue"

const minDeg = 1
const maxDeg = 90
const particlesClasses = [
  {
    class: "pop-top"
  },
  {
    class: "pop-top-left"
  },
  {
    class: "pop-top-right"
  },
  {
    class: "pop-bottom-right"
  },
  {
    class: "pop-bottom-left"
  }
];

let getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export default {
  components: {
    BProgress
  },
  computed: {
    isEnabled () {
      return this.campaign.reactions_enabled && this.campaign.is_voting_period_active
    },
    userReaction () {
      if (!this.userInteraction) {
        return null
      }
      let rtype = this.userInteraction.reaction_type
      if (rtype) {
        return this.getReaction(rtype)
      }
      return null
    },
    reactions () {
      return this.campaign.reactions
    },
    alreadyVoted () {
      if (this.userInteraction) {
        return this.userInteraction.total_likes > 0
      }
      return null
    },
    alreadyVotedBtnClass () {
      if (this.cancelReactionAttemptInProgress) {
        return "btn-danger btn-bg-animated"
      } else {
        return "btn-success"
      }
    },
    btnClass () {
      if (this.showAnimation) {
        return "scale"
      }
      return ""
    }
  },
  data () {
    return {
      voteLoading: false,
      showAnimation: false,
      animationIcon: '--1',
      tmrAnimation: null,
      cancelReactionAttemptInProgress: false,
      cancelReactionCounter: 0,
      cancelReactionTimer: null
    }
  },
  watch: {

  },
  methods: {
    ...mapActions(["registerParticipationReaction"]),

    getReaction (type) {
      return this.campaign.reactions.filter((item) => { return item.code === type })[0]
    },
    cancelReactionStart () {
      this.cancelReactionAttemptInProgress = true
      this.cancelReactionCounter = 5
      this.cancelReactionTimer = setInterval(this.cancelReactionTick.bind(this), 200)
    },
    cancelReactionStop () {
      clearInterval(this.cancelReactionTimer)
      this.cancelReactionAttemptInProgress = false
    },
    cancelReactionTick () {
      this.cancelReactionCounter += 7
      if (this.cancelReactionCounter >= 110) {
        clearInterval(this.cancelReactionTimer)
        this.cancelReactionStop()
        this.performReaction(this.userReaction.code)
      }
    },
    performReaction (type) {
      let payload = {
        id: this.item.id,
        reaction_type: type,
        gtm: this.$gtm
      }
      this.registerParticipationReaction(payload)
      this.performAnimation(this.getReaction(type).icon)
    },
    performAnimation (icon) {
      this.animationIcon = icon
      this.showAnimation = true
      this.$refs['rvoteanim'].classList.add('animate')
      this.tmrAnimation = setTimeout(this.startAnimation.bind(this), 100)
      if (icon !== 'x') {
        this.animateParticles(this.$refs.particles, 700)
      }
    },
    startAnimation () {
      this.$refs['rvoteanim'].classList.remove('animate')
      this.tmrAnimation = setTimeout(this.endAnimation.bind(this), 600)
    },
    endAnimation () {
      this.$refs['rvoteanim'].classList.add('animate')
      this.showAnimation = false
    },
    animateParticles(particles, dur) {
      this.addRandomParticlesRotation(particles, minDeg, maxDeg);
      for (let i = 0; i < particlesClasses.length; i++) {
        this.runParticleAnimationCycle(particles.children[i], particlesClasses[i].class, dur);
      }
      // Boolean functionality only to activate particles2, particles3 when needed
      particles.classList.add('animating')
      setTimeout(() => {
        particles.classList.remove('animating')
      }, dur);
    },
    addRandomParticlesRotation(particles, minDeg, maxDeg) {
      const randomRotationAngle = getRandomInt(minDeg, maxDeg) + 'deg'
      particles.style.transform = `rotate(${randomRotationAngle})`
    },
    runParticleAnimationCycle(el, className, duration) {
      if (el && !el.classList.contains(className)) {
        el.classList.add(className)
        setTimeout(() => { el.classList.remove(className) }, duration)
      }
    }
  },
  props: {
    campaign: Object,
    item: Object,
    userInteraction: Object
  }
};
</script>
