<template>
  <div class="container container-register">
    <transition name="slide-fade" mode="out-in">
      <div class="row justify-content-center">
        <div class="col-md-6 text-center">
          <h2 class="title color-custom-primary mb-2 animation-appear">
            {{ staticTexts.seeYouSoon }}
          </h2>
          <p class="font-corp animation-appear animation-delay-1">
            {{ staticTexts.sessionClosed }}
          </p>
          <p class="text-align-center animation-appear animation-delay-2">
            <router-link to="/" class="btn btn-primary">{{ staticTexts.continue }} &rarr;</router-link>
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* global gettext */
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
  },
  computed: {
    ...mapGetters(['getCampaignId']),
    staticTexts () {
      return {
        seeYouSoon: gettext("¡Hasta pronto!"),
        sessionClosed: gettext("Hemos cerrado tu sesión. Esperamos verte de vuelta pronto."),
        continue: gettext("Continuar")
      };
    }
  },
  methods: {
    ...mapActions(['doLogout', 'registerCampaignInteractions']),
    registerEvent (eventName) {
      let interactions = []
      interactions.push({ 'type': 'event', 'url': window.location.href, 'event': eventName })
      this.registerCampaignInteractions({ 'campaign': this.getCampaignId, interactions })
    }
  },
  data () {
    return {
    }
  },
  created () {
    let payload = {
      gtm: this.$gtm
    }
    this.doLogout(payload)
    this.registerEvent('logout')
  },
  mounted () {
  }
};

</script>

<style lang="scss" scoped>

</style>
