<template>
  <div>
    <h2>{{currentPage.title}}</h2>
    <div v-html="currentPage.content">
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'infopagedetail',
  data () {
    return {
      default: {
        title: "...",
        content: "......"
      }
    }
  },
  methods: {
    ...mapActions(['doGetInfoPages'])
  },
  computed: {
    ...mapGetters(['getInfoPages']),
    getPages () {
      return this.getInfoPages.pages
    },
    currentPage () {
      let slug = this.$route.params.slug
      let candidates = this.getPages.filter(p => p.slug === slug)
      if (candidates.length > 0) {
        return candidates[0]
      }
      return this.default
    }
  },
  mounted () {
    this.doGetInfoPages()
  },
  components: {
  }
}

</script>
