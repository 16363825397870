<template>
  <div class="mb-0">
    <label class="font-corp">{{ title }}</label>
    <a href="javascript:;" class="info-link" v-if="slotHasContent()" @click.prevent="toggleHelp">
      <span class="material-icons">
        {{ icon }}
      </span>
    </a>
    <transition name="collapse">
      <div class="alert alert-light alert-help" v-if="slotHasContent()" v-show="showHelp">
        <a href="javascript:;" @click.prevent="toggleHelp" class="btn btn-link btn-close"><i class="fa fa-times"></i></a>
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
/* global gettext */
export default {
  data () {
    return {
      showHelp: false
    }
  },
  props: {
    title: {
      type: String,
      default: gettext('Campo')
    },
    icon: {
      type: String,
      default: gettext('info')
    }
  },
  methods: {
    slotHasContent (name = 'default') {
      return !!this.$slots[ name ] || !!this.$scopedSlots[ name ]
    },
    toggleHelp () {
      this.showHelp = !this.showHelp
    }
  }
};

</script>

<style lang="scss">
.alert {
  strong {
    font-weight: 800;
  }
}
.info-link {
  // color: white;
  opacity: 0.7;
  margin-left: 0.5em;
  &:hover, &:active, &:focus {
    // color: white;
    opacity: 1;
  }
}
.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
}

</style>
