<template>
  <div class="bar-ranking">
    <strong v-if="showPosition" class="bar-ranking--number font-corp" :class="getRankingClass">#{{position}}</strong>
    <!-- <em class="bar-ranking--info font-corp">
      <i class="material-icons mr-2">
        {{getIcon}}
      </i>
      <span> {{getText}} </span>
    </em> |Esta info no aprta valor al visionado, y repetimos conceptos como el icono del ojo | -->
  </div>
</template>

<script>
export default {
  props: {
    position: {
      type: Number
    },
    config: {
      type: Object
    }
  },
  computed: {
    getRankingClass () {
      if (this.position === 1) {
        return "ranking--gold"
      } else if (this.position === 2) {
        return "ranking--silver"
      } else if (this.position === 3) {
        return "ranking--bronze"
      }
      return "ranking--others"
    },
    showPosition () {
      return this.config.selectedRanking !== 'most_recent'
    },
    getIcon () {
      if (this.config.selectedTab !== "participants") {
        return this.config.selectedTabIcon
      } else {
        return this.config.selectedRankingIcon
      }
    },
    getText () {
      if (this.config.selectedTab !== "participants") {
        return this.config.selectedTabText
      } else {
        return this.config.selectedRankingText
      }
    }
  }
};
</script>
