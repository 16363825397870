var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mode === 'view')?_c('li',{staticClass:"list-group-item",class:'item-mode-' + _vm.mode + ' manager-mode-' + _vm.managerMode},[_vm._m(0),_c('div',{staticClass:"list-group-item--text p-0"},[_c('div',{staticClass:"list-group-item--text---title"},[_c('h4',{staticClass:"title m-0"},[_vm._v(_vm._s(_vm.value.name)+" "+_vm._s(_vm.value.surname))]),_c('i',{staticClass:"material-icons",class:_vm.overall_status.class},[_vm._v(" "+_vm._s(_vm.overall_status.icon)+" ")])]),_c('div',{staticClass:"btn-group dropup"},[_c('button',{staticClass:"btn btn-card-options btn-just-icon btn-light btn-link btn-round",attrs:{"data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"},on:{"click":function($event){_vm.dropDown = !_vm.dropDown}}},[_c('i',{staticClass:"material-icons text_align-center"},[_vm._v("more_vert")])]),_c('div',{staticClass:"dropdown-menu dropdown-menu-right",class:{ show: _vm.dropDown },staticStyle:{"will-change":"transform"}},[_c('button',{staticClass:"btn btn-warning btn-link btn-block text-left",on:{"click":_vm.handleEdit}},[_vm._m(1),_vm._v(" "+_vm._s(_vm.staticTexts.edit)+" ")]),_c('button',{staticClass:"btn btn-danger btn-link btn-block text-left",on:{"click":_vm.handleDelete}},[_vm._m(2),_vm._v(" "+_vm._s(_vm.staticTexts.delete)+" ")])])])])]):_c('li',{staticClass:"list-group-item"},[_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return [_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-12"},[_c('h3',{staticClass:"title text-left text-primary mb-0 mt-0"},[_vm._v(" "+_vm._s(_vm.staticTexts.minorsData)+" ")])]),_c('div',{staticClass:"col-lg-5 col-md-6"},[_c('validation-provider',{attrs:{"name":_vm.staticTexts.minorName,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group md-field md-theme-default",class:classes},[_c('b-form-input',{staticClass:"font-corp md-input no-dark",attrs:{"placeholder":_vm.staticTexts.minorName},model:{value:(_vm.minor.name),callback:function ($$v) {_vm.$set(_vm.minor, "name", $$v)},expression:"minor.name"}}),(errors)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"col-lg-5 col-md-6"},[_c('validation-provider',{attrs:{"name":_vm.staticTexts.minorSurname,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group md-field md-theme-default",class:classes},[_c('b-form-input',{staticClass:"font-corp md-input no-dark",attrs:{"placeholder":_vm.staticTexts.minorSurname},model:{value:(_vm.minor.surname),callback:function ($$v) {_vm.$set(_vm.minor, "surname", $$v)},expression:"minor.surname"}}),(errors)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"col-lg-2 col-md-6"},[_c('validation-provider',{attrs:{"name":_vm.staticTexts.minorAge,"rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group md-field md-theme-default",class:classes},[_c('b-form-input',{staticClass:"font-corp md-input no-dark",attrs:{"placeholder":_vm.staticTexts.minorAge},model:{value:(_vm.minor.age),callback:function ($$v) {_vm.$set(_vm.minor, "age", $$v)},expression:"minor.age"}}),(errors)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1)]),_c('hr'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"title mb-0 text-left text-primary"},[_vm._v(_vm._s(_vm.staticTexts.firstTutorData))])]),_c('div',{staticClass:"col-lg-5 col-md-6"},[_c('validation-provider',{attrs:{"name":_vm.staticTexts.tutor1Name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group md-field md-theme-default",class:classes},[_c('b-form-input',{staticClass:"no-dark",attrs:{"placeholder":_vm.staticTexts.tutor1Name},model:{value:(_vm.minor.tutor1.name),callback:function ($$v) {_vm.$set(_vm.minor.tutor1, "name", $$v)},expression:"minor.tutor1.name"}}),(errors)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"col-lg-7 col-md-6"},[_c('validation-provider',{attrs:{"name":_vm.staticTexts.tutor1Surname,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group md-field md-theme-default",class:classes},[_c('b-form-input',{staticClass:"no-dark",attrs:{"placeholder":_vm.staticTexts.tutor1Surname},model:{value:(_vm.minor.tutor1.surname),callback:function ($$v) {_vm.$set(_vm.minor.tutor1, "surname", $$v)},expression:"minor.tutor1.surname"}}),(errors)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"col-lg-5 col-md-6"},[_c('validation-provider',{attrs:{"name":_vm.staticTexts.tutor1ID,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group md-field md-theme-default",class:classes},[_c('b-form-input',{staticClass:"no-dark",attrs:{"placeholder":_vm.staticTexts.tutor1ID},model:{value:(_vm.minor.tutor1.id),callback:function ($$v) {_vm.$set(_vm.minor.tutor1, "id", $$v)},expression:"minor.tutor1.id"}}),(errors)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"col-lg-7 col-md-6"},[_c('validation-provider',{attrs:{"name":_vm.staticTexts.tutor1Email,"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group md-field md-theme-default",class:classes},[_c('b-form-input',{staticClass:"no-dark",attrs:{"placeholder":_vm.staticTexts.tutor1Email},model:{value:(_vm.minor.tutor1.email),callback:function ($$v) {_vm.$set(_vm.minor.tutor1, "email", $$v)},expression:"minor.tutor1.email"}}),(errors)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"col-lg-5 col-md-6"}),_c('div',{staticClass:"col-lg-7 col-md-6"},[_c('SignBBLL',{attrs:{"btnExtraClass":"btn-block","signedLabel":_vm.staticTexts.tutor1Signed,"legalText":_vm.legalText,"unsignedLabel":_vm.staticTexts.tutor1Unsigned},model:{value:(_vm.minor.tutor1.signature),callback:function ($$v) {_vm.$set(_vm.minor.tutor1, "signature", $$v)},expression:"minor.tutor1.signature"}})],1)]),_c('hr'),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-12"},[_c('h5',{staticClass:"title mb-0 text-left text-primary"},[_vm._v(" "+_vm._s(_vm.staticTexts.secondTutorData)+" ")])]),(!_vm.minor.tutor2_non_exists)?[_c('div',{staticClass:"col-lg-5 col-md-6"},[_c('validation-provider',{attrs:{"name":_vm.staticTexts.tutor2Name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group md-field md-theme-default",class:classes},[_c('b-form-input',{staticClass:"no-dark",attrs:{"placeholder":_vm.staticTexts.tutor2Name},model:{value:(_vm.minor.tutor2.name),callback:function ($$v) {_vm.$set(_vm.minor.tutor2, "name", $$v)},expression:"minor.tutor2.name"}}),(errors)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"col-lg-7 col-md-6"},[_c('validation-provider',{attrs:{"name":_vm.staticTexts.tutor2Surname,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group md-field md-theme-default",class:classes},[_c('b-form-input',{staticClass:"no-dark",attrs:{"placeholder":_vm.staticTexts.tutor2Surname},model:{value:(_vm.minor.tutor2.surname),callback:function ($$v) {_vm.$set(_vm.minor.tutor2, "surname", $$v)},expression:"minor.tutor2.surname"}}),(errors)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"col-lg-5 col-md-6"},[_c('validation-provider',{attrs:{"name":_vm.staticTexts.tutor2ID,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group md-field md-theme-default",class:classes},[_c('b-form-input',{staticClass:"no-dark",attrs:{"placeholder":_vm.staticTexts.tutor2ID},model:{value:(_vm.minor.tutor2.id),callback:function ($$v) {_vm.$set(_vm.minor.tutor2, "id", $$v)},expression:"minor.tutor2.id"}}),(errors)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('div',{staticClass:"col-lg-7 col-md-6"},[_c('validation-provider',{attrs:{"name":_vm.staticTexts.tutor2Email,"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"form-group md-field md-theme-default",class:classes},[_c('b-form-input',{staticClass:"no-dark",attrs:{"placeholder":_vm.staticTexts.tutor2Email},model:{value:(_vm.minor.tutor2.email),callback:function ($$v) {_vm.$set(_vm.minor.tutor2, "email", $$v)},expression:"minor.tutor2.email"}}),(errors)?_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1)]:_vm._e(),_c('div',{staticClass:"col-lg-5 col-md-6 text-left"},[_c('b-form-checkbox',{attrs:{"value":true,"unchecked-value":false},model:{value:(_vm.minor.tutor2_non_exists),callback:function ($$v) {_vm.$set(_vm.minor, "tutor2_non_exists", $$v)},expression:"minor.tutor2_non_exists"}},[_vm._v(" El menor no dispone de segundo tutor legal ")])],1),_c('div',{staticClass:"col-lg-7 col-md-6"},[(!_vm.minor.tutor2_non_exists)?_c('SignBBLL',{attrs:{"btnExtraClass":"btn-block","signedLabel":_vm.staticTexts.tutor2Signed,"legalText":_vm.legalText,"unsignedLabel":_vm.staticTexts.tutor2Unsigned},model:{value:(_vm.minor.tutor2.signature),callback:function ($$v) {_vm.$set(_vm.minor.tutor2, "signature", $$v)},expression:"minor.tutor2.signature"}}):_vm._e()],1)],2),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-12 text-right mt-3 col-btn--responsive"},[_c('button',{staticClass:"btn btn-lg btn-light-blue float-left",on:{"click":_vm.handleCancel}},[_c('span',{staticClass:"material-icons"},[_vm._v("arrow_back")]),_vm._v(" "+_vm._s(_vm.staticTexts.cancel)+" ")]),(_vm.isEditing)?_c('button',{staticClass:"btn btn-lg btn-light-red text-left",on:{"click":_vm.handleDelete}},[_c('span',{staticClass:"material-icons"},[_vm._v("delete")]),_vm._v(" "+_vm._s(_vm.staticTexts.deleteMinor)+" ")]):_vm._e(),_c('button',{staticClass:"btn btn-lg btn-success",on:{"click":function($event){return _vm.handleClose(validate)}}},[_c('span',{staticClass:"material-icons"},[_vm._v("done")]),_vm._v(" "+_vm._s(_vm.staticTexts.confirm)+" ")])])])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-group-item--ico"},[_c('i',{staticClass:"material-icons"},[_vm._v(" child_care ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"material-icons"},[_vm._v("edit")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"btn-label"},[_c('i',{staticClass:"material-icons"},[_vm._v("delete_outline")])])}]

export { render, staticRenderFns }