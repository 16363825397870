<template>
  <div>
    <div class="d-md-none mobile-info-video">
      <!-- Info super puesta al video -->
      <div class="mobile-info-video--extra">
        <div class="d-flex align-items-center">
          <i v-if="isFromSocialNetwork" class="bar-social--item fab fa-fw" :class="itemIcon"></i>
          <ranking-position :position="item.position" :config="config"></ranking-position>
           <content-stats
              :item="item"
              :campaign="campaign"
              :showViews="campaign.show_most_viewed_ranking"
              :showShares="campaign.show_most_shared_ranking"
              :showVotes="campaign.show_most_voted_ranking"
              :showApplauses="campaign.show_most_applauded_ranking"
            ></content-stats>
        </div>
      </div>
      <!-- END -->
      <div class="row align-items-center justify-content-between">
        <div class="col-auto pr-0">
          <router-link
            to="/"
            tag="button"
            class="btn btn-light-green btn-round btn-fab ml-2 mb-2"
            title="Home"
          >
            <i class="material-icons">keyboard_arrow_left</i>
          </router-link>
        </div>
        <div class="col">
          <h1 class="mediainfo-title" @click.prevent="toggleMediaInfo()">{{item.title}}</h1>
        </div>
      </div>
      <collapse-transition>
        <div v-if="showMediainfo">
          <div class="row">
            <div class="col-auto">
              <div style="width:41px; display:block;"></div>
            </div>
            <div class="col">
              <div class="mediainfo-des">
                <p class="mb-1">{{item.description}}</p>
              </div>
              <h6 class="mediainfo-user mb-3">@{{item.author}}</h6>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-auto"></div>
          </div>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>

<script>
import ContentStats from "@/views/content/ContentStats.vue";
import { CollapseTransition } from "vue2-transitions";
import RankingPosition from "@/components/Hitsbook/RankingPosition.vue";

export default {
  components: {
    ContentStats,
    CollapseTransition,
    RankingPosition
  },
  data() {
    return {
      showMediainfo: false
    };
  },
  computed: {
    isFromSocialNetwork() {
      return (
        this.item.clip_type === "twitter" || this.item.clip_type === "instagram"
      );
    },
    itemIcon() {
      return "fa-" + this.item.clip_type;
    }
  },
  methods: {
    toggleMediaInfo() {
      this.showMediainfo = !this.showMediainfo;
    }
  },
  props: {
    item: Object,
    campaign: Object,
    config: Object,
    showPosition: {
      type: Boolean,
      default: false
    }
  }
};
</script>
