<template>
  <article>
    <!-- Ranking -->
    <div class="d-none d-md-block">
      <div class="row justify-content-between align-items-center">
        <div class="col-auto d-flex align-items-center">
          <i v-if="isFromSocialNetwork" class="bar-social--item fab fa-fw" :class="itemIcon"></i>
          <ranking-position :position="item.position" :config="config"></ranking-position>
          <content-stats
            :item="item"
            :campaign="campaign"
            :showViews="campaign.show_most_viewed_ranking"
            :showShares="campaign.show_most_shared_ranking"
            :showVotes="campaign.show_most_voted_ranking"
            :showApplauses="campaign.show_most_applauded_ranking"
          ></content-stats>
        </div>
        <div class="col-auto">
          <router-link to="/" class="btn btn-link btn-dark">
            <span class="material-icons">arrow_back</span>
            {{ staticTexts.return }}
          </router-link>
        </div>
      </div>
    </div>
    <!-- END Ranking -->
    <div class="d-none d-md-block">
      <h1 class="mediainfo-title overflow-hidden">{{ item.title }}</h1>
      <h6 class="mediainfo-user">@{{ item.author }}</h6>
      <div class="mediainfo-des">
        <p>{{ item.description }}</p>
      </div>
    </div>
    <div class="mediainfo-buttons row">
      <div class="mediainfo-button--actions col">
        <!-- Interacción - Voto -->
        <interaction-vote :campaign="campaign" :item="item" :userInteraction="userInteraction"></interaction-vote>
        <!-- END / Interacción - Voto -->
        <interaction-applauses :campaign="campaign" :item="item" :userInteraction="userInteraction"></interaction-applauses>
        <interaction-reactions :campaign="campaign" :item="item" :userInteraction="userInteraction"></interaction-reactions>
        <!-- Interacción - Aplauso --

        <button class="btn btn-custom-primary">
          <img src="{% static "img/campaign-demo/ico-slap.svg" %}"> ¡Aplausos!
        </button>
        !-- END / Interacción - Aplauso
        <button class="btn btn-light btn-round btn-fab btn-active" title="Opción 1" data-toggle="tooltip" data-placement="top" title="Opción">
          <img src="{% static "img/campaign-demo/option-01.png" %}">
        </button>
        <button class="btn btn-light btn-round btn-fab" title="Opción 2" data-toggle="tooltip" data-placement="top" title="Opción">
          <img src="{% static "img/campaign-demo/option-02.png" %}">
        </button>
        <button class="btn btn-light btn-round btn-fab" title="Opción 3" data-toggle="tooltip" data-placement="top" title="Opción">
          <img src="{% static "img/campaign-demo/option-03.png" %}">
        </button>
        -- END / Interacciones -->
      </div>
      <!-- div class="mediainfo-button--participe col-auto d-none d-md-block">
        <button class="btn btn-success">
          <i class="material-icons">video_call</i> ¡Participa!
        </button>
      </div -->
    </div>
  </article>
</template>

<script>
/* global gettext */
import ContentStats from "@/views/content/ContentStats.vue";
import InteractionVote from "@/views/content/InteractionVote.vue";
import InteractionApplauses from "@/views/content/InteractionApplauses.vue";
import InteractionReactions from "@/views/content/InteractionReactions.vue";

import RankingPosition from "@/components/Hitsbook/RankingPosition.vue";

export default {
  components: {
    ContentStats,
    InteractionVote,
    InteractionReactions,
    InteractionApplauses,
    RankingPosition
  },
  props: {
    item: Object,
    campaign: Object,
    config: Object,
    userInteraction: Object,
    showPosition: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isFromSocialNetwork() {
      return (
        this.item.clip_type === "twitter" || this.item.clip_type === "instagram"
      );
    },
    itemIcon() {
      return "fa-" + this.item.clip_type;
    },
    staticTexts () {
      return {
        return: gettext("Volver")
      };
    }
  }
};
</script>
