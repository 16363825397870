<template>
  <div class="row bar-grid justify-content-between mb-3">
    <div class="col-auto bar-grid--title">
      <h5 class="bar-title font-corp">
        {{ staticTexts.doYouWantToParticipate }}
        <router-link class="color-custom-primary" :to="{name: 'info'}">{{ staticTexts.discoverHow }}</router-link>
      </h5>
    </div>
    <div class="col-auto bar-grid--buttons d-flex text-right">
      <LoaderIndicator theme="getCampaign.theme_style" v-if="isLoading" class="loader" :size="40" />

      <div class="d-none d-md-block w-100">
        <div class="bar-display-row">
          <dropdown-filter
            v-if="dataLoaded && getCampaign.available_tabs.length > 1"
            :initialSelected="getHomeConfig.selectedTab"
            extraClass="mr-2"
            :options="getCampaign.available_tabs"
            buttonClasses="btn-custom-secondary btn dropdown-toggle"
            :dropRight="true"
            @changed="changedTab"
          ></dropdown-filter>
          <dropdown-filter
            v-if="dataLoaded && getCampaign.available_categories.length > 0"
            :initialSelected="getHomeConfig.selectedCategory"
            extraClass="mr-2"
            :options="getCampaign.available_categories"
            :showClearFilter="true"
            buttonClasses="btn-custom-secondary btn dropdown-toggle"
            :dropRight="true"
            @changed="changedCategory"
          ></dropdown-filter>
          <dropdown-filter
            v-if="dataLoaded"
            :initialSelected="getHomeConfig.selectedRanking"
            :options="getCampaign.available_rankings"
            buttonClasses="btn btn-custom-primary btn-link"
            @changed="changedRanking"
            :dropRight="true"
          ></dropdown-filter>
        </div>
      </div>
      <div class="d-block d-md-none w-100 text-right">
        <button
          @click.prevent="toggleFilters()"
          class="btn btn-just-icon btn-round btn-custom-primary"
        >
          <i class="material-icons text_align-center">more_vert</i>
        </button>
      </div>
    </div>
    <div class="col-12 bar-grid--buttons---mobile d-block d-md-none">
      <collapse-transition>
        <div v-if="showFilters" class="bar-display-wrap">
          <dropdown-filter
            v-if="dataLoaded && getCampaign.available_tabs.length > 1"
            :initialSelected="getHomeConfig.selectedTab"
            extraClass="mr-2"
            :options="getCampaign.available_tabs"
            buttonClasses="btn-custom-secondary btn dropdown-toggle"
            :dropRight="true"
            @changed="changedTab"
          ></dropdown-filter>
          <dropdown-filter
            v-if="dataLoaded && getCampaign.available_categories.length > 0"
            :initialSelected="getHomeConfig.selectedCategory"
            extraClass="mr-2"
            :options="getCampaign.available_categories"
            :showClearFilter="true"
            buttonClasses="btn-custom-secondary btn dropdown-toggle"
            :dropRight="true"
            @changed="changedCategory"
          ></dropdown-filter>
          <dropdown-filter
            v-if="dataLoaded"
            :initialSelected="getHomeConfig.selectedRanking"
            :options="getCampaign.available_rankings"
            buttonClasses="btn btn-card-options btn-light btn-link"
            @changed="changedRanking"
            :dropRight="true"
          ></dropdown-filter>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>

<script>
/* global gettext */
import DropdownFilter from "@/components/Hitsbook/DropdownFilter"
import { mapGetters, mapActions } from "vuex"
import { CollapseTransition } from "vue2-transitions"
import LoaderIndicator from '@/components/Hitsbook/LoaderIndicator'

export default {
  name: "FilterBar",
  components: {
    CollapseTransition,
    LoaderIndicator,
    DropdownFilter
  },
  computed: {
    ...mapGetters(["getHomeConfig", "getCampaign"]),
    staticTexts: function () {
      return {
        doYouWantToParticipate: gettext("¿Quieres participar?"),
        discoverHow: gettext("¡Descubre cómo!")
      };
    }
  },
  data() {
    return {
      isLoading: false,
      showFilters: false
    };
  },
  methods: {
    ...mapActions(["setHomeConfig"]),
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    dataLoaded() {
      return !!this.getCampaign.available_tabs;
    },
    dispatchNewConfig(newConfig) {
      this.isLoading = true;
      this.$emit("loadingstarts");
      this.setHomeConfig(newConfig).then(() => {
        this.isLoading = false;
        this.$emit("loadingends");
      });
    },
    changedCategory(newvalue, newText) {
      let newConfig = { ...this.getHomeConfig };
      newConfig.selectedCategory = newvalue;
      newConfig.selectedCategoryText = newText;
      this.dispatchNewConfig(newConfig);
    },
    changedRanking(newvalue, newText) {
      let newConfig = { ...this.getHomeConfig };
      newConfig.selectedRanking = newvalue;
      newConfig.selectedRankingText = newText;
      this.dispatchNewConfig(newConfig);
    },
    changedTab(newvalue, newText) {
      let newConfig = { ...this.getHomeConfig };
      newConfig.selectedTab = newvalue;
      newConfig.selectedTabText = newText;
      this.dispatchNewConfig(newConfig);
    }
  },
  mounted() {
    if (this.getHomeConfig.selectedRanking === "most_recent" || this.getHomeConfig.selectedRanking === null) {
      if (this.getCampaign.available_rankings.length > 0) {
        let defaultRanking = this.getCampaign.available_rankings[0]
        let newConfig = { ...this.getHomeConfig };
        newConfig.selectedRanking = defaultRanking[0];
        newConfig.selectedRankingText = defaultRanking[1];
        this.dispatchNewConfig(newConfig);
      }
    }
  }
};
</script>
<style lang="scss">
.loader {
  margin: 0.3125rem;
}
</style>
