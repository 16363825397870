<template>
  <img :src="imageUrl" :alt="staticTexts.loading" :width="size" :height="size" />
</template>

<script>
/* global gettext */
export default {
  props: {
    theme: {
      type: String,
      default: 'light'
    },
    inversed: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 40
    }
  },
  computed: {
    imageUrl () {
      let compareWith = 'light'
      if (this.inversed) {
        compareWith = 'dark'
      }
      if (this.theme === compareWith) {
        return '/static/img/loading.svg'
      } else {
        return '/static/img/loading-white.svg'
      }
    },
    staticTexts: function() {
      return {
        loading: gettext("Loading...")
      };
    }
  },
  data () {
    return {
    }
  }
};
</script>
