<template>
  <div
    :class="[
      { open: isOpen },
      { dropdown: direction === 'down' },
      { dropup: direction === 'up' },
      extraClass
    ]"
    @click="toggleDropDown"
    v-click-outside="closeDropDown"
  >
    <span class="btn-group">
      <button
        :class="buttonClasses"
        data-toggle="dropdown"
        href="javascript:void(0)"
        type="button" aria-haspopup="true" aria-expanded="false"
      >
        <template v-if="get_selected && updateButton">
          <i v-if="getIconKind(get_selected[2]) === 'material'" class="material-icons">{{get_selected[2]}}</i>
          <i v-if="getIconKind(get_selected[2]) === 'emoji'" :class="get_selected[2]"></i>
          {{get_selected[1]}}
        </template>
        <template v-if="!updateButton">
          <i class="material-icons text_align-center">more_vert</i>
        </template>
      </button>
      <template v-if="showClearFilter && !isEmpty">
        <a class="btn btn-custom-secondary btn-clear-filter" href="javascript:void(0)" @click.prevent="select(emptySelected)">
          <i class="material-icons">clear</i>
        </a>
      </template>
    </span>
    <div class="dropdown-menu" :class="get_right_class">
      <a class="dropdown-item" href="javascript:void(0)" v-for="option in options" :key="option[0]" @click.prevent="select(option)">
        <i v-if="getIconKind(option[2]) === 'material'" class="material-icons mr-2">{{option[2]}}</i>
        <i v-if="getIconKind(option[2]) === 'emoji'" class="mr-2" :class="option[2]"></i>
        {{option[1]}}
      </a>
    </div>
  </div>
</template>
<script>
/* global gettext */
export default {
  name: "dropdown-filter",
  props: {
    dropRight: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: "down"
    },
    updateButton: {
      type: Boolean,
      default: true
    },
    buttonClasses: {
      type: String,
      default: "btn btn-light dropdown-toggle"
    },
    showClearFilter: {
      type: Boolean,
      default: false
    },
    initialSelected: [String, Number],
    options: Array,
    extraClass: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      emptySelected: ['', gettext('Categorías'), ''],
      selected: ['', gettext('Categorías'), ''],
      isOpen: false
    }
  },
  computed: {
    isEmpty () {
      return this.selected[0] === this.emptySelected[0]
    },
    get_right_class () {
      if (this.dropRight) {
        return "dropdown-menu-right"
      } else {
        return ""
      }
    },
    get_selected () {
      if (Array.isArray(this.options)) {
        let filtered = this.options.filter(item => item[0] === this.initialSelected)
        if (filtered.length > 0) {
          return filtered[0]
        }
      }
      return this.emptySelected
    }
  },
  mounted () {
    // console.log("FUN:")
    // console.log(this.options)
    // console.log(this.initialSelected)
    if (this.initialSelected && Array.isArray(this.options)) {
      this.selected = this.options.filter(item => item[0] === this.initialSelected)[0]
    }
  },
  methods: {
    select (option) {
      this.selected = option
      this.$emit('changed', this.selected[0], this.selected[1])
    },
    getIconKind (iconClass) {
      if (iconClass && iconClass.includes('em-')) {
        return "emoji"
      } else {
        return "material"
      }
    },
    toggleDropDown () {
      this.isOpen = !this.isOpen;
    },
    closeDropDown () {
      this.isOpen = false;
    }
  }
};
</script>
