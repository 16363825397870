<template>
  <div class="container container-register">
    <transition name="slide-fade" mode="out-in">
      <div class="row align-items-center justify-content-around" v-if="step === 'login'" key="stepLogin">
        <div class="col-lg-4 col-md-5">
          <ValidationObserver v-slot="{ validate }">
            <form @submit.prevent="onSubmit(validate)">
              <!-- FORM HEADER-->
              <div class="form-header mb-2 form-header-contentup">
                <h3 class="title font-corp color-custom-primary mb-2 animation-appear">
                  {{ staticTexts.participateIn }}
                </h3>
                <h5 class="font-corp animation-appear animation-delay-1">
                  {{ staticTexts.initSessionToParticipate }}
                </h5>
                <p class="animation-appear animation-delay-2">
                  {{ staticTexts.notHaveAnAccount }}
                  <router-link class="color-custom-primary" :to="{'name': registerTarget}">{{ staticTexts.registerNow }}</router-link>
                </p>
              </div>
              <!-- END / FORM HEADER-->
              <!-- FORM BODY-->
              <div class="form-body animation-appear animation-delay-3" v-if="isEmailEnabled">
                <validation-provider :name="staticTexts.email" ref="emailProvider" rules="required|email" v-slot="{ classes, errors }">
                  <div class="form-group md-field md-theme-default" :class="classes">
                      <label class="font-corp"> {{ staticTexts.email }} *</label>
                      <b-form-input v-model="form.email" :placeholder="staticTexts.yourEmail"></b-form-input>
                      <span class="error">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
                <validation-provider :name="staticTexts.password" rules="required" v-slot="{ classes, errors }">
                  <div class="form-group md-field md-theme-default" :class="classes">
                    <label for="id_password">{{ staticTexts.password }}</label>
                    <div class="input-group">
                      <b-form-input v-model="form.password" id="id_password" name="password" :type="passwordInputType" class="font-corp"></b-form-input>
                      <span class="input-group-btn">
                        <button class="btn btn-custom-primary btn-link" @click.prevent="togglePasswordInputType()">{{passwordInputTypeTogglerText}}</button>
                      </span>
                    </div>
                    <span class="error">{{ errors[0] }}</span>
                    <a href="javascript:;" class="link font-corp d-block pt-2 pb-2 color-custom-primary" @click.prevent="step='passwordForget'"><small>{{ staticTexts.forgotYourPassword }}</small></a>
                  </div>
                </validation-provider>
              </div>
              <!-- END / FORM BODY-->
              <!-- FORM BOTTOM-->
              <div class="form-bottom animation-appear animation-delay-4" v-if="isEmailEnabled">
                <div class="form-row align-items-center">
                  <div class="col-12">
                    <button type="button" @click.prevent="onSubmit(validate)" :disabled="loginInProgress" class="btn btn-custom-primary">
                      <span v-if="!loginInProgress">
                        <span class="material-icons">
                        check_circle_outline</span>
                        {{ staticTexts.initSession }}
                      </span>
                      <span v-if="loginInProgress">
                        <LoaderIndicator theme="light" :size="40" />
                        {{ staticTexts.validatingCredentials }}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- END / FORM BOTTOM-->
              <!-- FORM BOTTOM-->
              <div class="form-bottom mt-3 animation-appear animation-delay-5">
                <h6 class="font-corp title-line" v-if="isEmailEnabled && (isFacebookEnabled || isGoogleEnabled)">
                  <span>{{ staticTexts.or }}</span>
                </h6>
                <div class="row m-0" v-if="isFacebookEnabled || isGoogleEnabled">
                  <button type="button" @click.prevent="fbLogin" class="btn btn-social-login btn-facebook btn-link col" v-if="isFacebookEnabled">
                    <img src="/static/img/facebook.svg" />
                    <span>{{ staticTexts.facebook }}</span>
                  </button>
                  <button type="button" @click.prevent="goLogin" class="btn btn-social-login btn-facebook btn-link col" v-if="isGoogleEnabled">
                    <img src="/static/img/google.svg" />
                    <span>{{ staticTexts.google }}</span>
                  </button>
                </div>
              </div>
              <!-- END / FORM BOTTOM-->
            </form>
          </ValidationObserver>
        </div>
        <div class="col-md-6 animation-appear-right animation-delay-3">
          <figure>
            <img class="img-fluid" :src="getLoginImage" />
          </figure>
        </div>
      </div>
      <div class="row" v-if="step === 'passwordForget'" key="stepPasswordForget">
        <div class="col-md-6">
          <ValidationObserver v-slot="{ validate }">
            <form @submit.prevent="onSendCode(validate)">
              <!-- FORM HEADER-->
              <div class="form-header form-header-contentup mb-2">
                <h3 class="title font-corp color-custom-primary mb-2">
                 {{ staticTexts.forgotYourPassword }}
                </h3>
                <h5 class="font-corp">
                  {{ staticTexts.confirmYourEmailToReceiveAnother }}
                </h5>
                <p>
                  {{ staticTexts.doYouRemember }}
                  <a href="javascript:;" class="color-custom-primary" @click.prevent="step='login'">{{ staticTexts.initSession }}</a>
                </p>
              </div>
              <!-- END / FORM HEADER-->
              <!-- FORM BODY-->
              <div class="form-body">
                <validation-provider :name="staticTexts.email" rules="required|email" v-slot="{ classes, errors }">
                  <div class="form-group md-field md-theme-default" :class="classes">
                      <label class="font-corp"> {{ staticTexts.email }} *</label>
                      <b-form-input v-model="form.email" :placeholder="staticTexts.yourEmail"></b-form-input>
                      <span class="error">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </div>
              <!-- END / FORM BODY-->
              <!-- FORM BOTTOM-->
              <div class="form-bottom">
                <div class="form-row mt-3 align-items-center justify-content-end">
                  <div class="col-md-7">
                    <button type="button" @click.prevent="onSendCode(validate)" :disabled="sendCodeInProgress" class="btn btn-custom-primary btn-lg btn-block">
                      <span v-if="!sendCodeInProgress">
                        <span class="material-icons">
                        check_circle_outline</span>
                        {{ staticTexts.sendCode }}
                      </span>
                      <span v-if="sendCodeInProgress">
                        <LoaderIndicator theme="light" :size="40" />
                        {{ staticTexts.sendingCode }}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- END / FORM BOTTOM-->
            </form>
          </ValidationObserver>
        </div>
        <div class="col-md-6">
          <figure>
            <img class="img-fluid" :src="getLoginImage" />
          </figure>
        </div>
      </div>
      <div class="row" v-if="step === 'confirmCode'" key="stepConfirmCode">
        <div class="col-md-6">
          <form>
            <!-- FORM HEADER-->
            <div class="form-header form-header-contentup mb-2 animation-appear">
              <h3 class="title font-corp color-custom-primary mb-2">
                {{ staticTexts.validateEmail }}
              </h3>
              <h5 class="font-corp mb-1">
                {{ staticTexts.enterTheCodeReceived }} <strong class="current-email" @click="wrongEmail=!wrongEmail">{{form.email}}</strong>.<br />
              </h5>
              <transition name="slide-down">
                <p class="wrong-email" v-if="wrongEmail">
                  {{ staticTexts.madeAMistake }}
                  <button class="btn btn-custom-primary btn-link btn-xs" @click.prevent="step='passwordForget'"><span class="material-icons">edit</span> {{ staticTexts.fixNow }}</button>
                </p>
              </transition>
              <p>
                {{ staticTexts.codeNotReceived }}
                <button @click.prevent="reSendCode" v-if="!resendInProgress && !resent" class="btn btn-link btn-custom-primary color-custom-primary">
                  {{ staticTexts.sendAgain }}
                </button>
                <span v-if="resendInProgress" class="btn btn-link btn-custom-primary color-custom-primary">
                  <LoaderIndicator :theme="getCampaign.theme_style" :size="40" /> {{ staticTexts.sending }}
                </span>
                <span v-show="resent" class="btn btn-link disabled btn-custom-primary color-custom-primary" :class="{'fade-out': resent}">
                  <i class="material-icons">check</i> {{ staticTexts.sent }}
                </span>
              </p>
            </div>
            <!-- END / FORM HEADER-->
            <!-- FORM BODY-->
            <div class="form-body animation-appear animation-delay-1" v-if="retries_left > 0">
              <ValidationObserver v-slot="{ invalid }">
                <validation-provider ref="codeProvider" :name="staticTexts.code" rules="required|digits:6" v-slot="{ classes, errors }">
                  <div class="form-group md-field md-theme-default" :class="classes">
                    <div class="input-group">
                      <b-form-input v-model="form.code"></b-form-input>
                      <div class="input-group-btn">
                        <button class="btn btn-lg" :class="{'btn-success': !validatingCodeInProgress, 'btn-link': validatingCodeInProgress}" :disabled="validatingCodeInProgress || invalid" @click.prevent="validateCode">
                          <span v-if="!validatingCodeInProgress">
                            <i class="material-icons">
                              check
                            </i>
                            {{ staticTexts.validateCode }}
                          </span>
                          <span v-if="validatingCodeInProgress">
                            <LoaderIndicator :theme="getCampaign.theme_style" :inversed="false" :size="40" />
                            {{ staticTexts.validating }}
                          </span>
                        </button>
                      </div>
                    </div>
                    <span class="error" v-if="errors">{{ errors[0] }}</span>
                  </div>
                </validation-provider>
              </ValidationObserver>
            </div>
            <div class="form-body animation-appear" v-if="retries_left===0">
              <div class="alert alert-danger">
                {{ staticTexts.tooManyRetries }}
              </div>
            </div>
            <!-- END / FORM BODY-->
          </form>
        </div>
        <div class="col-md-6">
          <figure class="animation-appear-right animation-delay-2">
            <img class="img-fluid" :src="getPinImage" />
          </figure>
        </div>
      </div>
      <div class="row align-items-center justify-content-around" v-if="step === 'passwordRecovered'" key="step3">
        <div class="col-md-6 text-center">
          <h2 class="title color-custom-primary mb-2 animation-appear">
            {{ staticTexts.done }}
          </h2>
          <p class="font-corp animation-appear animation-delay-1">
            {{ staticTexts.sentEmailWithNewCredentials }}
          </p>
          <p class="text-align-center animation-appear animation-delay-2">
            <router-link :to="getNextUrl" class="btn btn-primary">{{ staticTexts.continue }} &rarr;</router-link>
          </p>
        </div>
      </div>
    </transition>
    <b-modal ref="modal-loading" hide-footer hide-header-close no-close-on-backdrop :title="staticTexts.waitFewSeconds">
      <div class="text-center p-3 m-3">
        <b-spinner variant="primary" type="grow" :label="staticTexts.spinning"></b-spinner>
        <p class="lead mt-3 text-dark">{{ messageLoading }}</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* global gettext, interpolate */
/* global FB_LOGIN_URL */
/* global GO_LOGIN_URL */

import { mapGetters, mapActions } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import { BFormInput, BModal, BSpinner } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LoaderIndicator from '@/components/Hitsbook/LoaderIndicator'

export default {
  components: {
    LoaderIndicator,
    BFormInput,
    BModal,
    BSpinner,
    ValidationProvider,
    ValidationObserver
  },
  computed: {
    ...mapGetters(['getCampaign', 'getCampaignId', 'getLoginRequired', 'getParticipationAttempt']),
    getNextUrl () {
      if (this.getParticipationAttempt) {
        return { 'name': 'participate' }
      }
      if (this.nextUrl) {
        return this.nextUrl
      } else {
        return "/"
      }
    },
    registerTarget () {
      if (this.getParticipationAttempt) {
        return 'participate'
      }
      return 'register-basic'
    },
    getPinImage () {
      if (this.getCampaign.registration_pin_image) {
        return this.getCampaign.registration_pin_image
      } else {
        return '/static/img/register/img-register-03.png'
      }
    },
    getLoginImage () {
      if (this.getCampaign.registration_login_image) {
        return this.getCampaign.registration_login_image
      } else {
        return '/static/img/register/img-register-02.png'
      }
    },
    isEmailEnabled () {
      return this.getCampaign.registration_sources.includes('email')
    },
    isFacebookEnabled () {
      return this.getCampaign.registration_sources.includes('facebook') && this.getCampaign.registration_sources_validated
    },
    isGoogleEnabled () {
      return this.getCampaign.registration_sources.includes('google') && this.getCampaign.registration_sources_validated
    },
    staticTexts () {
      return {
        participateIn: interpolate(gettext("Participa en %s"), [this.getCampaign.name]),
        initSessionToParticipate: interpolate(gettext("Inicia sesión en %s con tu cuenta para poder participar"), [this.getCampaign.name]),
        notHaveAnAccount: gettext("¿No tienes cuenta aún?"),
        registerNow: gettext("Regístrate ahora"),
        email: gettext("Email"),
        yourEmail: gettext("Tu email"),
        password: gettext("Contraseña"),
        forgotYourPassword: gettext("¿Olvidaste tu contraseña?"),
        initSession: gettext("Inicia Sesión"),
        validatingCredentials: gettext("Validando credenciales..."),
        or: gettext("ó"),
        facebook: gettext("Facebook"),
        google: gettext("Google"),
        confirmYourEmailToReceiveAnother: gettext("Confirma tu email y te enviaremos una nueva"),
        doYouRemember: gettext("¿Ya la recuerdas?"),
        sendCode: gettext("Enviar código"),
        sendingCode: gettext("Enviando código..."),
        validateEmail: gettext("Valida tu correo"),
        enterTheCodeReceived: gettext("Introduce el código que te hemos enviado por email a"),
        madeAMistake: gettext("¿Te has equivocado al escribirlo?"),
        codeNotReceived: gettext("¿No te ha llegado el código?"),
        fixNow: gettext("Corregir ahora"),
        sendAgain: gettext("Volver a enviar"),
        sending: gettext("Enviando..."),
        sent: gettext("Enviado"),
        validateCode: gettext("Validar código"),
        validating: gettext("Validando..."),
        tooManyRetries: gettext("Has agotado el número máximo de intentos. El PIN ha sido invalidado por motivos de seguridad. Pulsa en 'Volver a enviar' para reiniciar el proceso. Te enviaremos un nuevo PIN por email."),
        done: gettext("¡Hecho!"),
        sentEmailWithNewCredentials: gettext("Te hemos enviado un correo con tus nuevas credenciales. Y además hemos iniciado sesión para que puedas seguir participando directamente."),
        continue: gettext("Continuar"),
        waitFewSeconds: gettext("Espere unos segundos..."),
        spinning: gettext("Spinning"),
        code: gettext("Código")
      };
    }
  },
  methods: {
    ...mapActions(['mockAPICall', 'doLogin', 'performPendingAction', 'registerCampaignInteractions', 'doResetPassword', 'doResetPasswordValidation']),
    onSubmit (validate) {
      // console.log("trigger validation")
      validate().then((isValid) => {
        if (isValid) {
          // console.log("valid form")
          this.loginInProgress = true
          // API Call
          this.doLogin(this.form).then((result) => {
            // console.log(result)
            this.nextUrl = this.getLoginRequired.url
            // this.performPendingAction()
            this.$router.push(this.getNextUrl)
          }).catch(() => {
            // console.log(error)
            this.$refs.emailProvider.applyResult({
              errors: [gettext("Las credenciales no son válidas. Pruebe de nuevo.")],
              valid: false,
              failedRules: {}
            })
          })
          this.loginInProgress = false
        } else {
          // console.log("NOT valid form")
        }
      }).catch(() => {
        // console.log("An unexpected error occurred while validating form values")
      })
    },
    fbLogin () {
      this.messageLoading = gettext("Conectando con Facebook...")
      this.$refs['modal-loading'].show()

      let url = FB_LOGIN_URL + "?next=" + encodeURIComponent("/#/accounts/required-data")
      window.location = url
    },
    goLogin () {
      this.messageLoading = gettext("Conectando con Google...")
      this.$refs['modal-loading'].show()

      let url = GO_LOGIN_URL + "&next=" + encodeURIComponent("/#/accounts/required-data")
      window.location = url
    },
    onSendCode (validate) {
      // console.log("trigger validation")
      validate().then((isValid) => {
        if (isValid) {
          // console.log("valid form")
          this.sendCodeInProgress = true
          // API Call
          let payload = {
            email: this.form.email,
            token: this.form.token
          }

          this.doResetPassword(payload).then((response) => {
            this.retries_left = response.data.retries_left
            this.sendCodeInProgress = false
            this.step = 'confirmCode'
          })
        } else {
          // console.log("NOT valid form")
        }
      }).catch(() => {
        // console.log("An unexpected error occurred while validating form values")
      })
    },
    reSendCode () {
      if (!this.resendInProgress && !this.resent) {
        this.resendInProgress = true
        let payload = {
          email: this.form.email,
          token: this.form.token
        }
        this.doResetPassword(payload).then((response) => {
          this.retries_left = response.data.retries_left
          this.sendCodeInProgress = false
          this.reSendCodeCompleted()
        })
        this.registerEvent('password-recovery-resend-code')
      }
    },
    reSendCodeCompleted () {
      this.resendInProgress = false
      this.resent = true
      setTimeout(this.resentCleanup.bind(this), 5000)
    },
    resentCleanup () {
      this.resent = false
    },
    validateCode () {
      if (!this.resendInProgress && !this.resent) {
        this.validatingCodeInProgress = true
        let payload = {
          email: this.form.email,
          token: this.form.token,
          code: this.form.code,
          gtm: this.$gtm
        }
        this.doResetPasswordValidation(payload).then((response) => {
          this.registerEvent('password-recovery-valid-code')
          this.nextUrl = this.getLoginRequired.url
          this.step = 'passwordRecovered'
        }).catch((error) => {
          this.retries_left = error.response.data.retries_left
          this.$refs.codeProvider.applyResult({
            errors: [interpolate(gettext("El código no es correcto o ha expirado. Le quedan %s intentos."), [this.retries_left])],
            valid: false,
            failedRules: {}
          })
          this.registerEvent('password-recovery-invalid-code')
        }).finally(() => {
          this.validatingCodeInProgress = false
        })
      }
    },
    registerEvent (eventName) {
      let interactions = []
      interactions.push({ 'type': 'event', 'url': window.location.href, 'event': eventName })
      this.registerCampaignInteractions({ 'campaign': this.getCampaignId, interactions })
    },
    togglePasswordInputType () {
      if (this.passwordInputType === 'password') {
        this.passwordInputType = 'text'
        this.passwordInputTypeTogglerText = gettext('OCULTAR')
      } else {
        this.passwordInputType = 'password'
        this.passwordInputTypeTogglerText = gettext('MOSTRAR')
      }
    }
  },
  data () {
    return {
      loginInProgress: false,
      passwordInputType: 'password',
      passwordInputTypeTogglerText: gettext('MOSTRAR'),
      sendCodeInProgress: false,
      validatingCodeInProgress: false,
      messageLoading: gettext("Cargando"),
      step: 'login',
      wrongEmail: false,
      resendInProgress: false,
      resent: false,
      retries_left: 10,
      form: {
        email: null,
        password: null,
        code: null,
        token: null,
        gtm: this.$gtm
      }
    }
  },
  mounted () {
    if (this.form.token === null) {
      this.form.token = uuidv4()
    }
    this.registerEvent('login-loaded')
  }
};

</script>

<style lang="scss" scoped>
  .form-group {
    &.valid {
      .form-control {
        background-image: linear-gradient(to top, #463268 2px, rgba(70, 150, 10, 0) 2px),
                          linear-gradient(to top, green 1px, rgba(20, 210, 20, 0) 1px) !important;
      }
    }
    &.invalid {
      .form-control {
        background-image: linear-gradient(to top, #463268 2px, rgba(70, 150, 10, 0) 2px),
                          linear-gradient(to top, red 1px, rgba(20, 210, 20, 0) 1px) !important;
      }
    }
  }
  .custom-control {
    .error {
      color: red;
    }
  }
  .current-email {
    cursor: pointer;
  }

  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all .2s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(15px);
    opacity: 0;
  }

  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all .2s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(15px);
    opacity: 0;
  }

  .slide-down-enter-active, .slide-down-leave-active {
    transition: all .2s ease;
  }
  .slide-down-enter, .slide-down-leave-to {
    transform: translateY(-15px);
    opacity: 0;
  }

  .wrong-email {
    margin-top: -10px;
    .btn-xs {
      padding: 5px;
      text-transform: none;
    }
  }

  .fade-out {
    animation: fade-out 5s ease-out forwards;
  }

  @keyframes fade-out {
    0% {
      opacity: 0.2;
      transform: scale(0.9) translateX(0px);
    }
    5% {
      opacity: 1;
      transform: scale(1.1);
    }
    10% {
      opacity: 1;
      transform: scale(1);
    }
    90% {
      opacity: 1;
      transform: translateX(0px);
    }
    100% {
      opacity: 0;
      transform: translateX(15px);
    }
  }

  .animation-appear {
    animation: appear 0.2s ease-in forwards;
    opacity:0;
  }

  .animation-appear-right {
    animation: appear-right 0.2s ease-in forwards;
    opacity:0;
  }

  @for $i from 1 through 20 {
    .animation-delay-#{$i} {
      animation-delay: $i * 0.04s
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
      transform: translateY(15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px)
    }
  }

  @keyframes appear-right {
    0% {
      opacity: 0;
      transform: translateX(15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px)
    }
  }

</style>
