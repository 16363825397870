<template>
  <div class="bagde-group">
    <content-stat v-if="showViews" :item="item" iconClass="d-block material-icons" iconText="remove_red_eye" :value="item.stats.num_views"></content-stat>
    <content-stat v-if="showVotes" :item="item" iconClass="d-block material-icons" iconText="thumb_up" :value="item.stats.num_likes"></content-stat>

    <template v-if="showReactions">
      <content-stat v-for="reaction in item.stats.reactions" :key="reaction.reaction_type" :item="item" :iconClass="'em em-' + getReaction(reaction.reaction_type).icon" iconText="" :value="reaction.num"></content-stat>
    </template>

    <content-stat v-if="showShares" :item="item" iconClass="fas fa-share-alt" iconText="" :value="item.stats.num_shares"></content-stat>
    <content-stat v-if="showApplauses" :item="item" iconClass="fas fa-sign-language" iconText="" :value="item.stats.num_applauses"></content-stat>
  </div>
</template>

<script>
import ContentStat from '@/views/content/ContentStat.vue'

export default {
  components: {
    ContentStat
  },
  computed: {
    numViews () {
      if (this.item) {
        return this.item.num_views
      }
      return 0
    },
    itemID () {
      if (this.item) {
        return this.item.id
      }
      return 0
    }
  },
  data () {
    return {
      initial: false,
      valueChanged: false,
      tmr: null
    }
  },
  watch: {
    itemID (to, from) {
      this.initial = true
    },
    numViews (to, from) {
      // console.log('numViews changed')
      this.animateViews()
    }
  },
  methods: {
    animateViews () {
      // console.log('animateViews')
      if (this.initial) {
        this.initial = false
        return
      }
      this.valueChanged = true
      this.tmr = setTimeout(this.clearAnimateViews.bind(this), 800)
    },
    getReaction (type) {
      return this.campaign.reactions.filter((item) => { return item.code === type })[0]
    },
    clearAnimateViews () {
      // console.log('animateViews')
      this.valueChanged = false
      clearTimeout(this.tmr)
      this.tmr = null
    }
  },
  props: {
    item: Object,
    campaign: Object,
    showViews: {
      type: Boolean,
      default: true
    },
    showShares: {
      type: Boolean,
      default: true
    },
    showReactions: {
      type: Boolean,
      default: true
    },
    showVotes: {
      type: Boolean,
      default: true
    },
    showApplauses: {
      type: Boolean,
      default: true
    }
  }
};
</script>
