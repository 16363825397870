<template>
  <div>
    <template v-if="getCampaign.slider_style === 'aside'">
      <div class="container container-campaign">
        <div class="row">
          <div class="col-lg-3">
            <main-banner :mode="getCampaign.slider_style" v-if="getCampaign.pre_campaign_show_header"></main-banner>
          </div>
          <div class="col-lg-9" v-html="getCampaign.pre_campaign_page_custom_html_content">
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <main-banner :mode="getCampaign.slider_style" v-if="getCampaign.pre_campaign_show_header"></main-banner>
      <div class="container container-campaign" v-html="getCampaign.pre_campaign_page_custom_html_content">
      </div>
    </template>
  </div>
</template>

<script>
/* global CAMPAIGN_ID */

import { mapGetters, mapActions } from 'vuex'
import MainBanner from '@/views/home/MainBanner.vue'

export default {
  name: 'home',
  components: {
    MainBanner
  },
  computed: {
    ...mapGetters(['getCampaign', 'getCampaignId']),
    getStyle () {
      return this.getCampaign.theme_style
    }
  },
  methods: {
    ...mapActions(['registerCampaignInteractions', 'doUpdateCampaign'])
  },
  mounted () {
    this.doUpdateCampaign(CAMPAIGN_ID)
    let interactions = []
    interactions.push({ 'type': 'pageview', 'url': window.location.href })
    this.registerCampaignInteractions({ 'campaign': this.getCampaignId, interactions })
  },
  watch: {
    getStyle(newValue) {
      let documentClasses = document.body.classList;
      if (newValue === 'dark') {
        documentClasses.add("dark-mode");
      } else {
        documentClasses.remove("dark-mode");
      }
    }
  }
};

</script>
