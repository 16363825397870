<template>
  <span>

    <button :class="getButtonClass" @click.prevent="showModal">
      <span class="material-icons">
        {{signButtonIcon}}
      </span>
      {{signButtonLabel}}
    </button>
    <input type="hidden" name="signature" v-model="signValue" />

    <b-modal ref="modal-signbbll" @shown="modalShown" size="lg" hide-header-close body-bg-variant="docusign" scrollable no-close-on-backdrop :title="staticTexts.modalTitle">
      <div class="container-docusign">
        <div class="cms" v-html="legalText">
        </div>
        <div class="panel-firm">
          <small class="text-muted">{{staticTexts.signHere}}</small>
          <VueSignaturePad :width="signatureWidth" height="320px" ref="signaturePad" />
        </div>
        <div ref="signatureBottom" id="signatureBottom"></div>
      </div>
      <template slot="modal-footer">
        <b-button variant="default" @click="cancelSignature">
          {{staticTexts.cancel}}
        </b-button>
        <b-button variant="secondary" @click="clearSignature">
          {{staticTexts.clear}}
        </b-button>
        <b-button variant="success" @click="saveSignature">
          {{staticTexts.done}}
        </b-button>
      </template>
    </b-modal>

  </span>
</template>

<script>
/* global gettext */
import { BModal, BButton } from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BButton
  },
  computed: {
    getButtonClass () {
      let cls = "btn"
      cls = cls + " " + this.btnExtraClass
      if (this.isSigned) {
        cls = cls + " btn-success"
      } else {
        cls = cls + " btn-secondary"
      }
      return cls
    },
    signatureWidth () {
      if (this.vw < 340) {
        return "150px"
      }
      if (this.vw < 395) {
        return "190px"
      }
      if (this.vw < 990) {
        return "250px"
      }
      return "480px"
    },
    signValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      }
    },
    signButtonLabel() {
      if (this.isSigned) {
        return this.signedLabel
      }
      return this.unsignedLabel
    },
    signButtonIcon() {
      if (this.isSigned) {
        return "check_circle_outline"
      }
      return "gesture"
    },
    staticTexts () {
      return {
        signHere: gettext("Firma aquí con el dedo o el ratón"),
        cancel: gettext("Cancelar"),
        clear: gettext("Limpiar"),
        done: gettext("Hecho"),
        modalTitle: gettext("Firma las bases legales"),
        emptyError: gettext("Debe firmar con el dedo o el ratón en el cuadro")
      };
    }
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    signedLabel: {
      type: String,
      default: "Firmado"
    },
    unsignedLabel: {
      type: String,
      default: "Firmar ahora"
    },
    btnExtraClass: {
      type: String,
      default: ""
    },
    legalText: {
      type: String,
      default: "LEGAL TEXT"
    }
  },
  data() {
    return {
      isSigned: false,
      signature: null,
      data: null,
      vh: null,
      vw: null
    }
  },
  methods: {
    onResize() {
      this.vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
      this.vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      this.$refs['signaturePad'].resizeCanvas()
      // console.log('resize', this.vw)
    },
    dumpSignature () {
      this.data = this.$refs['signaturePad'].toData()
    },
    restoreSignature () {
      if (this.data) {
        this.$refs['signaturePad'].fromData(this.data)
      }
    },
    saveSignature () {
      const { isEmpty, data } = this.$refs['signaturePad'].saveSignature()
      if (isEmpty) {
        this.$swal.fire({
          title: 'Error',
          text: this.staticTexts.emptyError,
          icon: 'error',
          timer: 4000,
          timerProgressBar: true
        })
        this.$refs['signatureBottom'].scrollIntoView(true)
      } else {
        this.signature = data
        this.isSigned = true
        this.dumpSignature()
        this.$refs['modal-signbbll'].hide()
        this.$emit('input', { signature: this.signature, data: this.data })
      }
    },
    showModal() {
      this.$refs['modal-signbbll'].show()
    },
    modalShown() {
      this.initializeSignPad()
      this.restoreSignature()
      this.$refs['signatureBottom'].scrollIntoView()
    },
    initializeSignPad() {
      if (this.value) {
        this.signature = this.value.signature
        this.data = this.value.data
        this.isSigned = true
      } else {
        this.isSigned = false
      }
      this.$refs['signaturePad'].openSignaturePad()
      this.$refs['signaturePad'].resizeCanvas()
      if (!this.value) {
        this.$refs['signaturePad'].clearSignature()
      }
    },
    clearSignature() {
      this.isSigned = false
      this.signature = null
      this.data = null
      this.$emit('input', null)
      this.$refs['signaturePad'].openSignaturePad()
      this.$refs['signaturePad'].resizeCanvas()
      this.$refs['signaturePad'].clearSignature()
      // this.initializeSignPad()
    },
    cancelSignature() {
      this.initializeSignPad()
      this.$refs['modal-signbbll'].hide()
    }
  },
  mounted() {
    if (this.value) {
      this.signature = this.value.signature
      this.data = this.value.data
      this.isSigned = true
    } else {
      this.isSigned = false
    }
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize.bind(this))
      this.onResize()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize.bind(this))
  }

};

</script>
