<template>
  <div>

    <div class="container container-campaign">
      <div class="row justify-content-between">
        <!--aside media detail-->
        <aside class="media-detail" :class="getAsideClass">
          <!--Video detail-->
          <media-info-mobile v-if="!pendingData" :showPosition="showPosition" :item="getContentItem" :campaign="getCampaign" :config="getHomeConfig"></media-info-mobile>
          <div class="sticky-top" :class="getResponsiveClass">
            <div
              v-if="pendingData"
              class="embed-responsive-item">
              <content-loader
                :width="720"
                :height="720"
                :speed="2"
                primaryColor="var(--content-loader-primary)"
                secondaryColor="var(--content-loader-secondary)"
              >
                <rect x="0" y="0" rx="0" ry="0" width="720" height="720" />
              </content-loader>
            </div>
            <img v-if="!pendingData" class="img-fluid" v-show="isPhoto" :src="getContentItem.url" v-hammer:swipe.left="loadNext">
            <div class="" v-show="isVideo && !pendingData" id="player" ref="player" v-hammer:swipe.left="loadNext" >
              <video-player ref="videoPlayer"
                  class="vjs-custom-skin"
                  :options="getPlayerOptions"
                  @play="playEvent($event)"
                  @pause="pauseEvent($event)"
                  @ended="completeEvent($event)"></video-player>
            </div>
            <div class="embed-responsive-item" v-show="isVideoExternal && !pendingData" v-html="getIframe"></div>
            <i v-if="isPhoto" class="material-icons media-type">
               {{ iconShowed }}
            </i>

            <div class="autoplay-next" v-show="autoplay_visible">
              <span class="btn-group">
                <button class="btn btn-primary btn-lg p-0 text-center" @click="loadNext">
                  <span class="p-3 d-block lead">{{ staticTexts.next }} <strong>{{ autoplay_counter_display }}</strong></span>
                  <b-progress class="mb-0" height="8px" :value="autoplay_counter_reversed + 5" :max="autoplay_interval"></b-progress>
                </button>
                <button v-show="autoplayRunning" class="btn btn-light btn-lg p-3" @click="stopAutoplay"><i class="fa fa-pause"></i></button>
              </span>
            </div>
          </div>
          <!--END / Video detail-->
        </aside>
        <!--END / col-item-->
        <!-- END / aside media detail-->
        <!-- Section media info -->

        <section class="media-info mt-4" :class="getInfoClass">
          <!-- Section media info / Content-->
          <div class="row justify-content-between">
            <div class="content-info" :class="getVideoInfoClass">
              <!-- Video info-->
              <media-info v-if="!pendingData" :showPosition="showPosition" :item="getContentItem" :userInteraction="getUserInteraction" :campaign="getCampaign" :config="getHomeConfig"></media-info>
              <content-loader
                v-if="pendingData"
                :width="600"
                :height="200"
                viewBox="0 0 600 200"
                :speed="2"
                primaryColor="var(--content-loader-primary)"
                secondaryColor="var(--content-loader-secondary)"
              >
                <rect x="0" y="0" rx="0" ry="0" width="600" height="50" />
                <rect x="0" y="60" rx="0" ry="0" width="40" height="20" />
                <rect x="45" y="60" rx="0" ry="0" width="40" height="20" />
                <rect x="0" y="90" rx="0" ry="0" width="600" height="10" />
                <rect x="0" y="110" rx="0" ry="0" width="600" height="10" />
                <rect x="0" y="130" rx="0" ry="0" width="600" height="10" />
              </content-loader>
              <!-- END / Video info-->
            </div>
            <!-- PUBLICIDAD -->
            <banner :ad="getCampaign.advertising.mid_page" v-if="getCampaign && getCampaign.advertising && getCampaign.advertising.mid_page"></banner>
            <!-- END / PUBLICIDAD-->
          </div>

          <div v-if="!isHorizontal" class="col-12">
            <hr class="media-separator-md d-none d-md-block">
          </div>

          <!-- END Section media info / Content-->
          <content-list v-if="!isHorizontal" v-show="!pendingData" :isRelated="true" :items="getOtherVideos" :limit="4"></content-list>
        </section>
        <!--END / Section media info -->

        <div v-if="isHorizontal" class="col-12">
          <hr class="media-separator">
        </div>

        <!-- END Section media info / Content-->
        <div v-if="isHorizontal" v-show="!pendingData" class="col-12">
          <content-list :isRelated="true" :items="getOtherVideos" :limit="4"></content-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global gettext */

import { mapGetters, mapActions } from 'vuex'
import MediaInfoMobile from '@/views/content/MediaInfoMobile.vue'
import MediaInfo from '@/views/content/MediaInfo.vue'
import ContentList from '@/components/Hitsbook/ContentList.vue'
import { ContentLoader } from "vue-content-loader"
import { BProgress } from "bootstrap-vue"
import Banner from '@/components/Hitsbook/Banner.vue'
// import videojs from 'video.js'
// import 'video.js/dist/video-js.css'
import VideoPlayer from '@/components/VideoPlayer.vue'

export default {
  components: {
    MediaInfoMobile,
    MediaInfo,
    ContentList,
    ContentLoader,
    BProgress,
    Banner,
    VideoPlayer
  },
  metaInfo() {
    let extraTitle = this.getCampaign.name
    let extraDescription = ""
    let title = ""

    if (this.getCampaign.seo_enabled) {
      extraTitle = this.getCampaign.meta_title
      extraDescription = this.getCampaign.meta_description
    }

    let metas = []
    if (this.getContentItem) {
      metas = [
        { name: 'description', content: 'Participación de ' + this.getContentItem.author + ' en ' + extraTitle + ' con - ' + this.getContentItem.title + ' - ' + extraDescription },
        { name: 'og:description', content: 'Participación de ' + this.getContentItem.author + ' en ' + extraTitle + ' con - ' + this.getContentItem.title + ' - ' + extraDescription },
        { property: 'twitter:title', content: this.getContentItem.title + " - " + extraTitle },
        { name: 'twitter:description', content: 'Participación de ' + this.getContentItem.author + ' en ' + this.getCampaign.name + ' con - ' + this.getContentItem.title + ' - ' + extraDescription },
        { property: 'twitter:image', content: this.getContentItem.url },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'og:title', content: this.getContentItem.title + " - " + extraTitle },
        { property: 'og:site_name', content: this.getCampaign.name },
        { property: 'og:image', content: this.getContentItem.url },
        { property: 'og:url', content: window.location.href }
      ]

      if (this.isVideo) {
        metas.push({ property: 'og:video', content: this.getContentItem.mp4_url })
        metas.push({ property: 'og:type', content: 'video.other' })
      } else {
        metas.push({ property: 'og:type', content: 'image' })
      }
      if (this.getCampaign) {
        title = this.getContentItem.title + " - " + this.getCampaign.name
      }
    }
    if (this.getCampaign) {
      if (this.getCampaign.seo_enabled) {
        metas.push({ robots: this.getCampaign.meta_robots })
        metas.push({ keywords: this.getCampaign.meta_keywords })
      }
    }

    return {
      title: title,
      meta: metas
    }
  },
  computed: {
    ...mapGetters(['getHomeParticipations', 'getCampaign', 'getHomeConfig', 'getParticipation', 'getUserInteractions']),
    autoplay_counter_display () {
      return Math.trunc(this.autoplay_counter / 10)
    },
    getPlayerOptions () {
      if (!this.getContentItem) {
        return {
          autoplay: this.hasPlayedOnce,
          controls: true,
          preload: 'auto',
          poster: null,
          sources: [
            {
              src: null,
              type: "video/mp4"
            }
          ]
        }
      }
      return {
        autoplay: this.hasPlayedOnce,
        controls: true,
        preload: 'auto',
        poster: this.getContentItem.url,
        sources: [
          {
            src: this.getContentItem.mp4_url,
            type: "video/mp4"
          }
        ]
      }
    },
    getUserInteraction () {
      if (!this.getContentItem) {
        return { "total_likes": 0 }
      }
      return this.getUserInteractions[this.getContentItem.id]
    },
    autoplay_counter_reversed () {
      return (this.autoplay_interval - this.autoplay_counter)
    },
    playerClass () {
      let cls = ""
      if (this.isLoading) {
        cls += " loading-next "
      }
      if (this.isVideo) {
        cls += " player-container "
      } else {
        cls += " img-container "
      }
      return cls
    },
    getContentItem () {
      if (this.getParticipation) {
        return this.getParticipation
      } else {
        return this.getHomeParticipations.filter(item => item.slug === this.content_slug)[0]
      }
    },
    isHorizontal () {
      if (!this.getContentItem) {
        return false
      }
      return this.getContentItem.orientation === 'horizontal'
    },
    getAsideClass () {
      if (!this.getContentItem) {
        return "col-xl-9 col-lg-8 col-md-12"
      }
      if (this.getContentItem.orientation === 'square') {
        return "col-md-6"
      }
      if (this.getContentItem.orientation === 'vertical') {
        return "col-lg-4 col-md-5 "
      }
      return "col-xl-9 col-lg-8 col-md-12"
    },
    getInfoClass () {
      if (!this.getContentItem) {
        return "col-md"
      }
      if (this.getContentItem.orientation === 'square') {
        return "col-md"
      }
      if (this.getContentItem.orientation === 'vertical') {
        return "col-md"
      }
      return "col-xl-3 col-lg-4 col-md"
    },
    getResponsiveClass () {
      let cls = this.playerClass
      if (this.isVideo) {
        cls += " embed-responsive "
        if (!this.getContentItem) {
          return cls + " embed-responsive-1by1"
        }
        if (this.getContentItem.orientation === 'square') {
          return cls + " embed-responsive-1by1"
        }
        if (this.getContentItem.orientation === 'vertical') {
          return cls + " embed-responsive-vertical"
        }
        return cls + " embed-responsive-16by9"
      }
      return cls
    },
    getVideoInfoClass () {
      let cls = this.playerClass
      if (!this.getContentItem) {
        return cls + " col-lg"
      }
      if (this.getContentItem.orientation === 'square') {
        return cls + " col-lg"
      }
      if (this.getContentItem.orientation === 'vertical') {
        return cls + " col-lg"
      }
      return cls + " col-12 col-md-8 col-lg-12"
    },
    getOtherVideos () {
      if (this.getParticipation) {
        return this.getParticipation.related
      }
      let index = this.getHomeParticipations.findIndex(item => item.slug === this.content_slug)
      let part1 = this.getHomeParticipations.slice(0, index)
      let part2 = this.getHomeParticipations.slice(index + 1)
      return part2.concat(part1)
    },
    content_slug () {
      return this.$route.params.slug
    },
    participationIsCompact () {
      return this.getCampaign.participations_style === 'compact'
    },
    getVerticalBoxSpacing () {
      if (this.participationIsCompact) {
        return 10
      }
      return 100
    },
    iconShowed () {
      if (this.isVideo) {
        return "play_circle_filled"
      } else {
        return "photo_size_select_actual"
      }
    },
    isVideo () {
      if (!this.getContentItem) {
        return false
      }
      return this.getContentItem.media_type === "video_internal" || this.getContentItem.media_type === "video"
    },
    isVideoExternal () {
      if (!this.getContentItem) {
        return false
      }
      return this.getContentItem.media_type === "video_external"
    },
    isPhoto () {
      if (!this.getContentItem) {
        return false
      }
      return this.getContentItem.media_type === "photo" || this.getContentItem.media_type === "image" || this.getContentItem.media_type === "carousel_album"
    },
    getIframe () {
      if (!this.getContentItem) {
        return null
      }
      if (this.getContentItem.media_type === "video_external") {
        return this.getContentItem.iframe
      }
      return null
    },
    getViewedTimeout () {
      if (this.isVideo) {
        return 3000
      } else {
        return 2000
      }
    },
    showPosition () {
      return this.getHomeConfig.selectedRanking !== 'most_recent'
    },
    staticTexts () {
      return {
        next: gettext("Siguiente")
      };
    }
  },
  watch: {
    content_slug (newValue) {
      if (this.getContentItem) {
        this.newContent(newValue)
      }
    }
  },
  methods: {
    ...mapActions(['registerParticipationInteractions', 'registerParticipationView', 'getParticipationDetails', 'clearParticipationDetails']),
    resetTimeout (clear = true) {
      if (clear) {
        clearTimeout(this.tmr_play)
      }
      this.tmr_play = setTimeout(this.registerView.bind(this), this.getViewedTimeout)
    },
    loadNext () {
      this.autoplay_visible = false
      this.$router.push({ name: 'content', params: { slug: this.getOtherVideos[0].slug } })
    },
    async newContent (newSlug) {
      // console.log(newSlug)
      clearTimeout(this.tmr_play)
      clearTimeout(this.tmr_photo_complete)
      await this.loadItem(newSlug)
      this.autoplay_visible = false
      await this.$nextTick()
      this.registerPageView()
      // console.log("VIDEO?: ", this.isVideo)
      this.setupContent()
      this.$meta().refresh()
    },
    setupContent () {
      if (this.isVideo) {
        this.setupPlayer()
      } else {
        this.resetTimeout()
        this.destroyPlayer()
        this.tmr_photo_complete = window.setTimeout(this.completeEvent.bind(this), 10000)
        if (this.getContentItem.origin === "instagram") {
          window.instgrm.Embeds.process();
        }
        if (this.getContentItem.origin === "tiktok") {
          let newH = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) - 200
          document.querySelector('.tiktok-embed').height = newH
          window.setTimeout(() => { document.querySelector('.tiktok-embed').height = newH }, 1000)
        }
      }
    },
    registerView () {
      let interactions = []
      interactions.push({ 'type': 'view', 'url': window.location.href })
      this.registerParticipationInteractions({ 'participation': this.getContentItem.id, interactions }).then(this.sync)
    },
    registerPageView () {
      let interactions = []
      interactions.push({ 'type': 'pageview', 'url': window.location.href })
      this.registerParticipationInteractions({ 'participation': this.getContentItem.id, interactions })
    },
    sync () {
      return this.loadItem(this.content_slug, false)
    },
    destroyPlayer () {
    },
    setupPlayer () {
    },
    playEvent () {
      this.resetTimeout()
      this.hasPlayedOnce = true
      // console.log("playEvent")
      //
    },
    pauseEvent () {
      // console.log("pauseEvent")
      //
    },
    completeEvent () {
      // console.log("completeEvent")
      if (this.autoplay) {
        if (this.hbplayer && this.hbplayer.getFullscreen()) {
          document.exitFullscreen()
        }
        this.autoplay_counter = this.autoplay_interval + 5
        this.autoplay_visible = true
        this.autoplayRunning = true
        this.tmr_autoplay = window.setTimeout(this.autoplayTick.bind(this), 100)
      }
    },
    autoplayTick () {
      this.autoplay_counter -= 1
      if (this.autoplay_counter > 1) {
        this.tmr_autoplay = window.setTimeout(this.autoplayTick.bind(this), 100)
      } else {
        this.autoplay_counter = this.autoplay_interval + 5
        this.autoplayRunning = false
        this.loadNext()
      }
    },
    stopAutoplay () {
      window.clearTimeout(this.tmr_autoplay)
      this.autoplay_visible = false
    },
    loadItem (slug, animate = true) {
      this.$Progress.start()
      if (animate) {
        this.isLoading = true
      }
      return this.getParticipationDetails(slug, 2).then(() => {
        this.$Progress.finish()
        if (animate) {
          this.isLoading = false
        }
      })
    }
  },
  data () {
    return {
      hbplayer: null,
      tmr_play: null,
      tmr_sync: null,
      tmr_autoplay: null,
      tmr_photo_complete: null,
      autoplay: false,
      autoplay_interval: 0,
      autoplay_counter: null,
      autoplay_visible: false,
      hasPlayedOnce: false,
      isLoading: false,
      autoplayRunning: false,
      pendingData: true
    }
  },
  deactivated () {
    this.stopAutoplay()
  },
  async mounted () {
    if (!this.getContentItem) {
      await this.newContent(this.$route.params.slug)
    } else {
      if (this.getContentItem.slug !== this.$route.params.slug) {
        await this.newContent(this.$route.params.slug)
      } else {
        this.setupContent()
      }
    }
    this.tmr_sync = setInterval(this.sync.bind(this), 99000)
  },
  async created () {
    if (this.getContentItem) {
      if (this.getContentItem.slug !== this.$route.params.slug) {
        await this.clearParticipationDetails()
      }
    }
    // await this.clearParticipationDetails()
    // console.log('cleared and loading...')
    this.sync().then(() => {
      this.pendingData = false
      // console.log('loaded!')
      this.autoplay = this.getCampaign.next_video_autoplay
      this.autoplay_interval = this.getCampaign.next_video_delay * 10
    })
  },
  beforeDestroy() {
    clearTimeout(this.tmr_play)
    clearInterval(this.tmr_sync)
    clearTimeout(this.tmr_photo_complete)
    this.stopAutoplay()
    this.tmr_play = null
    this.destroyPlayer()
  }
};

</script>

<style>
  .video-js .vjs-big-play-button {
    top:  44%;
    left: 40%;
  }
</style>
