<template>
  <div class="number-info" :class="extraClasses" :title="value">
    <div class="number-info--description" v-if="description && upsideDown">
      <img :src="getCampaign.favicon" :alt="getCampaign.name">
    </div>
    <div class="number-info--date">
      <strong>{{bigPart}}</strong>
      <small>{{sep}}{{smallPart}}<small>{{unit}}</small></small>
    </div>
    <div class="number-info--description" v-if="description && !upsideDown">
      <img :src="getCampaign.favicon" :alt="getCampaign.name">
      <p>{{description}}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const pad = (num, size) => {
  let s = "000000000" + num
  return s.substr(s.length - size)
}

export default {
  props: {
    value: {
      default: 0
    },
    upsideDown: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'percentage',
      validator: function (value) {
        return ['percentage', 'number', 'time'].indexOf(value) !== -1
      }
    },
    description: {
      type: String,
      default: null
    },
    extraClasses: {
      type: String,
      default: "number-info--med"
    }
  },
  watch: {
    value () {
      this.calculate()
    }
  },
  data () {
    return {
      bigPart: null,
      sep: null,
      smallPart: null,
      unit: null
    }
  },
  computed: {
    ...mapGetters(['getCampaign'])
  },
  methods: {
    setData (bigPart, sep, smallPart, unit) {
      this.bigPart = bigPart
      this.sep = sep
      this.smallPart = smallPart
      this.unit = unit
    },
    calculate () {
      if (this.type === 'percentage') {
        this.setData(parseInt(this.value), "", "", "%")
      } else if (this.type === 'time') {
        let hours = 0
        let minutes = 0
        let seconds = this.value
        if (seconds >= 60) {
          minutes = ~~(seconds / 60)
          seconds = seconds % 60
        }
        if (minutes >= 60) {
          hours = ~~(minutes / 60)
          minutes = minutes % 60
        }
        if (hours === 0 && minutes === 0) {
          this.setData(seconds, "", "", "s")
        } else {
          if (hours === 0) {
            this.setData(minutes, ":", pad(seconds, 2), "")
          } else {
            this.setData(hours, ":", pad(minutes, 2), ":" + pad(seconds, 2))
          }
        }
      } else {
        if (this.value < 1000) {
          this.setData(this.value, "", "", "")
        } else if (this.value < 1000000) {
          let aprox = Math.round(this.value / 100)
          let thousands = ~~(aprox / 10)
          let rest = aprox % 10
          this.setData(thousands, ".", rest, "K")
        } else {
          let aprox = Math.round(this.value / 100000)
          let millions = ~~(aprox / 10)
          let thousands = aprox % 10
          this.setData(millions, ".", thousands, "M")
        }
      }
    }
  },
  created () {
    this.calculate()
  }
};
</script>
<style lang="scss">
  .number-info {
    display: flex;
    flex-direction: column;

    .number-info--date {
      position: relative;
      top: -4em;
      strong {
        font-size: 4em;
        line-height: 1em;

        @media screen and (max-width: 767px) {
          font-size: 1.5em;
        }
      }

      small {
        font-weight: bolder;
        font-size: 1em;

        @media screen and (max-width: 767px) {
          font-size: 0.8em;
        }
      }
    }

    .number-info--description {
      font-size: 0.8em;
      opacity: 0.4;
      animation-name: pulse;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-timing-function: ease-out;
      position: relative;
      top: 2em;

      img {
        min-height: 9em;
        max-height: 9em;
      }

      @media screen and (max-width: 767px) {
        font-size: 0.7em;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  @keyframes pulse {
    0% {
      opacity: 0.1;
      transform: scale(0.8);
    }
    100% {
      opacity: 0.6;
      transform: scale(1.2)
    }
  }
</style>
