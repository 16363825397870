<template>
  <div class="page-transition page-transition-slide-up-2x">
    <filter-bar v-if="!isRelated" :available-tabs="availableTabs" :available-rankings="availableRankings" :available-categories="availableCategories" @loadingstarts="loadingStarts" @loadingends="loadingEnds"></filter-bar>
    <div v-else class="row bar-grid justify-content-between">
      <div class="col-12 bar-grid--title">
        <h5 class="bar-title font-corp">{{ staticTexts.moreParticipants }} <router-link to="/" class="color-custom-primary">{{ staticTexts.seeAll }}</router-link></h5>
      </div>
    </div>
    <div class="images-container" :class="{'loading': isLoading}">
      <zoom-center-transition>
        <vue-justified-layout v-show="getItems.length > 0" :items="getItems" :options="{ containerPadding: 15, targetRowHeight: 250, boxSpacing: { horizontal: 10, vertical: getVerticalBoxSpacing } }" v-slot="{item, style, index}">
          <content-list-item :key="item.id" :campaign="getCampaign" :item="item" :style="style" :ref="index" :showPosition="showPosition" :index="index" :paginator="getHomePaginator" :isCompact="participationIsCompact" :ranking="getHomeConfig.selectedRanking"></content-list-item>
        </vue-justified-layout>
      </zoom-center-transition>
      <div v-show="!getItems.length > 0" class="row">
        <div class="col">
          <p class="alert alert-info">{{ staticTexts.noMatchingParticipations }}</p>
        </div>
      </div>
    </div>

    <nav aria-label="Page navigation" v-show="getHomePaginator.num_pages > 1 && limit === 0" class="pagination-container">
      <pagination :perPage="getHomePaginator.size" :total="getHomePaginator.total_count" :value="getHomePaginator.page" @input="dispatchSetHomePageNumber"></pagination>
    </nav>
  </div>
</template>

<script>
/* global gettext */
import { mapGetters, mapActions } from 'vuex'
import ContentListItem from '@/components/Hitsbook/ContentListItem.vue'
import FilterBar from '@/components/Hitsbook/FilterBar.vue'
import Pagination from '@/components/Pagination.vue'
import { VueJustifiedLayout } from 'vue-justified-layout'
import { ZoomCenterTransition } from 'vue2-transitions'

export default {
  name: 'ContentList',
  components: {
    FilterBar,
    ContentListItem,
    Pagination,
    VueJustifiedLayout,
    ZoomCenterTransition
  },
  props: {
    items: Array,
    isRelated: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 0
    }
  },
  methods: {
    ...mapActions(['setHomePageNumber', 'doUpdateCampaign']),
    loadingStarts () {
      this.isLoading = true
    },
    loadingEnds () {
      this.isLoading = false
    },
    async dispatchSetHomePageNumber (value) {
      this.isLoading = true
      await this.setHomePageNumber(value)
      this.isLoading = false
    }
  },
  watch: {
    items(newvalue, oldvalue) {
      if (newvalue !== oldvalue) {
        window.dispatchEvent(new Event('resize'))
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 500)
      }
    }
  },
  data () {
    return {
      timer: null,
      msnry: null,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters(['getCampaign', 'getHomePaginator', 'getHomeConfig']),
    showPosition () {
      return this.getHomeConfig.selectedRanking !== 'most_recent' && this.limit === 0
    },
    getItems () {
      if (this.limit === 0) {
        return this.items
      } else {
        return this.items.slice(0, this.limit)
      }
    },
    participationIsCompact () {
      return this.getCampaign.participations_style === 'compact'
    },
    getVerticalBoxSpacing () {
      if (this.participationIsCompact) {
        return 50
      }
      return 100
    },
    availableTabs () {
      return this.getCampaign.available_tabs
    },
    availableCategories () {
      return this.getCampaign.available_categories
    },
    availableRankings () {
      return this.getCampaign.available_rankings
    },
    staticTexts () {
      return {
        moreParticipants: gettext("Más Participantes"),
        seeAll: gettext("Ver todos"),
        noMatchingParticipations: gettext("No hay participaciones que coincidan con tu criterio de búsqueda.")
      };
    }
  },
  mounted () {
    // this.doUpdateCampaign(this.getCampaign.id)
  }
};
</script>
