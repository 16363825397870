<template>
  <div class="container container-register">
    <transition name="slide-fade" mode="out-in">
      <template v-if="canParticipateResult.valid">
        <div class="row step1" v-if="step === 1" key="step1">
          <div class="col-md-6">
            <ValidationObserver v-slot="{ validate }">
              <form @submit.prevent="onSubmitStep1(validate)" autocomplete="nope">
                <!-- FORM HEADER-->
                <div class="form-header form-header-contentup mb-2">
                  <h3 class="title color-custom-primary mb-2 animation-appear" id="registerIn">
                    {{ staticTexts.registerIn }}
                  </h3>
                  <h5 class="font-corp animation-appear animation-delay-1" id="dataIsRequired">
                    {{ staticTexts.dataIsRequired }}
                  </h5>
                  <p class="animation-appear animation-delay-2" id="alreadyHasAccount">
                    <span class="pretext">{{ staticTexts.haveAnAccount }}</span>
                    <router-link class="color-custom-primary" :to="{'name': 'login'}">{{ staticTexts.initSession }}</router-link>
                  </p>
                  <h6 class="title-line animation-appear animation-delay-3" v-if="isFacebookEnabled || isGoogleEnabled">
                    <span class="pretext">{{ staticTexts.registerWith }}</span>
                    <span v-if="isFacebookEnabled"> {{ staticTexts.registerWithFacebook }} <span v-if="isGoogleEnabled">{{ staticTexts.registerWithOr }}</span> </span>
                    <span v-if="isGoogleEnabled"> {{ staticTexts.registerWithGoogle }} </span>
                  </h6>
                  <div class="row m-0 animation-appear animation-delay-4" v-if="isFacebookEnabled || isGoogleEnabled">
                    <button type="button" v-if="isFacebookEnabled" @click="fbLogin()" class="btn btn-social-login btn-facebook btn-link col">
                      <img src="/static/img/facebook.svg" />
                      <span>{{ staticTexts.facebook }}</span>
                    </button>
                    <button @click="goLogin()" v-if="isGoogleEnabled" type="button" class="btn btn-social-login btn-facebook btn-link col">
                      <img src="/static/img/google.svg" />
                      <span>{{ staticTexts.google }}</span>
                    </button>
                  </div>
                </div>
                <!-- END / FORM HEADER-->
                <!-- FORM BODY-->
                <div class="form-body animation-appear animation-delay-5" v-if="isEmailEnabled">
                  <h6>
                    <span v-if="isFacebookEnabled || isGoogleEnabled">{{ staticTexts.registerWithOr }} </span>
                  </h6>

                  <validation-provider :name="staticTexts.email" rules="required|email" v-slot="{ classes, errors }">
                    <div class="form-group md-field md-theme-default" :class="classes">
                        <label> {{ staticTexts.email }} *</label>
                        <b-form-input v-model="form.email" :placeholder="staticTexts.emailPlaceholder"></b-form-input>
                        <span class="error" v-if="errors">{{ errors[0] }}</span>
                    </div>
                  </validation-provider>

                  <div class="form-row" v-if="askPasswords">
                    <div class="col-sm">
                      <validation-provider :name="staticTexts.password" rules="required|min:6|confirmed:pass" v-slot="{ classes, errors }">
                        <div class="form-group md-field md-theme-default" :class="classes">
                          <label for="id_password">{{ staticTexts.password }}</label>
                          <b-form-input v-model="form.password" id="id_password" name="password" :placeholder="staticTexts.password" type="password"></b-form-input>
                          <span class="error" v-if="errors">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                    <div class="col-sm">
                      <validation-provider vid="pass" :name="staticTexts.repeatPassword" rules="required" v-slot="{ classes, errors }">
                        <div class="form-group md-field md-theme-default" :class="classes">
                          <label for="id_confirm_password">{{ staticTexts.repeatPassword }}</label>
                          <b-form-input v-model="form.confirm_password" id="id_confirm_password" :placeholder="staticTexts.repeatPassword" name="confirm_password" type="password"></b-form-input>
                          <span class="error">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <!-- END / FORM BODY-->
                <!-- FORM BOTTOM-->
                <div class="form-bottom mt-4 animation-appear animation-delay-6">
                  <!-- DYNAMIC FIELDS -->
                  <div class="form-row">
                    <div v-for="field in extraFields" :class="getFieldClasses(field)" :key="field.id">
                      <validation-provider :rules="getFieldRules(field)" :name="field.name" v-slot="{ errors, classes }">
                        <div v-if="field.type==='checkbox'" class="">
                          <b-form-checkbox
                            :id="field.id.toString()"
                            v-model="form.extra[field.id.toString()]"
                            :name="field.id.toString()"
                            :value="true"
                            :class="classes"
                            :unchecked-value="false"
                          >
                           <a href="javascript:;" class="color-custom-primary" @click.prevent="showModal(field.id.toString() + '-modal')" v-if="field.options.text">
                            {{ field.name }}
                           </a>
                           <b-modal :ref="field.id.toString() + '-modal'" hide-footer :title="field.name" v-if="field.options.text">
                              <div class="text-center p-3 m-3">
                                <p class="lead mt-3 text-dark">{{ field.options.text }}</p>
                              </div>
                           </b-modal>
                           <a :href="field.options.link" target="_blank" class="color-custom-primary" v-if="field.options.link">
                            {{ field.name }}
                           </a>
                           <span v-if="!field.options.link && !field.options.text">{{field.name}}</span>
                            <div>
                              <span class="error">{{ errors[0] }}</span>
                            </div>
                          </b-form-checkbox>
                        </div>
                        <div v-if="field.type==='char'" class="form-group md-field md-theme-default">
                          <label> {{ field.name }}</label>
                          <b-form-input
                            :id="field.id.toString()"
                            v-model="form.extra[field.id.toString()]"
                            :name="field.id.toString()"
                            class="font-corp md-input"
                            :class="classes"
                            :placeholder="field.name"
                          >
                          </b-form-input>
                          <em class="help_text">{{ field.description }}</em>
                          <span class="error">{{ errors[0] }}</span>
                        </div>
                        <div v-if="field.type==='date'" class="form-group md-field md-theme-default">
                          <label> {{ field.name }}</label>
                          <datetime class="form-group md-field md-theme-default" :phrases="{ok: 'Ok', cancel: 'Cancelar'}" :input-class="getInputClasses(classes)" v-model="form.extra[field.id.toString()]">
                          </datetime>
                          <em class="help_text">{{ field.description }}</em>
                          <span class="error">{{ errors[0] }}</span>
                        </div>
                        <div v-if="field.type==='integer'" class="form-group md-field md-theme-default">
                          <label> {{ field.name }}</label>
                          <b-form-input
                            :id="field.id.toString()"
                            v-model="form.extra[field.id.toString()]"
                            :name="field.id.toString()"
                            type="number"
                            :class="classes"
                            :placeholder="field.name"
                          >
                          </b-form-input>
                          <em class="help_text">{{ field.description }}</em>
                          <span class="error">{{ errors[0] }}</span>
                        </div>
                        <div v-if="field.type==='positive_integer'" class="form-group md-field md-theme-default">
                          <label> {{field.name}}</label>
                          <b-form-input
                            :id="field.id.toString()"
                            v-model="form.extra[field.id.toString()]"
                            :name="field.id.toString()"
                            type="number"
                            :class="classes"
                            :placeholder="field.name"
                            min="0"
                          >
                          </b-form-input>
                          <em class="help_text">{{ field.description }}</em>
                          <span class="error">{{ errors[0] }}</span>
                        </div>
                        <div v-if="field.type==='select'" class="form-group md-field md-theme-default">
                          <label> {{field.name}}</label>
                          <b-form-select
                            :id="field.id.toString()"
                            v-model="form.extra[field.id.toString()]"
                            :name="field.id.toString()"
                            :options="getFieldSelectOptions(field)"
                            :class="classes"
                            :placeholder="field.name"
                          >
                          </b-form-select>
                          <em class="help_text">{{ field.description }}</em>
                          <span class="error">{{ errors[0] }}</span>
                        </div>
                        <div v-if="field.type==='text'" class="form-group md-field md-theme-default">
                          <label> {{ field.name }}</label>
                          <b-form-textarea
                            :id="field.id.toString()"
                            v-model="form.extra[field.id.toString()]"
                            :name="field.id.toString()"
                            :rows="getFieldRows(field)"
                            :minrows="1"
                            class="font-corp md-input"
                            :class="classes"
                            :placeholder="field.name"
                          >
                          </b-form-textarea>
                          <em class="help_text" v-html="field.description"></em>
                          <span class="error">{{ errors[0] }}</span>
                        </div>
                      </validation-provider>
                    </div>
                  </div>

                  <validation-provider rules="acceptance" v-slot="{ errors, classes }">
                    <b-form-checkbox
                      id="legal_basis_acceptance"
                      v-model="form.legal_basis_acceptance"
                      name="legal_basis_acceptance"
                      value="accepted"
                      class="font-corp"
                      :class="classes"
                      unchecked-value="not_accepted"
                    >
                      <template v-if="getCampaignId === 538">
                        Al hacer clic en "Enviar código de validación", confirmas que tienes al menos 18 años, aceptas los <a href="/#/info/legal/legal_basis" class="color-custom-primary" target="_blank">{{ staticTexts.termsAndConditions }}</a> y reconoces que has leído y aceptas nuestra <a href="https://www.warnermediaprivacy.com/policycenter/b2c/es-row/" target="_blank">{{ staticTexts.privacyPolicy }}</a>. Además, reconoces que te enviaremos ofertas de marketing, promociones y otras ofertas de Canal Space y sus afiliadas (<a href="https://www.warnermediaprivacy.com/policycenter/b2c/affiliateslist/" target="_blank">ver detalles</a>) por e-mail, redes sociales y otros canales. Para cancelar en cualquier momento o para obtener más información sobre tus derechos y cómo ejercerlos, consulta las opciones disponibles en la Política de privacidad o enviar un correo a <a href="mailto:wmprivacy@warnermedia.com">wmprivacy@warnermedia.com</a>.
                      </template>
                      <template v-if="getCampaignId === 580">
                         {{ staticTexts.iAcceptThe }} <router-link :to="{name:'legal_basis'}" class="color-custom-primary" target="_blank">{{ staticTexts.legalBasis }}</router-link>
                      </template>
                      <template v-else>
                        {{ staticTexts.iAcceptThe }} <router-link :to="{name:'legal_basis'}" class="color-custom-primary" target="_blank">{{ staticTexts.legalBasis }}</router-link>, {{ staticTexts.the }} <router-link :to="{name:'terms'}" class="color-custom-primary" target="_blank">{{ staticTexts.termsAndConditions }}</router-link> {{ staticTexts.andThe }}
                          <router-link :to="{name:'privacy_policy'}" class="color-custom-primary" target="_blank">{{ staticTexts.privacyPolicy }}</router-link>
                      </template>
                      <div>
                        <span class="error">{{ errors[0] }}</span>
                      </div>
                    </b-form-checkbox>
                  </validation-provider>

                  <div class="form-row mt-5 align-items-center">
                    <div class="col-md-7">
                      <button type="submit" @click.prevent="onSubmitStep1(validate)" :disabled="sendCodeInProgress" class="btn btn-custom-primary btn-lg btn-block">
                        <span v-if="!sendCodeInProgress">
                          <span class="material-icons">
                          check_circle_outline </span>
                          {{ staticTexts.sendValidationCode }}
                        </span>
                        <span v-if="sendCodeInProgress">
                          <LoaderIndicator theme="light" :size="40" />
                          {{ staticTexts.sending }}
                        </span>

                      </button>
                    </div>
                    <div class="col">
                      <p class="mb-0 font-corp">
                        <small>
                          {{ staticTexts.clickSendCode }}
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- END / FORM BOTTOM-->
              </form>
            </ValidationObserver>
          </div>
          <div class="col-md-6">
            <figure class="animation-appear-right animation-delay-2">
              <img class="img-fluid" :src="getRegistrationImage" />
            </figure>
          </div>
        </div>
        <div class="row step2" v-if="step === 2" key="step2">
          <div class="col-md-6">
            <form>
              <!-- FORM HEADER-->
              <div class="form-header form-header-contentup mb-2 animation-appear">
                <h3 class="title color-custom-primary mb-2">
                  {{ staticTexts.validateAndParticipate }}
                </h3>
                <h5 class="font-corp mb-1">
                  {{ staticTexts.enterTheCodeReceived }} <strong class="current-email" @click="wrongEmail=!wrongEmail">{{form.email}}</strong>.<br />
                </h5>
                <transition name="slide-down">
                  <p class="wrong-email" v-if="wrongEmail">
                    {{ staticTexts.madeAMistake }}
                    <button class="btn btn-custom-primary btn-link btn-xs" @click.prevent="goToStep(1)"><span class="material-icons">edit</span> {{ staticTexts.fixNow }}</button>
                  </p>
                </transition>
                <p class="font-corp">
                  {{ staticTexts.codeNotReceived }}
                  <button @click.prevent="reSendCode" v-if="!resendInProgress && !resent" class="btn btn-link btn-custom-primary color-custom-primary">
                    {{ staticTexts.sendAgain }}
                  </button>
                  <span v-if="resendInProgress" class="btn btn-link btn-custom-primary color-custom-primary">
                    <LoaderIndicator :theme="getCampaign.theme_style" :size="40" /> {{ staticTexts.sending }}
                  </span>
                  <span v-show="resent" class="btn btn-link disabled btn-custom-primary color-custom-primary" :class="{'fade-out': resent}">
                    <i class="material-icons">check</i> {{ staticTexts.sent }}
                  </span>
                </p>
              </div>
              <!-- END / FORM HEADER-->
              <!-- FORM BODY-->
              <div class="form-body animation-appear animation-delay-1"  v-if="retries_left > 0">
                <ValidationObserver v-slot="{ invalid }">
                  <validation-provider ref="codeProvider" :name="staticTexts.code" rules="required|digits:6" v-slot="{ classes, errors }">
                    <div class="form-group md-field md-theme-default" :class="classes">
                      <div class="input-group">
                        <b-form-input v-model="form.code"></b-form-input>
                        <div class="input-group-btn">
                          <button class="btn btn-lg" :class="{'btn-success': !validatingCodeInProgress, 'btn-link': validatingCodeInProgress}" :disabled="validatingCodeInProgress || invalid" @click.prevent="validateCode">
                            <span v-if="!validatingCodeInProgress">
                              <i class="material-icons">
                                check
                              </i>
                              {{ staticTexts.validateCode }}
                            </span>
                            <span v-if="validatingCodeInProgress">
                              <LoaderIndicator :theme="getCampaign.theme_style" :inversed="false" :size="40" />
                              {{ staticTexts.validating }}
                            </span>
                          </button>
                        </div>
                      </div>
                      <span class="error" v-if="errors">{{ errors[0] }}</span>
                    </div>
                  </validation-provider>
                </ValidationObserver>
              </div>
              <div class="form-body" v-if="retries_left===0">
                <div class="alert alert-danger">
                  {{ staticTexts.tooManyRetries }}
                </div>
              </div>
              <!-- END / FORM BODY-->
              <!-- FORM BOTTOM-->
              <div class="form-bottom mt-3 animation-appear animation-delay-2">
                <p class="font-corp">
                  {{ staticTexts.haveAnAccount }} <router-link class="color-custom-primary" :to="{'name': 'login'}"><strong>{{ staticTexts.initSession }}</strong></router-link>
                </p>
              </div>
            </form>
          </div>
          <div class="col-md-6">
            <figure class="animation-appear-right animation-delay-2">
              <img class="img-fluid" :src="getPinImage" />
            </figure>
          </div>
        </div>
        <ValidationObserver v-slot="{ validate }" ref="observer" v-if="step === 3 && !uploadInProgress" key="step3">
          <div class="row justify-content-around">
            <div class="col-md-6 col-lg-5 col-xl-4">
              <!-- video up module -->
              <div class="card card-videoup">
                <div class="card-body">
                  <!-- video up ITEM -->
                  <div class="card-videoup--item" v-show="enabledOrigins.includes('hitsbook')">
                    <div id="accordion" role="tablist">
                      <div class="card-collapse">
                        <button
                          class="btn btn-custom-primary btn-link btn-heading btn-block text-left"
                          @click.prevent="setSource('upload')"
                        >
                          <i class="material-icons">
                            folder
                          </i>
                          {{staticTexts.attachTitle}}
                        </button>
                        <div
                          id="collapsePc"
                          class="collapse card-videoup--item---panel" :class="{'show': source === 'upload'}">
                          <div class="card-videoup--item---panel----box" @drop.prevent="addFile" @dragenter.prevent="dragEnter" @dragover.prevent="dragOver" @dragleave.prevent="dragLeave">
                            <h3 class="title drop-zone m-0" :class="{'pt-5': !participateForm.file, 'pb-5': !participateForm.file, 'dragging-over': isDragging}" @click="chooseFile" @drop.prevent="addFile" @dragover.prevent="dragOver">
                              <div v-if="participateForm.file">
                                <span class="text-success"><i class="fas fa-paperclip"></i> {{staticTexts.selectedFile}}</span>
                                <button class="btn btn-danger float-right btn-round" @click.prevent="clearFile"><i class="fas fa-trash-alt"></i></button>
                              </div>
                              <div v-else>
                                <i class="fas fa-plus-circle"></i>
                                <span v-if="!isDragging">{{staticTexts.dragFile}}</span>
                                <span v-else>{{staticTexts.dropFile}}</span>
                              </div>
                            </h3>
                            <validation-provider class="form-group" name="fichero" slim :rules="fileInputRules" v-slot="{ errors, validate }">
                              <input type="file" class="d-none" ref="fileInput" :accept="getFileAccept" @change="selectFile($event) || validate($event)" />
                              <span class="error" v-if="errors">{{ errors[0] }}</span>
                            </validation-provider>
                            <div class="text-center" v-if="participateForm.file">
                              <small>{{participateForm.file.name}}</small><br>
                              <small>{{participateForm.file.type}}</small>
                            </div>
                          </div>
                          <div class="card-videoup--item---panel----info">
                            <p class="font-corp">
                              <i class="fas fa-exclamation-circle"></i> {{staticTexts.maxSize}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- video up ITEM -->
                  <div class="card-videoup--item" v-show="enabledOrigins.includes('link_facebook')">
                    <div id="accordion" role="tablist">
                      <div class="card-collapse">
                        <button
                          class="btn btn-facebook btn-link btn-heading btn-block text-left"
                          @click.prevent="setSource('facebook')"
                        >
                          <i class="fab fa-facebook"></i>
                          {{staticTexts.uploadFrom}} Facebook
                        </button>
                        <div
                          id="collapseFacebook"
                          class="collapse card-videoup--item---panel" :class="{'show': source === 'facebook'}"
                        >
                          <div class="card-videoup--item---panel----box">
                            <form>
                              <div class="form-group md-field md-theme-default">
                                <validation-provider class="form-group md-field md-theme-default" :rules="socialLinkRule('facebook')" name="video_url" slim v-slot="{ errors, validate }">
                                  <input
                                    type="url"
                                    pattern="https://.*"
                                    class="form-control font-corp md-input"
                                    v-model="socialLinks.facebook"
                                    :placeholder="staticTexts.placeholderFB"
                                  />
                                  <span class="error" v-if="errors">{{ errors[0] }}</span>
                                </validation-provider>
                              </div>
                            </form>
                          </div>
                          <div class="card-videoup--item---panel----info">
                            <p class="font-corp">
                              {{staticTexts.uploadFromFB}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END / video up ITEM -->
                  <!-- video up ITEM -->
                  <div class="card-videoup--item" v-show="enabledOrigins.includes('link_instagram')">
                    <div id="accordion" role="tablist">
                      <div class="card-collapse">
                        <button
                          class="btn btn-instagram btn-link btn-heading btn-block text-left"
                          @click.prevent="setSource('instagram')"
                        >
                          <i class="fab fa-instagram"></i>
                          {{staticTexts.uploadFrom}} Instagram
                        </button>
                        <div
                          id="collapseInstagram"
                          class="collapse card-videoup--item---panel" :class="{'show': source === 'instagram'}"
                        >
                          <div class="card-videoup--item---panel----box">
                            <form>
                              <div class="form-group md-field md-theme-default">
                                <validation-provider class="form-group md-field md-theme-default" :rules="socialLinkRule('instagram')" name="video_url" slim v-slot="{ errors, validate }">
                                  <input
                                    type="url"
                                    pattern="https://.*"
                                    class="form-control font-corp md-input"
                                    v-model="socialLinks.instagram"
                                    :placeholder="staticTexts.placeholderIG"
                                  />
                                  <span class="error" v-if="errors">{{ errors[0] }}</span>
                                </validation-provider>
                              </div>
                            </form>
                          </div>
                          <div class="card-videoup--item---panel----info">
                            <p class="font-corp">
                              {{staticTexts.uploadFromIG}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END / video up ITEM -->
                  <!-- video up ITEM -->
                  <div class="card-videoup--item" v-show="enabledOrigins.includes('link_youtube')">
                    <div id="accordion" role="tablist">
                      <div class="card-collapse">
                        <button
                          class="btn btn-youtube btn-link btn-heading btn-block text-left"
                          @click.prevent="setSource('youtube')"
                        >
                          <i class="fab fa-youtube"></i>
                          {{staticTexts.uploadFrom}} Youtube
                        </button>
                        <div
                          id="collapseYoutube"
                          class="collapse card-videoup--item---panel"
                          :class="{'show': source === 'youtube'}"
                        >
                          <div class="card-videoup--item---panel----box">
                            <form>
                              <div class="form-group md-field md-theme-default">
                                <validation-provider class="form-group md-field md-theme-default" :rules="socialLinkRule('youtube')" name="video_url" slim v-slot="{ errors, validate }">
                                  <input
                                    type="url"
                                    pattern="https://.*"
                                    class="form-control font-corp md-input"
                                    v-model="socialLinks.youtube"
                                    :placeholder="staticTexts.placeholderYT"
                                  />
                                  <span class="error" v-if="errors">{{ errors[0] }}</span>
                                </validation-provider>
                              </div>
                            </form>
                          </div>
                          <div class="card-videoup--item---panel----info">
                            <p class="font-corp">
                              {{staticTexts.uploadFromYT}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END / video up ITEM -->
                  <!-- video up ITEM -->
                  <div class="card-videoup--item" v-show="enabledOrigins.includes('link_tiktok')">
                    <div id="accordion" role="tablist">
                      <div class="card-collapse">
                        <button
                          class="btn btn-tiktok btn-link btn-heading btn-block text-left"
                          @click.prevent="setSource('tiktok')"
                        >
                          <i class="fab fa-tiktok"></i>
                          {{staticTexts.uploadFrom}} TikTok
                        </button>
                        <div
                          id="collapseTiktok"
                          class="collapse card-videoup--item---panel"
                          :class="{'show': source === 'tiktok'}"
                        >
                          <div class="card-videoup--item---panel----box">
                            <form>
                              <div class="form-group md-field md-theme-default">
                                <validation-provider class="form-group md-field md-theme-default" :rules="socialLinkRule('tiktok')" name="video_url" slim v-slot="{ errors, validate }">
                                  <input
                                    type="url"
                                    pattern="https://.*"
                                    class="form-control font-corp md-input"
                                    v-model="socialLinks.tiktok"
                                    :placeholder="staticTexts.placeholderTT"
                                  />
                                  <span class="error" v-if="errors">{{ errors[0] }}</span>
                                </validation-provider>

                              </div>
                            </form>
                          </div>
                          <div class="card-videoup--item---panel----info">
                            <p class="font-corp">
                              {{staticTexts.uploadFromTT}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END / video up ITEM -->
                  <!-- video up ITEM -->
                  <div class="card-videoup--item" v-show="enabledOrigins.includes('link_twitter')">
                    <div id="accordion" role="tablist">
                      <div class="card-collapse">
                        <button
                          class="btn btn-twitter btn-link btn-heading btn-block text-left"
                          @click.prevent="setSource('twitter')"
                        >
                          <i class="fab fa-twitter"></i>
                          {{staticTexts.uploadFrom}} Twitter
                        </button>
                        <div
                          id="collapseTiktok"
                          class="collapse card-videoup--item---panel"
                          :class="{'show': source === 'twitter'}"
                        >
                          <div class="card-videoup--item---panel----box">
                            <form>
                              <div class="form-group md-field md-theme-default">
                                <validation-provider class="form-group md-field md-theme-default" :rules="socialLinkRule('twitter')" name="video_url" slim v-slot="{ errors, validate }">
                                  <input
                                    type="url"
                                    pattern="https://.*"
                                    class="form-control font-corp md-input"
                                    v-model="socialLinks.twitter"
                                    :placeholder="staticTexts.placeholderTW"
                                  />
                                  <span class="error" v-if="errors">{{ errors[0] }}</span>
                                </validation-provider>
                              </div>
                            </form>
                          </div>
                          <div class="card-videoup--item---panel----info">
                            <p class="font-corp">
                              {{staticTexts.uploadFromTW}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END / video up ITEM -->
                </div>
              </div>
              <!-- END / video up module -->
              <form @submit.prevent="onSubmitStep3(validate)" autocomplete="nope">
                <div class="form-body">
                  <div class="form-row">
                    <validation-provider :name="staticTexts.title" slim rules="required" v-slot="{ classes, errors }">
                      <div class="col-12 mb-2">
                        <div class="form-group md-field md-theme-default" :class="classes">
                          <label>{{staticTexts.title}}</label>
                          <b-form-input v-model="participateForm.title" :placeholder="staticTexts.title" class="form-control form-search"></b-form-input>
                          <span class="error" v-if="errors">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </validation-provider>
                    <validation-provider :name="staticTexts.description" slim rules="required" v-slot="{ classes, errors }">
                      <div class="col-12 mb-2">
                        <div class="form-group md-field md-theme-default" :class="classes">
                          <label>{{staticTexts.description}}</label>
                          <b-form-textarea rows="3" v-model="participateForm.description" :placeholder="staticTexts.description" class="form-control form-search"></b-form-textarea>
                          <span class="error" v-if="errors">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </validation-provider>
                    <validation-provider :name="staticTexts.category" slim rules="required" v-slot="{ classes, errors }" v-if="getCategoryOptions.length > 0">
                      <div class="col-12 mb-2">
                        <div class="form-group md-field md-theme-default" :class="classes">
                          <HBLabel :title="staticTexts.category">{{staticTexts.categoryHelp}}</HBLabel>
                          <b-form-select
                            v-model="participateForm.category"
                            :options="getCategoryOptions"
                            class="font-corp md-input"
                            :class="classes"
                            :placeholder="staticTexts.category"
                          >
                          </b-form-select>
                          <span class="error" v-if="errors">{{ errors[0] }}</span>
                        </div>
                      </div>
                    </validation-provider>
                    <validation-provider :name="staticTexts.signature" slim rules="required" v-slot="{ classes, errors }" v-if="getCampaign.request_participants_signature">
                      <div class="col-12 mb-2">
                          <div class="form-group md-field md-theme-default" :class="classes">
                            <HBLabel :title="staticTexts.signature">{{staticTexts.signatureHelp}}</HBLabel>
                            <SignBBLL v-model="participateForm.signature" btnExtraClass="btn-block" :legalText="getCampaign.legal_basis"></SignBBLL>
                            <span class="error" v-if="errors">{{ errors[0] }}</span>
                          </div>
                      </div>
                    </validation-provider>
                    <div class="col-12 mb-2" v-if="getCampaign.only_minors_can_participate">
                      <MinorsManager v-model="participateForm.minors" :legalText="getCampaign.custom_legal_minors" class="form-group md-field md-theme-default" :class="classes"></MinorsManager>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <button class="btn btn-success btn-lg btn-block">
                    {{staticTexts.uploadButton}}
                  </button>
                </div>
              </form>
            </div>
            <div class="col-md-6">
              <!-- FORM HEADER-->
              <aside class="cms-sidebar">
                <div class="cms-sidebar--box">
                  <h3 class="title color-custom-primary mb-2 custom-font">
                    {{staticTexts.almostReady}}
                  </h3>
                  <h4>
                    {{staticTexts.emailValidated}}
                  </h4>
                  <figure>
                    <img class="img-fluid" :src="getLoginImage" />
                  </figure>
                </div>
              </aside>
              <!-- END / FORM HEADER-->
            </div>
          </div>
        </ValidationObserver>
        <div class="row justify-content-center step3" v-if="step === 3 && uploadInProgress" key="step3.1">
          <div class="col-md-6 text-center">
            <h2 class="title color-custom-primary mb-2 animation-appear">
              {{staticTexts.uploadingText}}
            </h2>
            <p class="font-corp animation-appear animation-delay-1 mt-5">
            </p>
            <DoughnutChart :value="uploadPercent" :percent="uploadPercent" variant="type1" :description="staticTexts.uploading"></DoughnutChart>
          </div>
        </div>
        <div class="row justify-content-center step4" v-if="step === 4" key="step4">
          <div class="col-md-6 text-center">
            <div class="text-center m-5">
              <div class="svg-container">
                  <svg class="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
                      <circle class="circle" fill="#5bb543" cx="24" cy="24" r="22"/>
                      <path class="tick" fill="none" stroke="#FFF" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M14 27l5.917 4.917L34 17"/>
                  </svg>
              </div>
            </div>
            <h2 class="title thanks thanks-title color-custom-primary mt-3 mb-4 animation-appear">
              {{ staticTexts.thanksForParticipating }}
            </h2>
            <p class="font-corp thanks thanks-public animation-appear animation-delay-1" v-if="getCampaign.show_participations">
              {{ staticTexts.manyThanksForParticipating }}
            </p>
            <p class="font-corp thanks thanks-private animation-appear animation-delay-1" v-else>
              {{ staticTexts.manyThanksForParticipatingPrivate }}
            </p>
            <p class="font-corp thanks thanks-shock animation-appear animation-delay-1 d-none d-none-special">
              Revisaremos si el video cumple con las condiciones y, cuando sea aprobado, les llegará un correo de confirmación.
            </p>
            <p class="text-align-center animation-appear animation-delay-2 mt-5">
              <router-link :to="getNextUrl" class="btn btn-primary">{{ staticTexts.continue }} &rarr;</router-link>
            </p>
          </div>
        </div>
      </template>
      <div class="row justify-content-center cannot-participate-message" v-if="!canParticipateResult.valid" key="stepError">
        <div class="col-md-6 text-center">
          <div class="text-center m-5">
            <div class="svg-container m-auto">
                <svg version="1.1" id="Warn" height="128" width="128" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                    <path style="fill:#3B4145;" d="M322.939,62.642l178.737,309.583C508.231,383.578,512,396.74,512,410.791
                      c0,42.67-34.592,77.264-77.264,77.264H256L194.189,256L256,23.946C284.62,23.946,309.587,39.519,322.939,62.642z"/>
                    <path style="fill:#525A61;" d="M189.061,62.642L10.323,372.225C3.769,383.578,0,396.74,0,410.791
                      c0,42.67,34.592,77.264,77.264,77.264H256V23.946C227.38,23.946,202.413,39.519,189.061,62.642z"/>
                    <path style="fill:#FFB751;" d="M474.913,387.678L296.177,78.098c-8.056-13.959-22.849-22.767-38.848-23.22l152.869,402.275h24.539
                      c25.559,0,46.358-20.798,46.358-46.358C481.095,402.677,478.952,394.683,474.913,387.678z"/>
                    <path style="fill:#FFD764;" d="M444.853,387.678c3.492,7.005,5.336,14.999,5.336,23.117c0,25.559-17.935,46.358-39.992,46.358
                      H77.264c-25.559,0-46.358-20.799-46.358-46.358c0-8.118,2.143-16.112,6.181-23.117l178.736-309.58
                      c8.283-14.34,23.674-23.251,40.177-23.251c0.443,0,0.886,0.01,1.329,0.031c13.732,0.536,26.414,9.323,33.326,23.22L444.853,387.678z
                      "/>
                    <path style="fill:#3B4145;" d="M256,354.131v51.509c14.227,0,25.755-11.528,25.755-25.755
                      C281.755,365.659,270.227,354.131,256,354.131z"/>
                    <path style="fill:#525A61;" d="M256,354.131c2.843,0,5.151,11.528,5.151,25.755c0,14.227-2.308,25.755-5.151,25.755
                      c-14.227,0-25.755-11.528-25.755-25.755C230.245,365.659,241.773,354.131,256,354.131z"/>
                    <path style="fill:#3B4145;" d="M256,132.646V323.23c14.227,0,25.755-11.538,25.755-25.755V158.401
                      C281.755,144.174,270.227,132.646,256,132.646z"/>
                    <path style="fill:#525A61;" d="M256,132.646c2.843,0,5.151,11.528,5.151,25.755v139.074c0,14.216-2.308,25.755-5.151,25.755
                      c-14.227,0-25.755-11.538-25.755-25.755V158.401C230.245,144.174,241.773,132.646,256,132.646z"/>
                </svg>
            </div>
          </div>
          <h2 class="title error-title color-custom-primary mt-3 mb-4 animation-appear">
            {{staticTexts.cannotParticipate}}
          </h2>
          <p class="font-corp error-detail country-error animation-appear animation-delay-1" v-if="!canParticipateResult.valid_country">
            {{staticTexts.countryRestriction}}
          </p>
          <p class="font-corp error-detail dates-error animation-appear animation-delay-1" v-if="!canParticipateResult.valid_dates">
            {{staticTexts.datesRestriction}}
          </p>
          <p class="font-corp error-detail limit-error animation-appear animation-delay-1" v-if="!canParticipateResult.valid_user">
            {{staticTexts.limitRestriction}}
          </p>
          <p class="text-align-center animation-appear animation-delay-2 mt-5">
            <router-link :to="getNextUrl" class="btn btn-primary">&larr; {{staticTexts.backLink}}</router-link>
          </p>
        </div>
      </div>
    </transition>

    <b-modal ref="modal-loading" hide-footer hide-header-close no-close-on-backdrop :title="staticTexts.waitFewSeconds">
      <div class="text-center p-3 m-3">
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
        <p class="lead mt-3 text-dark">{{ messageLoading }}</p>
      </div>
    </b-modal>

  </div>
</template>

<script>
/* global gettext */
/* global FB_LOGIN_URL */
/* global GO_LOGIN_URL */
/* global CURRENT_LANGUAGE_CODE */

import { mapGetters, mapActions, mapMutations } from 'vuex'
import { BFormInput, BFormCheckbox, BFormSelect, BFormTextarea, BModal, BSpinner } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import LoaderIndicator from '@/components/Hitsbook/LoaderIndicator'
import HBLabel from '@/components/Hitsbook/HBLabel'
import DoughnutChart from '@/components/Hitsbook/DoughnutChart.vue'
import SignBBLL from '@/components/Hitsbook/SignBBLL'
import MinorsManager from '@/components/Hitsbook/MinorsManager'
import Swal from 'sweetalert2'

import { v4 as uuidv4 } from 'uuid'
import { Settings } from 'luxon'
import { Datetime } from 'vue-datetime'

Settings.defaultLocale = 'es'

export default {
  components: {
    LoaderIndicator,
    HBLabel,
    BFormInput,
    BFormCheckbox,
    BFormSelect,
    BFormTextarea,
    Datetime,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    DoughnutChart,
    SignBBLL,
    MinorsManager
  },
  computed: {
    ...mapGetters(['getCampaign', 'getCampaignId', 'getLoginRequired', 'getFormExtraFields', 'getAuth']),
    isDragging () {
      return this.dropOver > 0
    },
    enabledOrigins () {
      return this.getCampaign.origins
    },
    getCategoryOptions () {
      let options = []
      this.getCampaign.available_categories_for_participate.forEach((item) => {
        options.push({
          'value': item[0],
          'text': item[1]
        })
      })
      return options
    },
    askPasswords () {
      return this.getCampaign.request_password_on_registration_form
    },
    extraFields () {
      // TODO: Revisar que se ordene por el nuevo campo de orden que hizo jarr
      return this.getFormExtraFields.filter(item => item.request_to_participate).sort((a, b) => { return a.participation_order - b.participation_order })
    },
    getNextUrl () {
      if (this.nextUrl) {
        return this.nextUrl
      } else {
        return "/"
      }
    },
    getLoginImage () {
      if (this.getCampaign.registration_login_image) {
        return this.getCampaign.registration_login_image
      } else {
        return '/static/img/register/img-register-02.png'
      }
    },
    getPinImage () {
      if (this.getCampaign.registration_pin_image) {
        return this.getCampaign.registration_pin_image
      } else {
        return '/static/img/register/img-register-03.png'
      }
    },
    getRegistrationImage () {
      if (this.getCampaign.registration_image) {
        return this.getCampaign.registration_image
      } else {
        return '/static/img/register/img-register-01.png'
      }
    },
    isEmailEnabled () {
      if (Array.isArray(this.getCampaign.registration_sources)) {
        return this.getCampaign.registration_sources.includes('email')
      }
      return false
    },
    isFacebookEnabled () {
      if (Array.isArray(this.getCampaign.registration_sources)) {
        return this.getCampaign.registration_sources.includes('facebook') && this.getCampaign.registration_sources_validated
      }
      return false
    },
    isGoogleEnabled () {
      if (Array.isArray(this.getCampaign.registration_sources)) {
        return this.getCampaign.registration_sources.includes('google') && this.getCampaign.registration_sources_validated
      }
      return false
    },
    fileInputRules () {
      if (this.participateForm.file || this.source !== 'upload') {
        return ""
      }
      return "required"
    },
    getFileAccept () {
      let result = []
      let validTypes = this.getCampaign.content_type
      validTypes.forEach((vt) => {
        let ctFamily = vt.code
        if (ctFamily === 'photo') ctFamily = 'image'
        result.push(ctFamily + "/*")
      })
      return result.join(",")
    },
    staticTexts () {
      return {
        registerIn: gettext("¡Sube tu vídeo y participa!"),
        dataIsRequired: gettext("Para que puedas participar, además de tu vídeo necesitamos saber quién eres, por eso te pedimos los siguientes datos:"),
        haveAnAccount: gettext("¿Ya tienes cuenta?"),
        initSession: gettext("Inicia Sesión"),
        registerWith: gettext("Regístrate con tu cuenta"),
        registerWithFacebook: gettext("FACEBOOK"),
        registerWithGoogle: gettext("GOOGLE"),
        registerWithOr: gettext("o"),
        facebook: gettext("Facebook"),
        google: gettext("Google"),
        registerWithEmail: gettext("regístrate con tu email"),
        email: gettext("Regístrate con tu email"),
        emailPlaceholder: gettext("john@doe.com"),
        password: gettext("Contraseña"),
        repeatPassword: gettext("Repetir contraseña"),
        cancel: gettext("Cancelar"),
        sendValidationCode: gettext("Enviar código de validación"),
        sending: gettext("Enviando..."),
        clickSendCode: gettext('Haz click en "Enviar código" para que te llegue un PIN por email para validar tu registro.'),
        validateAndParticipate: gettext("¡Valida y participa!"),
        enterTheCodeReceived: gettext("Introduce el código que te hemos enviado por email a"),
        madeAMistake: gettext("¿Te has equivocado al escribirlo?"),
        fixNow: gettext("Corregir ahora"),
        codeNotReceived: gettext("¿No te ha llegado el código?"),
        sendAgain: gettext("Volver a enviar"),
        sent: gettext("Enviado"),
        validateCode: gettext("Validar código"),
        validating: gettext("Validando..."),
        tooManyRetries: gettext("Has agotado el número máximo de intentos. El PIN ha sido invalidado por motivos de seguridad. Pulsa en 'Volver a enviar' para reiniciar el proceso. Te enviaremos un nuevo PIN por email."),
        thanksForParticipating: gettext("¡Gracias por participar!"),
        manyThanksForParticipating: gettext("Muchas gracias por participar en la campaña. Tu participación pasa por un proceso de revisión por lo que puede tardar un tiempo en aparecer públicamente. Te avisaremos en cuanto se haya aceptado."),
        manyThanksForParticipatingPrivate: gettext("Muchas gracias por participar en la campaña."),
        continue: gettext("Continuar"),
        waitFewSeconds: gettext("Espere unos segundos..."),
        iAcceptThe: gettext("Acepto las"),
        legalBasis: gettext("Bases legales"),
        the: gettext("los"),
        termsAndConditions: gettext("Términos y condiciones de uso"),
        andThe: gettext("y la"),
        privacyPolicy: gettext("Política de privacidad"),
        code: gettext("Código"),
        title: gettext("Título de tu participación"),
        description: gettext("Descripción de tu participación"),
        category: gettext("Categoría"),
        iamauthor: gettext("Soy el autor de este vídeo y tengo todos los derechos sobre él"),
        almostReady: gettext("¡Casi listo!"),
        emailValidated: gettext("Tu email ha sido validado, ahora puedes subir tu vídeo y participar en el concurso."),
        attachTitle: gettext("Subir desde tu dispositivo"),
        dragFile: gettext("¡Haz click y sube tu participación aquí!"),
        dropFile: gettext("Suelta aquí tu archivo"),
        maxSize: gettext("El tamaño máximo de archivo permitido es de 500.0 MB."),
        alsoFromNetworks: gettext("Recuerda que puedes subir tu vídeo desde tus redes sociales preferidas."),
        categoryHelp: gettext("Asocia tu participación a una de las categorías disponibles"),
        uploadButton: gettext("¿Todo listo? ¡Haz clic y participa!"),
        uploadingText: gettext("Se está enviando tu participación"),
        uploading: gettext("Subiendo..."),
        selectedFile: gettext("Archivo seleccionado"),
        cannotParticipate: gettext("Ups! No puedes participar"),
        countryRestriction: gettext("Las bases de la campaña prohiben participar desde tu país."),
        datesRestriction: gettext("En estos momentos la participación está cerrada."),
        limitRestriction: gettext("Ya tienes una participación activa."),
        backLink: gettext("Volver"),
        uploadFrom: gettext("Subir desde"),
        uploadFromFB: gettext("Sube tu vídeo de manera instantánea con Facebook: Copia la URL y pégala en este campo. Recuerda que debes tener el vídeo en Facebook en modo público para todos."),
        uploadFromIG: gettext("Sube tu vídeo de manera instantánea con Instagram: Copia la URL y pégala en este campo. Recuerda que debes tener el vídeo en Instagram en modo público para todos."),
        uploadFromYT: gettext("Sube tu vídeo de manera instantánea con Youtube: Copia la URL y pégala en este campo."),
        uploadFromTT: gettext("Sube tu vídeo de manera instantánea con Tiktok: Copia la URL y pégala en este campo."),
        uploadFromTW: gettext("Sube tu vídeo de manera instantánea con Twitter: Copia la URL y pégala en este campo."),
        placeholderFB: gettext("Pega aquí la url de tu vídeo de Facebook"),
        placeholderIG: gettext("Pega aquí la url de tu vídeo de Instagram"),
        placeholderYT: gettext("Pega aquí la url de tu vídeo de Youtube"),
        placeholderTT: gettext("Pega aquí la url de tu vídeo de Tiktok"),
        placeholderTW: gettext("Pega aquí la url de tu vídeo de Twitter"),
        signature: gettext("Firma de las bases legales"),
        signatureHelp: gettext("Debes revisar y firmar las bases legales")
      };
    }
  },
  methods: {
    ...mapActions([
      'registerCampaignInteractions',
      'mockAPICall',
      'mockAPIUploadCall',
      'participatePhotoInternal',
      'participateVideoInternal',
      'participateVideoExternal',
      'performPendingAction',
      'doRequestEmailValidationCode',
      'doValidateEmail',
      'doRegisterByEmailToParticipate',
      'canParticipate']),
    ...mapMutations(['setParticipationAttempt']),
    showModal (ref) {
      this.$refs[ref][0].show()
    },
    getInputClasses (classes) {
      classes['form-control'] = true
      classes['md-input'] = true
      return classes
    },
    isOriginEnabled(source) {
      let origin = "link_" + source
      return this.getCampaign.objective.origins.includes(origin)
    },
    socialLinkRule (kind) {
      if (kind === this.source) {
        return "required"
      }
      return ""
    },
    fbLogin () {
      this.messageLoading = "Conectando con Facebook..."
      this.$refs['modal-loading'].show()

      let url = FB_LOGIN_URL + "?next=" + encodeURIComponent("/#/accounts/required-data-participate")
      window.location = url
    },
    goLogin () {
      this.messageLoading = "Conectando con Google..."
      this.$refs['modal-loading'].show()

      let url = GO_LOGIN_URL + "&next=" + encodeURIComponent("/#/accounts/required-data-participate")
      window.location = url
    },
    reSendCode () {
      if (!this.resendInProgress && !this.resent) {
        this.resendInProgress = true
        let payload = {
          email: this.form.email,
          token: this.form.token
        }
        this.doRequestEmailValidationCode(payload).then((response) => {
          this.retries_left = response.data.retries_left
          this.reSendCodeCompleted()
        })
        this.registerEvent('participate-resend-code')
      }
    },
    getRegistrationPayload () {
      let payload = {
        email: this.form.email,
        password: this.form.password,
        password_repeat: this.form.confirm_password,
        token: this.form.token,
        code: this.form.code,
        gtm: this.$gtm,
        conditions_accepted: true
      }
      Object.keys(this.form.extra).forEach(key => {
        payload[key] = this.form.extra[key]
      })
      this.extraFields.forEach(item => {
        if (item.id.toString() in payload) {
          if (item.type === 'date') {
            payload[item.id.toString()] = payload[item.id.toString()].split("T")[0]
          }
        } else {
          if (item.type === 'checkbox') {
            payload[item.id.toString()] = false
          } else if (item.type === 'char' || item.type === 'text' || item.type === 'select') {
            payload[item.id.toString()] = ''
          } else {
            payload[item.id.toString()] = null
          }
        }
      })
      // console.log(payload)

      return payload
    },
    reSendCodeCompleted () {
      this.resendInProgress = false
      this.resent = true
      setTimeout(this.resentCleanup.bind(this), 5000)
    },
    resentCleanup () {
      this.resent = false
    },
    validateCode () {
      if (!this.resendInProgress && !this.resent) {
        this.validatingCodeInProgress = true
        let payload = {
          email: this.form.email,
          token: this.form.token,
          code: this.form.code
        }
        this.doValidateEmail(payload).then((response) => {
          this.registerEvent('participate-valid-code')
          this.doRegisterByEmailToParticipate(this.getRegistrationPayload()).then((response) => {
            this.registerEvent('participate-completed')
            this.goToStep(3)
          }).catch((error) => {
            this.$refs.codeProvider.applyResult({
              errors: error.response.data.errors,
              valid: false,
              failedRules: {}
            })

            this.registerEvent('participate-invalid-data')
            this.goToStep(2)
          })
        }).catch((error) => {
          this.retries_left = error.response.data.retries_left
          this.$refs.codeProvider.applyResult({
            errors: [gettext("El PIN no es válido. Asegúrese de escribirlo tal cual se lo hemos enviado por email.")],
            valid: false,
            failedRules: {}
          })
          this.registerEvent('participate-invalid-code')
        }).finally(() => {
          this.validatingCodeInProgress = false
        })
      }
    },
    onSubmitStep1 (validate) {
      localize(this.lang)
      validate().then((isValid) => {
        if (isValid) {
          this.sendCodeInProgress = true
          // API Call
          let payload = {
            email: this.form.email,
            token: this.form.token
          }
          this.doRequestEmailValidationCode(payload).then((response) => {
            this.retries_left = response.data.retries_left
            this.goToStep(2)
            this.registerEvent('participate-send-code')
          }).catch((error) => {
            // console.log("response error", error.response)
            if ('email' in error.response.data) {
              this.$notify({
                message: error.response.data.email.join("<br>"),
                icon: 'add_alert',
                horizontalAlign: 'center',
                verticalAlign: 'bottom',
                type: 'danger'
              })
              this.registerEvent('basic-registration-error-email-bad-domain')
            } else {
              this.$notify({
                message: error.response.data.errors.join("<br>"),
                icon: 'add_alert',
                horizontalAlign: 'center',
                verticalAlign: 'bottom',
                type: 'danger'
              })
              this.registerEvent('basic-registration-error-email-already-exists')
            }
          }).finally(() => {
            this.sendCodeInProgress = false
          })
        } else {
          // console.log("NOT valid form")
        }
      }).catch(() => {
        // console.log("An unexpected error occurred while validating form values")
      })
    },
    goToStep (number) {
      this.step = number
      if (number === 2) {
        this.wrongEmail = false
      }
      this.registerEvent('participate-step-' + number)
    },
    registerEvent (eventName) {
      let interactions = []
      interactions.push({ 'type': 'event', 'url': window.location.href, 'event': eventName })
      this.registerCampaignInteractions({ 'campaign': this.getCampaignId, interactions })
    },
    getFieldRules (field) {
      let rules = []
      if (field.is_required_to_participate) {
        if (field.type === "checkbox") {
          rules.push("checkboxrequired")
        } else {
          rules.push("required")
        }
      }
      if (field.type === "integer") {
        rules.push("integer")
      }
      if (field.type === "positive_integer") {
        rules.push("integer")
        rules.push("min_value:0")
      }
      if (field.id === 2314 || field.id === 2320) {
        rules.push("alpha_num")
        rules.push("min:9")
        rules.push("max:12")
      }
      return rules.join("|")
    },
    getFieldClasses (field) {
      return field.css_classes + " mb-2"
    },
    getFieldSelectOptions (field) {
      return [...field.options.options]
    },
    getFieldRows (field) {
      if (field.id === 2314 || field.id === 2320) {
        return 1
      }
      if ("rows" in field.options) {
        return field.options.rows
      }
      return 4
    },
    setSource (newValue) {
      this.source = newValue
      this.participateForm.video_url = null
    },
    addFile (e) {
      let droppedFiles = e.dataTransfer.files
      if (!droppedFiles) return
      ([...droppedFiles]).forEach(f => {
        this.setFile(f)
      })
      this.dropOver = 0
    },
    selectFile (e) {
      if (e.target.files.length > 0) {
        let file = e.target.files[0]
        this.setFile(file)
      }
    },
    isValidFileType (file) {
      let validTypes = this.getCampaign.objective.content_types
      let isValid = false
      validTypes.forEach((vt) => {
        let ctFamily = vt
        if (ctFamily === 'photo') ctFamily = 'image'
        if (file.type.includes(ctFamily)) {
          isValid = true
        }
      })
      return isValid
    },
    setFile (file) {
      if (this.isValidFileType(file)) {
        this.participateForm.file = file
      } else {
        Swal.fire({
          title: gettext('Ups!'),
          text: gettext('El formato del fichero no es válido. Escoja otro fichero.'),
          icon: 'error',
          confirmButtonText: gettext('Ok')
        })
      }
    },
    clearFile (e) {
      this.participateForm.file = null
      this.$refs.fileInput.value = ''
      e.stopPropagation()
    },
    dragEnter () {
      this.dropOver += 1
    },
    dragLeave () {
      this.dropOver -= 1
    },
    dragOver (evt) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
    },
    chooseFile () {
      this.$refs.fileInput.click()
    },
    animateUpload () {
      this.uploadPercent += 1
      if (this.uploadPercent < 100) {
        setTimeout(() => { this.animateUpload() }, 200)
      } else {
        // this.uploadInProgress = false
        // this.goToStep(4)
      }
    },
    getMinorsPayload () {
      let payload = []
      this.participateForm.minors.forEach((minor) => {
        let minorPayload = {
          child_name: minor.name,
          child_surname: minor.surname,
          child_age: minor.age,
          child_id: minor.id,
          child_address: minor.address,
          tutor1_name: minor.tutor1.name,
          tutor1_surname: minor.tutor1.surname,
          tutor1_id: minor.tutor1.id,
          tutor1_email: minor.tutor1.email,
          tutor1_signature: minor.tutor1.signature.signature,
          tutor2_non_exists: minor.tutor2_non_exists,
          tutor2_name: null,
          tutor2_surname: null,
          tutor2_id: null,
          tutor2_email: null,
          tutor2_signature: null
        }
        if (!minor.tutor2_non_exists) {
          minorPayload['tutor2_name'] = minor.tutor2.name
          minorPayload['tutor2_surname'] = minor.tutor2.surname
          minorPayload['tutor2_id'] = minor.tutor2.id
          minorPayload['tutor2_email'] = minor.tutor2.email
          minorPayload['tutor2_signature'] = minor.tutor2.signature.signature
        }

        payload.push(minorPayload)
      })
      return JSON.stringify(payload)
    },
    onSubmitStep3 (validate) {
      validate().then((isValid) => {
        if (isValid) {
          let payload = {
            title: this.participateForm.title,
            description: this.participateForm.description
          }
          if (this.getCategoryOptions.length > 0) {
            payload["category"] = this.participateForm.category
          }
          this.uploadInProgress = true
          payload["uploadHandler"] = this.uploadHandler.bind(this)

          if (this.getCampaign.request_participants_signature) {
            payload["signature"] = this.participateForm.signature.signature
          }
          if (this.participateForm.minors.length > 0) {
            payload['minors'] = this.getMinorsPayload()
          }

          if (this.source === "upload") {
            let fileType = this.participateForm.file.type
            if (fileType.includes('image')) {
              payload["photo_file"] = this.participateForm.file
              this.participatePhotoInternal(payload).then(() => { this.uploadOK() }).catch((error) => {
                this.uploadInProgress = false
                this.$nextTick(() => {
                  this.$refs.observer.setErrors(error.response.data);
                })
              })
            } else {
              payload["video_file"] = this.participateForm.file
              this.participateVideoInternal(payload).then(() => { this.uploadOK() }).catch((error) => {
                this.uploadInProgress = false
                this.$nextTick(() => {
                  // console.log(error.response.data)
                  // alert(error.response.data)
                  this.$refs.observer.setErrors(error.response.data);
                })
              })
            }
          } else {
            payload["video_url"] = this.getVideoUrl()
            payload["origin"] = this.getOrigin()
            this.participateVideoExternal(payload).then(() => { this.uploadOK() }).catch((error) => {
              let errors = error.response.data;
              this.uploadInProgress = false
              this.$nextTick(() => {
                this.$refs.observer.setErrors(errors);
              });
              if ("video_url" in errors) {
                let message = errors['video_url'][0]
                this.$swal.fire({
                  title: 'Error',
                  text: message,
                  icon: 'error',
                  timer: 4000,
                  timerProgressBar: true
                })
              }
            })
          }
        } else {
          // console.log("NOT valid form")
        }
      }).catch((e) => {
        // console.log(e)
        // console.log("An unexpected error occurred while validating form values")
      })
    },
    getVideoUrl () {
      if (this.source === "facebook") {
        return this.socialLinks.facebook
      }
      if (this.source === "twitter") {
        return this.socialLinks.twitter
      }
      if (this.source === "instagram") {
        return this.socialLinks.instagram
      }
      if (this.source === "youtube") {
        return this.socialLinks.youtube
      }
      if (this.source === "tiktok") {
        return this.socialLinks.tiktok
      }
    },
    getOrigin () {
      if (this.source === "upload") {
        return null
      }
      return "link_" + this.source
    },
    uploadHandler (progressEvent) {
      this.uploadPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      if (this.uploadPercent >= 100) {
        // this.uploadInProgress = false
      }
    },
    uploadOK () {
      this.uploadInProgress = false
      this.goToStep(4)
    }
  },
  data () {
    return {
      step: 1,
      messageLoading: gettext("Cargando..."),
      sendCodeInProgress: false,
      resendInProgress: false,
      canParticipateResult: {
        valid: true,
        valid_user: true,
        valid_dates: true,
        valid_country: true
      },
      resent: false,
      wrongEmail: false,
      nextUrl: null,
      lang: CURRENT_LANGUAGE_CODE,
      validatingCodeInProgress: false,
      retries_left: 10,
      form: {
        email: null,
        password: null,
        token: null,
        extra: {},
        confirm_password: null,
        legal_basis_acceptance: 'not_accepted',
        newsletter_acceptance: 'not_accepted',
        code: ""
      },
      dropOver: 0,
      participateForm: {
        title: null,
        category: null,
        description: null,
        file: null,
        video_url: null,
        signature: null,
        minors: []
      },
      socialLinks: {
        facebook: null,
        instagram: null,
        twitter: null,
        youtube: null,
        tiktok: null
      },
      uploadInProgress: false,
      uploadPercent: 0,
      uploadError: false,
      source: 'upload'
    }
  },
  created () {
    if (this.lang === "pt-br") {
      this.lang = "pt_BR"
    }
    localize(this.lang)
  },
  mounted () {
    this.setParticipationAttempt(true)
    this.canParticipate().then((response) => {
      this.canParticipateResult = response.data.data
      if (!this.canParticipateResult.valid) {
        setTimeout(() => {
          this.$router.push({ "name": "home" })
        }, 3000)
      }
    })
    this.registerEvent('participate-step-1')
    if (this.getAuth.loggedIn) {
      this.goToStep(3)
      return
    }
    if (this.form.token === null) {
      this.form.token = uuidv4()
    }
    localize(this.lang)
  }
};

</script>

<style lang="scss">
  .field {

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
  .current-email {
    cursor: pointer;
  }
  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all .2s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(15px);
    opacity: 0;
  }

  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all .2s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(15px);
    opacity: 0;
  }

  .slide-down-enter-active, .slide-down-leave-active {
    transition: all .2s ease;
  }
  .slide-down-enter, .slide-down-leave-to {
    transform: translateY(-15px);
    opacity: 0;
  }

  .wrong-email {
    margin-top: -10px;
    .btn-xs {
      padding: 5px;
      text-transform: none;
    }
  }

  .fade-out {
    animation: fade-out 5s ease-out forwards;
  }

  @keyframes fade-out {
    0% {
      opacity: 0.2;
      transform: scale(0.9) translateX(0px);
    }
    5% {
      opacity: 1;
      transform: scale(1.1);
    }
    10% {
      opacity: 1;
      transform: scale(1);
    }
    90% {
      opacity: 1;
      transform: translateX(0px);
    }
    100% {
      opacity: 0;
      transform: translateX(15px);
    }
  }

  .animation-appear {
    animation: appear 0.2s ease-in forwards;
    opacity:0;
  }

  .animation-appear-right {
    animation: appear-right 0.2s ease-in forwards;
    opacity:0;
  }

  @for $i from 1 through 20 {
    .animation-delay-#{$i} {
      animation-delay: $i * 0.04s
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
      transform: translateY(15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px)
    }
  }

  @keyframes appear-right {
    0% {
      opacity: 0;
      transform: translateX(15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px)
    }
  }

  @supports (animation: grow .5s cubic-bezier(.25, .25, .25, 1) forwards) {
       .tick {
          stroke-opacity: 0;
          stroke-dasharray: 29px;
          stroke-dashoffset: 29px;
          animation: draw .5s cubic-bezier(.25, .25, .25, 1) forwards;
          animation-delay: .6s
      }

      .circle {
          fill-opacity: 0;
          stroke: #219a00;
          stroke-width: 16px;
          transform-origin: center;
          transform: scale(0);
          animation: grow 1s cubic-bezier(.25, .25, .25, 1.25) forwards;
      }
  }

  @keyframes grow {
      60% {
          transform: scale(.8);
          stroke-width: 4px;
          fill-opacity: 0;
      }
      100% {
          transform: scale(.9);
          stroke-width: 8px;
          fill-opacity: 1;
          fill: #219a00;
      }
  }

  @keyframes draw {
      0%, 100% { stroke-opacity: 1; }
      100% { stroke-dashoffset: 0; }
  }

</style>
