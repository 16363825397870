<template>
    <div class="container container-campaign">
      <div class="row justify-content-between">
        <aside class="col-md-4 col-md-offset-1 cms-aside">
          <div class="sticky-top">
            <h6 class="cms-title">{{ staticTexts.moreInfo }}</h6>
            <ul class="nav nav-cms flex-column">
              <li class="nav-item" v-for="page in getInfoPages.pages" :key="page.id">
                <router-link class="nav-link color-custom-primary" :to="{name: 'infopage', params: {slug: page.slug}}" active-class="active">{{page.title}} <i class="material-icons"> arrow_forward </i></router-link>
              </li>
            </ul>
            <ul class="nav nav-mini-cms flex-column">
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'legal_basis'}" active-class="active">{{ staticTexts.legalBasis }}</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'privacy_policy'}" active-class="active">{{ staticTexts.privacyPolicy }}</router-link>
              </li>
              <li class="nav-item" v-if="false">
                <router-link class="nav-link" :to="{name: 'terms'}" active-class="active">{{ staticTexts.termsAndConditions }}</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'cookies_policy'}" active-class="active">{{ staticTexts.cookiesPolicy }}</router-link>
              </li>
            </ul>
          </div>
        </aside>
        <section class="col-md-7">
          <article class="tab-content cms-content">
            <div class="tab-pane active">
              <SlideXLeftTransition :duration="300" origin="center top" mode="out-in">
                <router-view></router-view>
              </SlideXLeftTransition>
            </div>
          </article>
        </section>
      </div>
    </div>
</template>

<script>
/* global gettext */
import { mapGetters, mapActions } from 'vuex'
import { SlideXLeftTransition } from 'vue2-transitions'

export default {
  name: 'infopage',
  components: {
    SlideXLeftTransition
  },
  methods: {
    ...mapActions(['doGetInfoPages']),
    redirect () {
      if (this.$route.params.hasOwnProperty('slug') || this.$route.path.includes('/legal/')) {
        return
      }
      if (this.getInfoPages.pages_count === 0) {
        // this condition will be false in a few ms
        setTimeout(this.redirect.bind(this), 100)
      } else {
        this.$router.push({ name: 'infopage', params: { 'slug': this.getInfoPages.pages[0].slug } })
      }
    }
  },
  computed: {
    ...mapGetters(['getInfoPages']),
    staticTexts () {
      return {
        moreInfo: gettext("+ info"),
        legalBasis: gettext("Bases legales"),
        privacyPolicy: gettext("Política de privacidad"),
        termsAndConditions: gettext("Términos y condiciones de uso"),
        cookiesPolicy: gettext("Política de cookies")
      };
    }
  },
  mounted () {
    this.doGetInfoPages()
    this.redirect()
  }
}

</script>
