<template>
  <div>
    <template v-if="getCampaign.slider_style === 'aside'">
      <div class="container container-campaign">
        <div class="row">
          <div class="col-lg-3">
            <main-banner :mode="getCampaign.slider_style" v-if="getCampaign && getCampaign.slider_enabled"></main-banner>
          </div>
          <div class="col-lg-9">
            <img :src="getCampaign.splash_image" class="img-fluid w-100" alt="Aún no hay contenidos" v-if="!getCampaign.show_participations && getCampaign.splash_image" />
            <div class="alert alert-light" v-if="!getCampaign.show_participations && !getCampaign.splash_image && !getCampaign.has_during_campaign_page">
              {{ staticTexts.beTheFirstParticipant }}
            </div>
            <div class="container container-campaign" v-if="getCampaign.has_during_campaign_page" v-html="getCampaign.during_campaign_page_custom_html_content"></div>
            <content-list :items="getHomeParticipations" v-if="getCampaign.show_participations"></content-list>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <main-banner :mode="getCampaign.slider_style" v-if="getCampaign && getCampaign.slider_enabled"></main-banner>
      <div class="container container-campaign">
        <img :src="getCampaign.splash_image" class="img-fluid w-100" alt="Aún no hay contenidos" v-if="!getCampaign.show_participations && getCampaign.splash_image" />
        <div class="alert alert-light" v-if="!getCampaign.show_participations && !getCampaign.splash_image && !getCampaign.has_during_campaign_page">
          {{ staticTexts.beTheFirstParticipant }}
        </div>
        <div class="container container-campaign" v-if="getCampaign.has_during_campaign_page" v-html="getCampaign.during_campaign_page_custom_html_content"></div>
        <content-list :items="getHomeParticipations" v-if="getCampaign.show_participations"></content-list>
      </div>
    </template>
    <b-modal ref="modal-welcome" size="lg" hide-footer :title="getCampaign.name">
      <iframe width="100%" style="margin: auto" height="315" src="https://www.youtube.com/embed/g2a6UTEGplo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </b-modal>
  </div>
</template>

<script>
/* global gettext */
import { mapGetters, mapActions, mapMutations } from 'vuex'
import MainBanner from '@/views/home/MainBanner.vue'
import ContentList from '@/components/Hitsbook/ContentList.vue'
import { BModal } from 'bootstrap-vue'

export default {
  name: 'home',
  components: {
    BModal,
    ContentList,
    MainBanner
  },
  metaInfo() {
    let title = this.getCampaign.name
    let metas = [
      { title: this.getCampaign.name }
    ]
    if (this.getCampaign.seo_enabled) {
      title = this.getCampaign.meta_title
      metas = [
        { title: this.getCampaign.meta_title },
        { description: this.getCampaign.meta_description },
        { keywords: this.getCampaign.meta_keywords },
        { robots: this.getCampaign.meta_robots }
      ]
    }

    metas.push(
      { property: 'twitter:image', content: this.getShareImage },
      { property: 'twitter:card', content: "summary_large_image" },
      { property: 'og:image', content: this.getShareImage },
      { property: 'og:url', content: window.location.href }
    )

    return {
      title,
      meta: metas
    }
  },
  computed: {
    ...mapGetters(['getCampaign', 'getHomeParticipations', 'getCampaignId', 'getSettings', 'getWelcomeShowed']),
    getStyle () {
      return this.getCampaign.theme_style
    },
    getExtendedState () {
      return this.getCampaign.extended_state
    },
    getShareImage () {
      if (this.getCampaign.slides.length > 0) {
        return this.getCampaign.slides[0].featured_image
      } else {
        return this.getCampaign.logo
      }
    },
    staticTexts () {
      return {
        beTheFirstParticipant: gettext("¡Sé el primero en participar! Cuanto antes lo hagas más posibilidades tendrás de ser el ganador.")
      };
    }
  },
  methods: {
    ...mapActions(['registerCampaignInteractions']),
    ...mapMutations(['setParticipationAttempt', 'setWelcomeShowed'])
  },
  mounted () {
    let isPreview = this.getSettings.isPreview
    this.setParticipationAttempt(false)
    if (!isPreview) {
      if (this.getExtendedState === 'pre' && this.getCampaign.has_pre_campaign_page) {
        this.$router.push({ name: 'preCampaign' })
        return
      }
      if (this.getExtendedState === 'post' && this.getCampaign.has_post_campaign_page) {
        this.$router.push({ name: 'postCampaign' })
        return
      }
      let interactions = []
      interactions.push({ 'type': 'pageview', 'url': window.location.href })
      this.registerCampaignInteractions({ 'campaign': this.getCampaignId, interactions })
    }
    if (!this.getWelcomeShowed && this.getCampaignId === 731) {
      this.$refs['modal-welcome'].show()
      this.setWelcomeShowed()
    }
  },
  watch: {
    getStyle(newValue) {
      let documentClasses = document.body.classList;
      if (newValue === 'dark') {
        documentClasses.add("dark-mode");
      } else {
        documentClasses.remove("dark-mode");
      }
    }
  }
};

</script>
