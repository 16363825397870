<template>
  <div class="vote-box" v-if="isEnabled">
    <div class="vote--animation" ref="rvoteanim" v-show="showAnimation">
      <div><i class="em-svg" :class="'em-' + animationIcon" aria-role="presentation"></i></div>
    </div>

    <button class="btn btn-vote btn-custom-primary" v-if="!alreadyVoted" @click="performVote()">
        <span>
          <i class="material-icons">thumb_up</i> {{ staticTexts.vote }}
        </span>
    </button>

    <button v-else class="btn btn-success btn-voted" :class="alreadyVotedBtnClass" @mousedown="cancelVoteStart" @touchstart="cancelVoteStart" @mouseup="cancelVoteStop" @touchend="cancelVoteStop" @touchcancel="cancelVoteStop" @mouseleave="cancelVoteStop">
      <span v-show="cancelVoteAttemptInProgress">
        <i class="material-icons">
        update
        </i> {{ staticTexts.cancelVote }}
      </span>
      <span class="btn-voted-text" v-show="!cancelVoteAttemptInProgress">
        <i class="material-icons">
        thumb_up
        </i> {{ staticTexts.voted }}
      </span>
      <b-progress v-if="cancelVoteAttemptInProgress" class="btn-bottom--bar" variant="dark" :value="cancelVoteCounter" :max="100"></b-progress>
    </button>
    <div ref="particles" class="particles-container">
      <div class="triangle">
        <div class="square"></div>
        <div class="circle"></div>
        <div class="circle2"></div>
      </div>
      <div class="triangle">
        <div class="square"></div>
        <div class="circle"></div>
        <div class="circle2"></div>
      </div>
      <div class="triangle">
        <div class="square"></div>
        <div class="circle"></div>
        <div class="circle2"></div>
      </div>
      <div class="triangle">
        <div class="square"></div>
        <div class="circle"></div>
        <div class="circle2"></div>
      </div>
      <div class="triangle">
        <div class="square"></div>
        <div class="circle"></div>
        <div class="circle2"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* global gettext */
import { mapActions } from 'vuex'
import { BProgress } from "bootstrap-vue"

const minDeg = 1
const maxDeg = 90
const particlesClasses = [
  {
    class: "pop-top"
  },
  {
    class: "pop-top-left"
  },
  {
    class: "pop-top-right"
  },
  {
    class: "pop-bottom-right"
  },
  {
    class: "pop-bottom-left"
  }
];

let getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export default {
  components: {
    BProgress
  },
  computed: {
    isEnabled () {
      return this.campaign.is_voting_enabled && this.campaign.is_voting_period_active
    },
    alreadyVoted () {
      if (this.mounted) {
        if (this.userInteraction) {
          return this.userInteraction.total_likes > 0
        } else {
          return false
        }
      } else {
        return false
      }
    },
    alreadyVotedBtnClass () {
      if (this.cancelVoteAttemptInProgress) {
        return "btn-danger btn-bg-animated"
      } else {
        return "btn-success"
      }
    },
    staticTexts () {
      return {
        vote: gettext("¡Vota!"),
        cancelVote: gettext("Retirar voto"),
        voted: gettext("Has votado"),
        votedPopupTitle: gettext("Has votado"),
        votedPopupText: gettext("Voto registrado con éxito")
      };
    }
  },
  data () {
    return {
      voteLoading: false,
      showAnimation: false,
      animationIcon: '--1',
      tmrAnimation: null,
      cancelVoteAttemptInProgress: false,
      cancelVoteCounter: 0,
      cancelVoteTimer: null,
      mounted: false
    }
  },
  mounted () {
    this.mounted = true
  },
  watch: {

  },
  methods: {
    ...mapActions(["registerParticipationVote"]),
    cancelVoteStart () {
      this.cancelVoteAttemptInProgress = true
      this.cancelVoteCounter = 15
      this.cancelVoteTimer = setInterval(this.cancelVoteTick.bind(this), 150)
    },
    cancelVoteStop () {
      clearInterval(this.cancelVoteTimer)
      this.cancelVoteAttemptInProgress = false
    },
    cancelVoteTick () {
      this.cancelVoteCounter += 7
      if (this.cancelVoteCounter >= 120) {
        clearInterval(this.cancelVoteTimer)
        this.cancelVoteStop()
        this.performVote()
      }
    },
    performVote () {
      this.voteLoading = true
      let voteAction = "vote"
      if (!this.alreadyVoted) {
        this.performAnimation("--1")
      } else {
        this.performAnimation("x")
        voteAction = "unvote"
      }
      this.$nextTick(() => {
        let payload = {
          id: this.item.id,
          reaction_type: null,
          gtm: this.$gtm,
          voteAction: voteAction
        }
        this.registerParticipationVote(payload).then((response) => {
          this.voteLoading = false
          if (response && voteAction === "vote") {
            this.$swal.fire({
              title: this.staticTexts.votedPopupTitle,
              text: this.staticTexts.votedPopupText,
              icon: 'success',
              timer: 3000,
              timerProgressBar: true
            })
          }
        })
      })
    },
    performAnimation (icon) {
      this.animationIcon = icon
      this.showAnimation = true
      this.$refs['rvoteanim'].classList.add('animate')
      this.tmrAnimation = setTimeout(this.startAnimation.bind(this), 100)
      if (icon !== 'x') {
        this.animateParticles(this.$refs.particles, 700)
      }
    },
    startAnimation () {
      if (this.$refs['rvoteanim']) {
        this.$refs['rvoteanim'].classList.remove('animate')
        this.tmrAnimation = setTimeout(this.endAnimation.bind(this), 600)
      }
    },
    endAnimation () {
      this.$refs['rvoteanim'].classList.add('animate')
      this.showAnimation = false
    },
    animateParticles(particles, dur) {
      this.addRandomParticlesRotation(particles, minDeg, maxDeg);
      for (let i = 0; i < particlesClasses.length; i++) {
        this.runParticleAnimationCycle(particles.children[i], particlesClasses[i].class, dur);
      }
      // Boolean functionality only to activate particles2, particles3 when needed
      particles.classList.add('animating')
      setTimeout(() => {
        particles.classList.remove('animating')
      }, dur);
    },
    addRandomParticlesRotation(particles, minDeg, maxDeg) {
      const randomRotationAngle = getRandomInt(minDeg, maxDeg) + 'deg'
      particles.style.transform = `rotate(${randomRotationAngle})`
    },
    runParticleAnimationCycle(el, className, duration) {
      if (el && !el.classList.contains(className)) {
        el.classList.add(className)
        setTimeout(() => { el.classList.remove(className) }, duration)
      }
    }
  },
  props: {
    campaign: Object,
    item: Object,
    userInteraction: Object
  }
};
</script>
